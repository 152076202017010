import styled from '@emotion/styled';

const LoginForm = styled.div`
  .login-background {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
  .login-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f4fdfc;
    z-index: 1;
  }
  .input--error .ant-input {
    border-color: #cf4242;
  }
  form {
    z-index: 3;
    position: absolute;
    top: 3%;
    left: 0;
    right: 0;
    img {
      width: 200px;
      height: 140px;
      padding: 0 0 40px 0;
      display: block;
      margin: auto;
    }
    .form {
      width: 450px;
      padding: 40px 50px;
      background-color: #fff;
      margin: 0 auto 30px auto;
      box-shadow: 3px 3px 9px #00000029;

      input {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 5px;
        }
      }

      h2 {
        font-family: AvenirLTStdHeavy;
        text-align: center;
        color: #050593;
        font-size: 24px;
      }

      p {
        font-family: AvenirLTStdBook;
        font-size: 16px;
        color: #000;
        margin-bottom: 5px;
      }

      hr {
        border-top: 1px solid #dddddd;
        color: #ddd;
        margin-bottom: 30px;
      }

      button {
        font-family: AvenirLTStdHeavy;
        font-size: 16px;
        margin-top: 20px;
      }
      .under-line {
        font-family: AvenirLTStdBook;
        font-size: 16px;
        color: #707070;
        cursor: pointer;
        display: inline-block;
        text-decoration: underline;
      }
      .back-to-login {
        margin-top: 20px;
      }
      .password-heading {
        margin-top: 20px;
      }
      .forgot-password {
        margin-bottom: 10px;
      }
      .forgot-password-text {
        margin-bottom: 20px;
      }
    }
  }
`;

export default LoginForm;
