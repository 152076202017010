import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button } from 'antd';
import validator from 'validator';
import SearchInput from './SearchInput';
import {
  TEXT_VALID,
  TEXT_MISSING_FIELD,
  TEXT_LIMIT_50,
  TEXT_POSITIVE_NUMB,
  TEXT_DECIMAL_PLACES,
  TEXT_MIN_VALUE,
  TEXT_MAX_VALUE,
  TEXT_MIN_UNITS,
  TEXT_MAX_UNITS,
  TEXT_ONLY_NUMBER
} from '../constants/message';
import { invalidCharacter } from '../utils/formatString';
import { isMeasureUnitValid } from '../helpers/validate';

import Input from './Input';
import InputNumber from './InputNumber';

const ContainerInner = styled.div`
  background: #05059312 0% 0% no-repeat padding-box;
  padding: 30px 45px;
  width: fit-content;
  position: relative;
  margin-bottom: 20px;
  .group-form {
    width: 500px;
    float: left;
  }
  .buton-icon {
    position: absolute;
    right: 20px;
    bottom: 32px;
    :hover {
      color: #050593;
    }
  }
  .unit-wrapper--dimensions {
    margin-right: 2px;
  }
  .input-dimension {
    width: 33.333%;
    float: left;
  }
  .input-dimension--length .ant-input {
    border-radius: 4px 0 0 4px;
  }
  .input-dimension--width .ant-input {
    border-radius: 0;
    margin-left: -1px;
  }
  .input-dimension--height .ant-input {
    border-radius: 0 4px 4px 0;
    margin-left: -2px;
  }
`;

const checkValid = (_newState) =>
  !!(
    _newState.type &&
    _newState.amount &&
    validator.isInt(_newState.amount.toString(), { min: 1, max: 999 }) &&
    (_newState.name && !invalidCharacter(_newState.name)) &&
    _newState.weight &&
    isMeasureUnitValid(
      _newState.weight.amount,
      _newState.weight.isKg,
      34000,
      75000
    ) &&
    _newState.dimensions &&
    isMeasureUnitValid(
      _newState.dimensions.length,
      _newState.dimensions.isCm,
      1400,
      552
    ) &&
    isMeasureUnitValid(
      _newState.dimensions.width,
      _newState.dimensions.isCm,
      1400,
      552
    ) &&
    isMeasureUnitValid(
      _newState.dimensions.height,
      _newState.dimensions.isCm,
      1400,
      552
    )
  );

const ShipmentDimension = ({
  triggerData = () => {},
  _index,
  data,
  list,
  handleDelete
}) => {
  const updateState = (newState) => {
    const _newState = {
      ...data,
      ...newState
    };
    _newState.status = checkValid(_newState);
    triggerData(_newState);
    return _newState;
  };

  const [err, setErr] = useState({
    name: '',
    type: '',
    amount: '',
    weight: '',
    length: '',
    width: '',
    height: ''
  });

  const {
    name = '',
    type = undefined,
    amount,
    weight = {
      amount: '',
      isKg: true
    },
    dimensions = {
      length: '',
      width: '',
      height: '',
      isCm: true
    }
  } = data;

  const updateStateInside = (newState) => {
    setErr((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const validatorNumber = (target, prop, text, isWeight, flgUnit = true) => {
    if (validator.isEmpty(target)) {
      updateStateInside({
        [prop]: `${TEXT_MISSING_FIELD} ${text}.`
      });
      updateState({ status: false });
    } else if (!validator.isNumeric(target) || target < 0) {
      updateStateInside({
        [prop]: TEXT_POSITIVE_NUMB
      });
      updateState({ status: false });
    } else if (!validator.isDecimal(target, { decimal_digits: '0,2' })) {
      updateStateInside({
        [prop]: TEXT_DECIMAL_PLACES
      });
      updateState({ status: false });
    } else if (isWeight) {
      if (target < 0.1) {
        updateStateInside({
          [prop]: `${TEXT_MIN_VALUE} ${flgUnit ? '0.1kg' : '0.1lb'}`
        });
        updateState({ status: false });
      } else if (flgUnit && target > 34000) {
        updateStateInside({
          [prop]: `${TEXT_MAX_VALUE} 34000kg`
        });
        updateState({ status: false });
      } else if (!flgUnit && target > 75000) {
        updateStateInside({
          [prop]: `${TEXT_MAX_VALUE} 75000lb`
        });
        updateState({ status: false });
      } else {
        updateStateInside({
          [prop]: ''
        });
      }
    } else if (!isWeight) {
      if (target < 0.1) {
        updateStateInside({
          [prop]: `${TEXT_MIN_VALUE} ${flgUnit ? '0.1cm' : '0.1inch'}`
        });
        updateState({ status: false });
      } else if (flgUnit && target > 1400) {
        updateStateInside({
          [prop]: `${TEXT_MAX_VALUE} 1400cm`
        });
        updateState({ status: false });
      } else if (!flgUnit && target > 552) {
        updateStateInside({
          [prop]: `${TEXT_MAX_VALUE} 552inches`
        });
        updateState({ status: false });
      } else {
        updateStateInside({
          [prop]: ''
        });
      }
    } else {
      updateStateInside({
        [prop]: ''
      });
    }
  };

  const validatorEmpty = () => {
    if (!type || validator.isEmpty(type)) {
      updateStateInside({
        type: `${TEXT_MISSING_FIELD} Type.`
      });
      updateState({ status: false });
    } else {
      updateStateInside({
        type: ''
      });
    }
  };
  const validatorAmount = () => {
    if (amount === '') {
      updateStateInside({
        amount: `${TEXT_MISSING_FIELD} Unit.`
      });
      updateState({ status: false });
    } else if (!validator.isInt(amount.toString())) {
      updateStateInside({
        amount: TEXT_ONLY_NUMBER
      });
      updateState({ status: false, amount: '' });
    } else if (amount < 1) {
      updateStateInside({
        amount: TEXT_MIN_UNITS
      });
      updateState({ status: false, amount: '' });
    } else if (amount > 999) {
      updateStateInside({
        amount: TEXT_MAX_UNITS
      });
      updateState({ status: false, amount: '' });
    } else {
      updateStateInside({
        amount: ''
      });
    }
  };
  const validatorName = () => {
    if (name && invalidCharacter(name)) {
      updateStateInside({
        name: TEXT_VALID
      });
      updateState({ status: false });
    } else if (!validator.isLength(name, { min: 0, max: 50 })) {
      updateStateInside({
        name: TEXT_LIMIT_50
      });
      updateState({ status: false });
    } else if (!name) {
      updateStateInside({
        name: `${TEXT_MISSING_FIELD} Package Name`
      });
      updateState({ status: false });
    } else {
      updateStateInside({
        name: ``
      });
    }
  };

  return (
    <ContainerInner>
      <Row className="pb2">
        <Col className="input-wrapper">
          <p>Package Name</p>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => updateState({ name: e.target.value })}
            onBlur={() => validatorName()}
          />
          {err.name ? <p className="msg-error">{err.name}</p> : ''}
        </Col>
        <Col className="input-wrapper">
          <p>Type</p>
          <SearchInput
            showSearch={false}
            items={list}
            value={type}
            placeholder="Select..."
            onChange={(value) => {
              updateState({ type: value });
              updateStateInside({
                type: ''
              });
            }}
            onBlur={() => validatorEmpty()}
          />
          {err.type ? <p className="msg-error">{err.type}</p> : ''}
        </Col>
        <Col className="input-wrapper">
          <p>How many unit(s)?</p>
          <InputNumber
            value={amount}
            defaultValue={amount}
            onChange={(value) => {
              updateState({ amount: value });
              updateStateInside({
                amount: ''
              });
            }}
            onBlur={() => validatorAmount()}
          />
          {err.amount ? <p className="msg-error">{err.amount}</p> : ''}
        </Col>
      </Row>
      <Row>
        <Col className="input-wrapper">
          <p>
            Weight for one unit
            <span className="unit-wrapper">
              <span
                aria-hidden
                role="button"
                className={`unit-text ${!weight.isKg && 'unChecked'}`}
                onClick={() => {
                  if (weight.amount) {
                    validatorNumber(
                      weight.amount,
                      'weight',
                      'Weight for one unit',
                      true,
                      true
                    );
                  }

                  updateState({ weight: { ...weight, isKg: true } });
                }}
              >
                KG
              </span>
              <span className="devide">|</span>
              <span
                aria-hidden
                role="button"
                className={`unit-text ${weight.isKg && 'unChecked'}`}
                onClick={() => {
                  if (weight.amount) {
                    validatorNumber(
                      weight.amount,
                      'weight',
                      'Weight for one unit',
                      true,
                      false
                    );
                  }
                  updateState({ weight: { ...weight, isKg: false } });
                }}
              >
                LB
              </span>
            </span>
          </p>
          <Input
            placeholder="How much..."
            value={weight.amount}
            onChange={(e) => {
              updateStateInside({ weight: '' });
              updateState({
                weight: {
                  ...weight,
                  amount: e.target.value
                }
              });
            }}
            onBlur={() => {
              validatorNumber(
                weight.amount,
                'weight',
                'Weight for one unit',
                true,
                weight.isKg
              );
            }}
          />
          {err.weight ? <p className="msg-error">{err.weight}</p> : ''}
        </Col>
        <Col className="group-form">
          <p>
            Dimensions for one unit (L x W x H)
            <span className="unit-wrapper unit-wrapper--dimensions">
              <span
                aria-hidden
                role="button"
                className={`unit-text ${!dimensions.isCm && 'unChecked'}`}
                onClick={() => {
                  if (dimensions.length) {
                    validatorNumber(
                      dimensions.length,
                      'length',
                      'Package Length',
                      false,
                      true
                    );
                  }
                  if (dimensions.width) {
                    validatorNumber(
                      dimensions.width,
                      'width',
                      'Package Width',
                      false,
                      true
                    );
                  }
                  if (dimensions.height) {
                    validatorNumber(
                      dimensions.height,
                      'height',
                      'Package Height',
                      false,
                      true
                    );
                  }
                  updateState({ dimensions: { ...dimensions, isCm: true } });
                }}
              >
                CM
              </span>
              <span className="devide">|</span>
              <span
                aria-hidden
                role="button"
                className={`unit-text ${dimensions.isCm && 'unChecked'}`}
                onClick={() => {
                  if (dimensions.length) {
                    validatorNumber(
                      dimensions.length,
                      'length',
                      'Package Length',
                      false,
                      false
                    );
                  }
                  if (dimensions.width) {
                    validatorNumber(
                      dimensions.width,
                      'width',
                      'Package Width',
                      false,
                      false
                    );
                  }
                  if (dimensions.height) {
                    validatorNumber(
                      dimensions.height,
                      'height',
                      'Package Height',
                      false,
                      false
                    );
                  }
                  updateState({ dimensions: { ...dimensions, isCm: false } });
                }}
              >
                IN
              </span>
            </span>
          </p>
          <div className="ant-input-group">
            <div className="input-dimension input-dimension--length">
              <Input
                placeholder="Length"
                value={dimensions.length}
                onChange={(e) => {
                  updateStateInside({ length: '' });
                  updateState({
                    dimensions: {
                      ...dimensions,
                      length: e.target.value
                    }
                  });
                }}
                onBlur={() =>
                  validatorNumber(
                    dimensions.length,
                    'length',
                    'Package Length',
                    false,
                    dimensions.isCm
                  )
                }
              />
              {err.length ? <p className="msg-error">{err.length}</p> : ''}
            </div>
            <div className="input-dimension input-dimension--width">
              <Input
                value={dimensions.width}
                placeholder="Width"
                onChange={(e) => {
                  updateStateInside({ width: '' });
                  updateState({
                    dimensions: { ...dimensions, width: e.target.value }
                  });
                }}
                onBlur={() =>
                  validatorNumber(
                    dimensions.width,
                    'width',
                    'Package Width',
                    false,
                    dimensions.isCm
                  )
                }
              />
              {err.width ? <p className="msg-error">{err.width}</p> : ''}
            </div>
            <div className="input-dimension input-dimension--height">
              <Input
                placeholder="Height"
                value={dimensions.height}
                onChange={(e) => {
                  updateStateInside({ height: '' });
                  updateState({
                    dimensions: {
                      ...dimensions,
                      height: e.target.value
                    }
                  });
                }}
                onBlur={() =>
                  validatorNumber(
                    dimensions.height,
                    'height',
                    'Package Height',
                    false,
                    dimensions.isCm
                  )
                }
              />
              {err.height ? <p className="msg-error">{err.height}</p> : ''}
            </div>
          </div>
        </Col>
      </Row>
      {_index !== 0 && (
        <Button
          className="buton-icon"
          icon="delete"
          onClick={() => handleDelete()}
        />
      )}
    </ContainerInner>
  );
};

export default ShipmentDimension;
