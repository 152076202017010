import { createReducer } from '@reduxjs/toolkit';
import {
  fetchQuotes,
  fetchQuotesLoading,
  setPageFilter,
  setPageSizeFilter
} from '../actions/quoteRequestsList';

const initialState = {
  quotes: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  }
};

export default createReducer(initialState, {
  [fetchQuotes](state, action) {
    const { quotes, total } = action.payload;
    state.quotes = {
      data: quotes,
      total,
      loading: false
    };
  },
  [fetchQuotesLoading](state) {
    state.quotes.loading = true;
  },
  [setPageFilter](state, action) {
    state.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.page = 1;
    state.records = action.payload;
  }
});
