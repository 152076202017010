import { createReducer } from '@reduxjs/toolkit';
import {
  fetchShipments,
  fetchShipmentsLoading,
  fetchChoices,
  fetchChoicesLoading,
  setPageFilter,
  setPageSizeFilter,
  setShipmentRangeFilters,
  setShipmentFilters,
  resetFilters,
  cancelFilters,
  applyFilters
} from '../actions/shipmentList';

const initialState = {
  shipments: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filtered: false,
  filters: {
    status: 'All',
    page: 1,
    records: '20',
    trackingNumber: null,
    billingInvoiceNumber: null,
    merchantName: 'All',
    chargeableWeight: { min: '', max: '', unit: 'All' },
    createdAt: [],
    estDeliveryDate: [],
    shipmentMode: 'All',
    origin: 'All',
    destination: 'All',
    createdBy: 'All',
    creatorEmail: 'All'
  },
  tempFilters: {
    status: 'All',
    page: 1,
    records: '20',
    trackingNumber: null,
    billingInvoiceNumber: null,
    merchantName: 'All',
    chargeableWeight: { min: '', max: '', unit: 'All' },
    createdAt: [],
    estDeliveryDate: [],
    shipmentMode: 'All',
    origin: 'All',
    destination: 'All',
    createdBy: 'All',
    creatorEmail: 'All'
  },
  choices: {
    loading: false,
    statuses: [],
    merchants: [],
    user_names: [],
    user_emails: []
  }
};

export default createReducer(initialState, {
  [fetchShipments](state, action) {
    const { shipments, total } = action.payload;
    state.shipments = {
      data: shipments,
      total,
      loading: false
    };
  },
  [fetchShipmentsLoading](state) {
    state.shipments.loading = true;
  },
  [fetchChoices](state, action) {
    const { merchants, statuses, users } = action.payload;
    state.choices = {
      loading: false,
      merchants: [
        {
          value: 'All',
          label: 'All'
        },
        ...merchants.map((item) => ({
          value: item.merchantId,
          label: item.merchantName
        }))
      ],
      user_names: [...new Set(users?.map((user) => user.name))].sort(),
      user_emails: [...new Set(users?.map((user) => user.email))].sort(),
      statuses: [
        {
          value: 'All',
          label: 'All'
        },
        ...statuses.map((item) => ({
          value: item.statusValue,
          label: item.statusName
        }))
      ]
    };
  },
  [fetchChoicesLoading](state) {
    state.choices.loading = true;
  },
  [setPageFilter](state, action) {
    state.filters.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.filters.page = 1;
    state.filters.records = action.payload;
  },
  [setShipmentFilters](state, action) {
    state.tempFilters = {
      ...state.tempFilters,
      ...action.payload
    };
  },
  [setShipmentRangeFilters](state, action) {
    state.tempFilters[action.payload.field][action.payload.key] =
      action.payload.value;
  },
  [resetFilters](state, action) {
    if (action.payload) {
      state.filters = {
        ...state.tempFilters,
        [action.payload.key]: initialState.filters[action.payload.key],
        records: state.filters.records
      };
    } else {
      state.filters = {
        ...state.tempFilters,
        ...initialState.filters,
        records: state.filters.records
      };
    }
    state.filtered = false;
  },
  [cancelFilters](state) {
    state.tempFilters = state.filters;
  },
  [applyFilters](state) {
    state.filters = state.tempFilters;
    state.filtered = !(
      !state.filters.trackingNumber &&
      !state.filters.billingInvoiceNumber &&
      state.filters.status === 'All' &&
      state.filters.merchantName === 'All' &&
      state.filters.shipmentMode === 'All' &&
      state.filters.origin === 'All' &&
      state.filters.destination === 'All' &&
      !state.filters.estDeliveryDate.length &&
      !state.filters.createdAt.length &&
      !state.filters.chargeableWeight
    );
  }
});
