import { Field } from 'formik';
import * as React from 'react';
import Radio from '../Radio';

const FormRadioGroup = ({
  name,
  validate,
  children,
  onChange: handleChange = () => {},
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value, onChange }, form: { setFieldValue } }) => (
        <>
          <Radio.Group
            name={name}
            value={value}
            onChange={(event) => {
              setFieldValue(name, event.target.value);
              onChange(event);
              handleChange(event);
            }}
            {...restProps}
          >
            {children}
          </Radio.Group>
          {/* {meta.error && <Message type="error">{meta.error}</Message>} */}
        </>
      )}
    </Field>
  );
};

export default FormRadioGroup;
