import { FastField } from 'formik';
import * as React from 'react';
import EditableLocationInput from '../EditableLocationInput';
import Message from '../Message';

const FormAddress = ({ name, validate, onSubmit, ...restProps }) => {
  return (
    <FastField name={name} validate={validate}>
      {({ field: { value }, form: { setFieldValue }, meta }) => (
        <>
          <EditableLocationInput
            value={value}
            onSubmit={async (values) => {
              return onSubmit(values, setFieldValue);
            }}
            {...restProps}
          />
          {meta.error && <Message type="error">{meta.error}</Message>}
        </>
      )}
    </FastField>
  );
};

export default FormAddress;
