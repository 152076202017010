import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';
import { message } from 'antd';
import { getProfileSync } from '../auth0';
import { formatShipmentModeValue } from '../utils/formatString';
import CancellationModal from './CancellationModal';
import {
  STATUS_CANCELLED,
  STATUS_SHIPMENT_CREATED,
  STATUS_QUOTE_DOCUMENT_UPLOADED,
  STATUS_QUOTE_SHIPMENT_CONFIRMED
} from '../constants/status';
import { updateQuoteStatus } from '../actions/quoteDetail';
import {
  TEXT_SHIPMENT_STATUS_ERROR,
  TEXT_SHIPMENT_STATUS_SUCCESS
} from '../constants/message';
// import Button from './Button';

const StyledShipmentDetails = styled.div`
  .shipment-body {
    /* padding: 0px 20px; */
  }
  .shipment-body div:last-child {
    border-bottom: transparent;
  }
  button {
    margin-top: 15px;
    margin-right: 10px;
    padding: 0px 41px;
  }
`;
const CancelLink = styled.a`
  text-decoration: underline;
  font-family: AvenirLTStdBookOblique;
  font-size: 16px;
  font-style: normal;
  color: #000000;
  &:hover {
    text-decoration: underline;
    color: #000000;
  }
`;

// const StyledShippingInfoMessage = styled.div`
//   background-color: #1fceca33;
//   text-align: center;
//   padding: 10px;
//   font-size: 16px;
//   font-family: AvenirLTStdBook;
//   color: #000;
//   margin-bottom: 15px;
//   svg {
//     margin-right: 10px;
//     color: #000;
//   }
//   .check svg {
//     color: #52c41a;
//   }
//   a {
//     color: #050593;
//     text-decoration: underline;
//   }
// `;

// const ShippingInfoMessage = ({ children, info = true }) => {
//   return (
//     <StyledShippingInfoMessage>
//       {info ? (
//         <Icon type="info-circle" theme="outlined" />
//       ) : (
//         <Icon type="check-circle" theme="filled" className="check" />
//       )}
//       {children}
//     </StyledShippingInfoMessage>
//   );
// };

const StyledShipmentDetailRow = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  li em {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  li span {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
  ul {
    line-height: 1;
    list-style-type: none;
    display: grid;
    grid-template-columns: ${(prop) =>
      prop.templateColumns ||
      'repeat(auto-fit,minmax(calc(100% - 66%),1fr)) 33% 33%'};
    padding: 0;
    grid-gap: 15px;
    margin-right: 25px;
    @media (min-width: 1600px) {
      grid-template-columns: ${(prop) =>
        prop.templateColumns ||
        'repeat(auto-fit,minmax(calc(100% - 75%),1fr)) 25% 25% 25%'};
    }
  }
`;

const ShipmentDetailRow = ({ type, schema, title, children }) => {
  const templateColumnMap = {
    full: '1fr',
    twoCol: '203px auto'
  };

  const getData = () => {
    if (!schema) return [];
    if (schema.formatData) {
      return schema.formatData(schema.data);
    }
    return schema.data;
  };
  const [data, setData] = useState(getData());

  useEffect(() => {
    setData(getData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  return (
    <>
      {data.length > 0 && (
        <StyledShipmentDetailRow templateColumns={templateColumnMap[type]}>
          <h5>{schema?.title || title}</h5>
          <ul>
            {data.map(({ label, value, formatValue, formatLabel }) => (
              <li key={`${label}${value}`}>
                <em className="label">
                  {formatLabel ? formatLabel(label) : label}
                </em>
                <span className="value">
                  {formatValue ? formatValue(value) : value}
                </span>
              </li>
            ))}
          </ul>

          {children && <p>{children}</p>}
        </StyledShipmentDetailRow>
      )}
    </>
  );
};
const ShipmentDetails = () => {
  const dispatch = useDispatch();
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const shipmentDetails = detailAPI?.shipment_details;
  const [isInternal, setIsInternal] = useState(false);
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [cancelReasonError, setCancelReasonError] = useState('');

  useEffect(() => {
    const profile = getProfileSync();
    const roles = profile['https://b2b.janio.asia/roles'] || [];
    setIsInternal(roles.includes('merchant/internal'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateStatus = async (newStatus, reason) => {
    try {
      await dispatch(
        updateQuoteStatus({
          id: detailAPI?.quote_id,
          status: newStatus,
          reason
        })
      );
      message.success(TEXT_SHIPMENT_STATUS_SUCCESS);
    } catch (error) {
      const result = error?.response?.data?.result;
      message.error(result || TEXT_SHIPMENT_STATUS_ERROR);
    }
    setIsCancelModalVisible(false);
    return null;
  };
  const handleSetCancelReason = (e) => {
    setCancelReason(e.target.value);
  };
  const handleCancelShipment = () => {
    if (cancelReason) {
      updateStatus(STATUS_CANCELLED, cancelReason);
      setCancelReasonError('');
    } else {
      setCancelReasonError('Please enter Reason for Cancellation.');
    }
  };
  const merchant = {
    title: 'Merchant Information',
    data: [
      {
        label: 'Merchant Name',
        value: detailAPI?.merchant_name
      }
    ]
  };
  const yourShipment = {
    title: 'Your Shipment',
    data: [
      {
        label: 'Shipment Name',
        value: detailAPI?.shipment_name
      },
      {
        label: 'Quote ID',
        value: detailAPI?.quote_request_id
      }
    ]
  };
  const shipmentMode = {
    title: 'Shipment Mode',
    data: [
      {
        label: 'Freight Used',
        value: detailAPI?.shipment_mode
      }
    ]
  };

  const getFirstMileTxt = () => {
    let firstMileTxt = '';
    if (detailAPI?.origin?.is_using_first_mile === 1) {
      firstMileTxt = 'Pickup';
    } else if (detailAPI?.origin?.is_using_first_mile === 0) {
      firstMileTxt = 'Dropoff';
    } else {
      firstMileTxt = 'No first mile';
    }
    return firstMileTxt;
  };

  const origin = {
    title: 'Origin/Shipper',
    data: [
      {
        label: 'First-mile',
        value: getFirstMileTxt()
      },
      {
        label: 'Country/Region',
        value: detailAPI?.origin?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          detailAPI?.origin?.is_using_first_mile
            ? 'Address'
            : 'Dropoff Address',
        value:
          [
            detailAPI?.origin?.location_name,
            detailAPI?.origin?.address,
            detailAPI?.origin?.city,
            detailAPI?.origin?.state,
            detailAPI?.origin?.postal_code
          ]
            .filter(Boolean)
            .join(', ') || '-'
      },
      {
        label: 'Origin Airport/Port',
        value: detailAPI?.origin?.port || 'None'
      },
      {
        label: 'Pickup Date',
        value: detailAPI?.origin?.pickup_date,
        formatLabel: () =>
          detailAPI?.origin?.is_using_first_mile
            ? 'Pickup Date'
            : 'Dropoff Date',
        formatValue: (value) => moment(value).format('DD MMM YYYY')
      },
      {
        label: "Shipper's Name",
        value: detailAPI?.shipper_name
      },
      {
        label: 'POC Name',
        value: detailAPI?.origin?.poc_name
      },
      {
        label: 'POC Number',
        value: detailAPI?.origin?.poc_number
      },
      {
        label: 'Email',
        value: detailAPI?.origin?.poc_email
      }
    ],
    formatData: (data) =>
      data.filter((address) => {
        if (['POC Name', 'POC Number', 'Email'].includes(address.label)) {
          return address?.value;
        }
        return true;
      })
  };

  const midMile = {
    title: 'Mid-mile',
    data: [
      {
        label: 'Freight-forwarding',
        value: detailAPI?.is_using_mid_mile,
        formatValue: (value) => (value ? 'Yes' : 'No')
      }
    ]
  };

  const destination = {
    title: 'Destination/Consignee',
    data: [
      {
        label: 'Last-mile',
        value: detailAPI?.destination?.is_using_last_mile,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Country/Region',
        value: detailAPI?.destination?.country
      },
      {
        label: 'Address',
        formatLabel: () =>
          detailAPI?.destination?.is_using_first_mile
            ? 'Address'
            : 'Pickup Address',
        value: [
          detailAPI?.destination?.location_name,
          detailAPI?.destination?.address,
          detailAPI?.destination?.city,
          detailAPI?.destination?.state,
          detailAPI?.destination?.postal_code
        ]
          .filter(Boolean)
          .join(', ')
      },
      {
        label: 'Destination Airport/Port',
        value: detailAPI?.destination?.port || 'None'
      },
      {
        label: 'Delivery Date',
        value: detailAPI?.destination?.pickup_date,
        formatValue: (value) => `${moment(value).format('DD MMM YYYY')} (Est.)`
      },
      {
        label: "Consignee's Name",
        value: detailAPI?.consignee_name
      },
      {
        label: 'POC Name',
        value: detailAPI?.destination?.poc_name
      },
      {
        label: 'POC Number',
        value: detailAPI?.destination?.poc_number
      },
      {
        label: 'Email',
        value: detailAPI?.destination?.poc_email
      }
    ],
    formatData: (data) =>
      data.filter((address) => {
        if (['POC Name', 'POC Number', 'Email'].includes(address.label)) {
          return address?.value;
        }
        return true;
      })
  };

  const information = {
    title: 'Shipping Information',
    data: [
      {
        label: 'Incoterm',
        value: detailAPI?.incoterm,
        formatValue: (value) => value || 'None'
      },
      {
        label: 'MAWB/OOBL',
        value: detailAPI?.import_awb
      },
      {
        label: 'HAWB/HBL',
        value: detailAPI?.export_awb
      }
    ]
  };

  const getLoadType = () => {
    switch (detailAPI?.shipment_mode) {
      case 'Sea':
        return {
          label: 'Container',
          load: detailAPI?.shipment_load_type
            ? 'Full container load (FCL)'
            : 'Less than container load (LCL)'
        };

      case 'Land':
        return {
          label: 'Truck',
          load: detailAPI?.shipment_load_type
            ? 'Full truck load (FTL)'
            : 'Less than truck load (LTL)'
        };
      default:
        return null;
    }
  };

  const load = {
    title: getLoadType()?.label,
    data: [
      {
        label: `${getLoadType()?.label} Load`,
        value: getLoadType()?.load
      }
    ]
  };
  const getLoadDetails = () => {
    const loadDetails = [];
    let index = 1;
    const title = getLoadType()?.label;
    for (let i = 0; i < (detailAPI?.loads?.length || 0); i += 1) {
      for (
        let j = 0;
        j < (detailAPI?.loads[i]?.containers_seals?.length || 0);
        j += 1
      ) {
        loadDetails.push({
          title: detailAPI?.loads[i]?.type
            ? `${title} ${index} (${detailAPI?.loads[i]?.type})`
            : `${title} ${index}`,
          data: [
            {
              label: `${title} No.`,
              value:
                detailAPI?.loads[i]?.containers_seals[j]?.containerNo || '-'
            },
            {
              label: `Seal No.`,
              value: detailAPI?.loads[i]?.containers_seals[j]?.sealNo || '-'
            }
          ]
        });
        index += 1;
      }
    }
    return loadDetails;
  };
  if (detailAPI?.shipment_load_type) {
    load.data = [
      ...load.data,
      {
        label: `No. of ${getLoadType()?.label}s`,
        value: detailAPI?.load_type_units
      }
    ];
  }

  const agreedPrice = {
    title: 'Shipping Price',
    data: [
      {
        label: 'Has Agreed Price',
        value: detailAPI?.agreed_price ? 'Yes' : 'No'
      },
      {
        label: 'Agreed Price',
        value: detailAPI?.shipment_price
      }
    ],
    formatData: (data) => data.filter((shippingPrice) => shippingPrice?.value)
  };

  const packages = detailAPI?.packages?.map((item, index) => {
    const getWeightValue = (weightType, packageItem) => {
      if (weightType) {
        return packageItem.weight
          ? `${packageItem.weight} ${packageItem.weight_uom.toUpperCase()}`
          : 'None';
      }
      return packageItem.total_weight
        ? `${packageItem.total_weight} ${packageItem.weight_uom.toUpperCase()}`
        : 'None';
    };
    const getVolumeValue = (volumeType, packageItem) => {
      if (
        volumeType &&
        packageItem.length &&
        packageItem.width &&
        packageItem.height
      ) {
        return `${packageItem.length} X ${packageItem.width} X ${
          packageItem.height
        } ${packageItem?.length_uom?.toUpperCase()}`;
      }
      return packageItem.total_volume
        ? `${packageItem.total_volume} CBM`
        : 'None';
    };

    return {
      title: `Set ${index + 1}`,
      data: [
        {
          label: 'Type',
          value: item.type
        },
        {
          label: 'No. of units',
          value: item.units
        },
        {
          label: 'Chargeable Weight',
          value: item.chargeable_weight
            ? `${item.chargeable_weight} KG`
            : 'None'
        },
        {
          label: item.weight_type ? 'Weight (Per Unit)' : 'Weight (Total)',
          value: getWeightValue(item.weight_type, item)
        },
        {
          label: item.volume_type ? 'Volume (Per Unit)' : 'Volume (Total)',
          value: getVolumeValue(item.volume_type, item)
        }
      ]
    };
  });

  const dangerousGoods = {
    'Hazardous Goods': shipmentDetails?.contains_hazardous_goods,
    Batteries: shipmentDetails?.contains_battery,
    Others: shipmentDetails?.other
  };

  const otherDetails = {
    title: 'Other Details',
    data: [
      {
        label: 'Dangerous Goods',
        value: Object.keys(dangerousGoods).filter(
          (goods) => dangerousGoods[goods]
        ),
        formatValue: (value) => value.join(', ') || 'None'
      },
      {
        label: 'Shipment Name',
        value: detailAPI?.shipment_name,
        formatValue: (value) => value || ''
      },
      {
        label: 'Shipment Notes/Remarks',
        value: shipmentDetails?.description,
        formatValue: (value) => value || 'None'
      }
    ]
  };

  const customBrokerageServices = {
    title: 'Custom Brokerage Services',
    data: [
      {
        label: 'Custom Clearance',
        value: detailAPI?.is_using_custom_clearance,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Exporter of Record',
        value: detailAPI?.is_eor_present,
        formatValue: (value) => (value ? 'Yes' : 'No')
      },
      {
        label: 'Importer of Record',
        value: detailAPI?.is_ior_present,
        formatValue: (value) => (value ? 'Yes' : 'No')
      }
    ]
  };

  const total = {
    title: 'Total',
    data: [
      {
        label: 'Units By Package Type',
        value: detailAPI?.package_type_units,
        formatValue: (value) => value || 'None'
      },
      {
        label: 'Volume/Measurement (m3)',
        value: detailAPI?.total_volume,
        formatValue: (value) => {
          if (value) {
            const text = formatShipmentModeValue(
              detailAPI?.shipment_mode,
              value
            );
            return `${text} CBM`;
          }
          return 'None';
        }
      },
      {
        label: 'Gross Weight',
        value: detailAPI?.total_actual_weight,
        formatValue: (value) => {
          if (value) {
            return `${Math.ceil(parseFloat(value))} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Volumetric Weight',
        value: detailAPI?.total_volumetric_weight,
        formatValue: (value) => {
          if (value) {
            const text = formatShipmentModeValue(
              detailAPI?.shipment_mode,
              value
            );
            return `${text} KG`;
          }
          return 'None';
        }
      },
      {
        label: 'Total Chargeable Weight',
        value: formatShipmentModeValue(
          detailAPI?.shipment_mode,
          detailAPI?.total_weight
        ),
        formatValue: (value) => {
          if (value) {
            if (detailAPI?.total_weight_unit) {
              return `${value} ${detailAPI?.total_weight_unit}`;
            }
            return `${value} ${
              detailAPI?.shipment_mode === 'Sea' ? 'CBM' : 'KG'
            }`;
          }
          return 'None';
        }
      },
      {
        label: '',
        value: 'Please note that your invoice may change after reweighing.',
        formatValue: (value) => {
          return (
            <div
              style={{
                color: '#050593',
                fontSize: 16,
                fontFamily: 'AvenirLTStdHeavyOblique'
              }}
            >
              {value}
            </div>
          );
        }
      }
    ]
    // formatData: (data) => data.filter((totalWeight) => totalWeight?.value)
  };
  // const getShippingInfoMessage = () => {
  //   if (
  //     [STATUS_SHIPMENT_CREATED, STATUS_QUOTE_DOCUMENT_UPLOADED].includes(
  //       detailAPI?.quote_status
  //     )
  //   ) {
  //     return (
  //       <ShippingInfoMessage>
  //         Updating details of this shipment?&nbsp;
  //         <a
  //           href="/#"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             onEdit();
  //           }}
  //         >
  //           Edit here
  //         </a>
  //         .
  //       </ShippingInfoMessage>
  //     );
  //   }
  //   return null;
  // };
  return (
    <StyledShipmentDetails id="shipment-details">
      {/* {getShippingInfoMessage()} */}
      <CancellationModal
        isCancelModalVisible={isCancelModalVisible}
        setIsCancelModalVisible={setIsCancelModalVisible}
        setCancelReasonError={setCancelReasonError}
        handleCancelShipment={handleCancelShipment}
        handleSetCancelReason={handleSetCancelReason}
        cancelReason={cancelReason}
        cancelReasonError={cancelReasonError}
      />
      <div className="shipment-body">
        {isInternal && <ShipmentDetailRow schema={merchant} />}
        {isInternal && <ShipmentDetailRow schema={yourShipment} />}
        <ShipmentDetailRow schema={shipmentMode} />
        <ShipmentDetailRow schema={origin} />
        <ShipmentDetailRow schema={midMile} />
        <ShipmentDetailRow schema={destination} />
        <ShipmentDetailRow schema={information} />
        <ShipmentDetailRow schema={agreedPrice} />
        {getLoadType() && detailAPI?.shipment_load_type !== null && (
          <ShipmentDetailRow schema={load} />
        )}
        {getLoadDetails().map((loadDetails) => (
          <ShipmentDetailRow key={loadDetails.title} schema={loadDetails} />
        ))}
        {packages.map((item) => (
          <ShipmentDetailRow key={item.title} schema={item} />
        ))}
        <ShipmentDetailRow schema={otherDetails} />
        <ShipmentDetailRow schema={customBrokerageServices} />
        <ShipmentDetailRow schema={total} />
      </div>
      {/* {[STATUS_SHIPMENT_CREATED, STATUS_QUOTE_DOCUMENT_UPLOADED].includes(
        detailAPI?.quote_status
      ) && <Button onClick={onEdit}>Edit</Button>} */}

      {[
        STATUS_SHIPMENT_CREATED,
        STATUS_QUOTE_DOCUMENT_UPLOADED,
        STATUS_QUOTE_SHIPMENT_CONFIRMED
      ].includes(detailAPI?.quote_status) && (
        <CancelLink onClick={() => setIsCancelModalVisible(true)}>
          Cancel Shipment
        </CancelLink>
      )}
    </StyledShipmentDetails>
  );
};

export default ShipmentDetails;
