import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { DatePicker as AntDatePicker } from 'antd';

const { RangePicker: AntRangePicker } = AntDatePicker;

const StyledCalendar = styled.div`
  .ant-select-selection__rendered {
    line-height: 33px;
  }

  .ant-calendar-input,
  .ant-calendar-picker-input.ant-input {
    line-height: 3;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding: 6px 11px 3px 11px;
    color: #000;
  }

  &.range .ant-calendar-picker-input.ant-input {
    line-height: 1;
  }

  .ant-calendar-picker-input.ant-input {
    &:hover {
      border-color: #050593;
    }
    &:focus {
      border-color: #050593;
      box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
    }
  }

  /* Style below contained mode */

  .ant-calendar-input-wrap {
    padding: 6px 0;
  }

  .ant-calendar-date-panel,
  .ant-calendar-input:placeholder-shown {
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }

  .ant-calendar-my-select,
  .ant-calendar-my-select a:hover,
  .ant-calendar-header .ant-calendar-century-select,
  .ant-calendar-header .ant-calendar-decade-select,
  .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-header .ant-calendar-month-select {
    color: #000;
  }

  .ant-calendar-table th,
  .ant-calendar-date[aria-disabled='true'] {
    color: #707070;
  }

  .ant-calendar-today-btn {
    color: #050593;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background-color: #101098;
  }

  .ant-calendar-today div.ant-calendar-date[aria-disabled='false'] {
    color: #050593;
    border-color: #050593;
    background: white;
  }

  .ant-calendar-range
    .ant-calendar-selected-start-date
    .ant-calendar-date:hover,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover,
  .ant-calendar-range .ant-calendar-in-range-cell::before,
  .ant-calendar-date:hover {
    background: #f2f2f9;
    color: rgba(0, 0, 0, 0.65);
  }
`;

const StyledDatePicker = styled.div`
  .ant-calendar-input {
    line-height: 3;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding: 6px 11px 3px 11px;
    color: #000;
  }

  .ant-calendar-input-wrap {
    padding: 6px 0;
  }

  .ant-calendar-date-panel,
  .ant-calendar-input:placeholder-shown {
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }

  .ant-calendar-my-select,
  .ant-calendar-my-select a:hover,
  .ant-calendar-header .ant-calendar-century-select,
  .ant-calendar-header .ant-calendar-decade-select,
  .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-header .ant-calendar-month-select {
    color: #000;
  }

  .ant-calendar-table th,
  .ant-calendar-date[aria-disabled='true'] {
    color: #707070;
  }

  .ant-calendar-today-btn {
    color: #050593;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background-color: #f2f2f9;
  }

  .ant-calendar-today div.ant-calendar-date[aria-disabled='false'] {
    color: #050593;
    border-color: #050593;
    background: white;
  }

  .ant-calendar-date:hover {
    background: #f2f2f9;
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
  .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: #f2f2f9;
  }
`;

const DatePickerContainer = styled.div`
  .ant-select-selection__rendered {
    line-height: 33px;
  }

  .ant-calendar-input,
  .ant-calendar-picker-input.ant-input {
    line-height: 3;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding: 6px 11px 3px 11px;
    color: #000;
    height: 35px;
  }

  .ant-calendar-picker-input.ant-input {
    border: 1px solid #ccc;
    &:hover {
      border-color: #050593;
    }
    &:focus {
      border-color: #050593;
      box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
    }
  }

  .ant-calendar-date-panel,
  .ant-calendar-input:placeholder-shown {
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }
  .ant-calendar-input-wrap {
    height: auto;
    padding: unset;
  }

  .ant-calendar-my-select,
  .ant-calendar-my-select a:hover,
  .ant-calendar-header .ant-calendar-century-select,
  .ant-calendar-header .ant-calendar-decade-select,
  .ant-calendar-header .ant-calendar-year-select,
  .ant-calendar-header .ant-calendar-month-select {
    color: #000;
  }

  .ant-calendar-table th,
  .ant-calendar-date[aria-disabled='true'] {
    color: #707070;
  }

  .ant-calendar-today-btn {
    color: #050593;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background-color: #f2f2f9;
  }

  .ant-calendar-today div.ant-calendar-date[aria-disabled='false'] {
    color: #050593;
    border-color: #050593;
    background: white;
  }

  .ant-calendar-date:hover {
    background: #f2f2f9;
  }
`;

const DatePicker = (props) => {
  const node = useRef();
  return (
    <DatePickerContainer ref={node}>
      <AntDatePicker {...props} getCalendarContainer={() => node.current} />
    </DatePickerContainer>
  );
};

const RangePicker = (props) => {
  const { contained, ...datePickerProps } = props;
  const datePickerContainer = useRef();
  const containedRef = useRef();

  useEffect(() => {
    if (!contained) {
      const mountOn = document.body.appendChild(document.createElement('div'));
      ReactDOM.render(
        <StyledDatePicker
          // eslint-disable-next-line no-return-assign
          ref={(e) => (datePickerContainer.current = e)}
        />,
        mountOn
      );
    }
  }, [contained]);

  return (
    <StyledCalendar ref={containedRef} className="range">
      <AntRangePicker
        {...datePickerProps}
        getCalendarContainer={() => {
          if (contained) {
            return containedRef.current;
          }
          return datePickerContainer.current;
        }}
      />
    </StyledCalendar>
  );
};

DatePicker.RangePicker = RangePicker;

export default DatePicker;
