import validator from 'validator';

export const isChrome =
  /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export const isMeasureUnitValid = (data, unitStatus, max1, max2) =>
  data &&
  validator.isDecimal(data, {
    decimal_digits: '0,2'
  }) &&
  ((unitStatus &&
    validator.isFloat(data, {
      min: 0.1,
      max: max1
    })) ||
    (!unitStatus &&
      validator.isFloat(data, {
        min: 0.1,
        max: max2
      })));

export default { isChrome, isMeasureUnitValid };
