import styled from '@emotion/styled';

// TODO: styles here are not reusable should be on the Page itself
const Container = styled.div`
  // max-width: 1200px;
  margin: 0 40px;
  .clrf {
    content: '';
    margin-top: 15px;
    display: inline-block;
  }
  .table-container {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23050593' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    padding: 5px 20px;
    .flex-spacing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0 0;
      .book-oblique {
        color: #050593;
      }
      h3 {
        color: #000;
        font-size: 20px;
      }
    }
    .ant-table-thead {
      tr th {
        background-color: transparent;
        color: #707070;
        font-size: 16px;
        font-family: AvenirLTStdHeavy;
        border-bottom: 1px solid #1fceca;
        padding: 16px 0;
      }
    }
    .ant-table-tbody {
      tr td {
        font-family: AvenirLTStdBook;
        font-size: 16px;
        text-transform: capitalize;
        color: #000;
        padding: 10px 0;
      }
    }
    .sp1 {
    }
    .sp2 {
      height: 1px;
      width: 20px;
      display: inline-block;
    }
  }
  .wrapper-upload {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    .ant-upload-list-item-card-actions {
      display: none;
    }
    .ant-upload-list-item-info {
      cursor: pointer;
      font-family: AvenirLTStdBookOblique;
      font-size: 16px;
      color: #000000;
      text-decoration: underline;
      width: 190px;
      .ant-upload-list-item-name {
        white-space: pre-line;
        word-break: break-all;
        line-height: 1.2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .ant-upload-list-item:hover .ant-upload-list-item-info {
      background: transparent;
    }
    .ant-upload-list-item {
      margin-top: 0;
      padding: 0;
    }
    .ant-upload {
      width: 115px;
      button {
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .upload-row-item {
    align-items: center;
    display: grid;
    grid-template-columns: 300px auto;
    margin-bottom: 12px;
    h6 {
      margin-bottom: 0;
      display: inline-block;
    }
  }
  .threeColsGrid {
    grid-template-columns: 45% 35% 20%;
    @media (min-width: 1600px) {
      grid-template-columns: 35% 35% 30%;
    }
  }
  .upload-row-item .file-download {
    font-family: AvenirLTStdBookOblique;
    text-decoration: underline;
    font-size: 16px;
    color: #000000;
    white-space: pre-line;
    word-break: break-all;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .ant-upload.ant-upload-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .wrapper-shipment {
    .wrapper-status-shipment {
      display: flex;
      height: 170px;
      padding: 25px 30px;
      background-color: #1fceca33;
      align-items: center;
      img {
        height: 70px;
        margin-right: 30px;
      }
      div p:last-child {
        margin-bottom: 0;
      }
    }
    .underline {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

export default Container;
