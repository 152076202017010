import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';

const StyledBillingDetails = styled.div`
  .billing-body {
    /* padding: 0px 20px; */
  }
  .billing-body div:last-child {
    border-bottom: transparent;
  }
  button {
    margin-top: 15px;
    padding: 0px 41px;
  }
`;
const StyledBillingDetailRow = styled.div`
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 20px;
  }
  li em {
    font-family: AvenirLTStdHeavy;
    font-size: 14px;
    color: #707070;
    display: block;
    font-style: normal;
    margin-bottom: 5px;
  }
  li span {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #000000;
    line-height: 1.25;
    word-break: break-word;
  }
  ul {
    line-height: 1;
    list-style-type: none;
    display: grid;
    grid-template-columns: ${(prop) =>
      prop.templateColumns ||
      'repeat(auto-fit,minmax(calc(100% - 66%),1fr)) 33% 33%'};
    padding: 0;
    grid-gap: 15px;
  }
`;
const Link = styled.a`
  text-decoration: underline;
  color: #050593;
  &:hover {
    text-decoration: underline;
    color: #050593;
  }
`;
const BillingDetailsRow = ({ type, schema, title, children }) => {
  const templateColumnMap = {
    full: '1fr',
    twoCol: '203px auto'
  };

  const [data] = useState(() => {
    if (!schema) return [];
    if (schema.formatData) {
      return schema.formatData(schema.data);
    }
    return schema.data;
  });

  return (
    <>
      {data.length > 0 && (
        <StyledBillingDetailRow templateColumns={templateColumnMap[type]}>
          {(schema?.title || title) && <h5>{schema?.title || title}</h5>}
          <ul>
            {data.map(({ label, value, formatValue, formatLabel }) => (
              <li key={`${label}${value}`}>
                <em className="label">
                  {formatLabel ? formatLabel(label) : label}
                </em>
                <span className="value">
                  {formatValue ? formatValue(value) : value}
                </span>
              </li>
            ))}
          </ul>

          {children && <p>{children}</p>}
        </StyledBillingDetailRow>
      )}
    </>
  );
};
const BillingDetails = () => {
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const billingDetails = {
    data: [
      {
        label: 'Invoice No.',
        value: detailAPI?.invoice_number,
        formatValue: (value) =>
          value ? (
            <Link href={detailAPI.invoice_link} target="_blank">
              {value}
            </Link>
          ) : (
            '-'
          )
      },
      {
        label: 'Credit Note No.',
        value: detailAPI?.credit_note_number,
        formatValue: (value) =>
          value ? (
            <Link href={detailAPI.credit_note_link} target="_blank">
              {value}
            </Link>
          ) : (
            '-'
          )
      }
    ]
  };
  return (
    <StyledBillingDetails id="billing-details">
      <div className="billing-body">
        <BillingDetailsRow schema={billingDetails} />
      </div>
      <p>
        Please contact the finance team for any billing clarifications at
        billing@janio.asia
      </p>
    </StyledBillingDetails>
  );
};

export default BillingDetails;
