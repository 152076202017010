import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Row, Col, Popover, Icon, Input as AntInput } from 'antd';
import moment from 'moment';
import Input from './Input';
import DatePicker from './DatePicker';
import Button from './Button';
import SearchInput from './SearchInput';
import Select from './Select';
import COUNTRIES from '../constants/countries';
import {
  setShipmentFilters,
  resetFilters,
  setShipmentRangeFilters,
  applyFilters
} from '../actions/shipmentList';

const { RangePicker } = DatePicker;

const FilterContainer = styled.div`
  top: 225px;
  left: 184px;
  width: 280px;
  height: 138px;
  z-index: 999;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 20px #00000029;
  opacity: 1;
  &.range-filter {
    width: 330px !important;
  }
  &.filter-container {
    background: #fff;
    padding: 15px;
    box-shadow: 3px 3px 20px #00000029;
    color: #000;
    label {
      font-family: AvenirLTStdBook;
      font-size: 16px;
      margin-bottom: 5px;
      display: block;
      text-align: left;
      .anticon {
        cursor: pointer;
      }
    }
    .ant-select-dropdown-menu {
      text-align: left;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    padding: 1px;
    margin-top: 15px;
    button {
      margin-left: 13px;
      min-width: 60px;
    }
    .reset-filter {
      line-height: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      color: #bbbbbb;
      margin: 0;
      padding: 0;
    }
  }
  .ant-select {
    display: block;
  }
  .field-row {
    margin: 0 0 12px 0 !important;
  }
  .inputRange {
    .rangeStart {
      border-right: 0px;
      width: 32%;
      text-align: center;
    }
    .rangeEnd {
      width: 32%;
      text-align: center;
      border-left: 0;
    }
    .rangeInputSplit {
      width: 10%;
      border-left: 0;
      border-right: 0;
      background-color: #fff;
      pointer-events: none;
    }
  }
`;

const ShipmentFilter = ({
  onFilter = () => {},
  filterName,
  isMinMaxFilter = false,
  confirm = () => {}
}) => {
  const dispatch = useDispatch();
  const { merchants, user_names, user_emails, statuses, loading } = useSelector(
    (state) => state.shipmentList.choices
  );

  const status = useSelector((state) => state.shipmentList.tempFilters.status);
  const trackingNumber = useSelector(
    (state) => state.shipmentList.tempFilters.trackingNumber
  );
  const merchantName = useSelector(
    (state) => state.shipmentList.tempFilters.merchantName
  );
  const shipmentMode = useSelector(
    (state) => state.shipmentList.tempFilters.shipmentMode
  );
  const shipmentName = useSelector(
    (state) => state.shipmentList.tempFilters.shipmentName
  );
  const estDeliveryDate = useSelector((state) =>
    state.shipmentList.tempFilters.estDeliveryDate
      ? state.shipmentList.tempFilters.estDeliveryDate?.map((d) => moment(d)) ||
        []
      : []
  );
  const billingInvoiceNumber = useSelector(
    (state) => state.shipmentList.tempFilters.billingInvoiceNumber
  );
  const createdAt = useSelector((state) =>
    state.shipmentList.tempFilters.createdAt
      ? state.shipmentList.tempFilters.createdAt?.map((d) => moment(d)) || []
      : []
  );
  const origin = useSelector((state) => state.shipmentList.tempFilters.origin);
  const destination = useSelector(
    (state) => state.shipmentList.tempFilters.destination
  );
  const createdBy = useSelector(
    (state) => state.shipmentList.tempFilters.createdBy
  );
  const creatorEmail = useSelector(
    (state) => state.shipmentList.tempFilters.creatorEmail
  );
  const chargeableWeight = useSelector(
    (state) => state.shipmentList.tempFilters.chargeableWeight
  );

  const handleReset = () => {
    dispatch(resetFilters({ key: filterName }));
    const filters = {
      trackingNumber,
      shipmentName,
      status: status === 'All' ? null : status,
      merchantName: merchantName === 'All' ? null : merchantName,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      estDeliveryDate,
      billingInvoiceNumber,
      createdAt,
      chargeableWeight:
        chargeableWeight &&
        (chargeableWeight.min ||
          chargeableWeight.max ||
          chargeableWeight.unit !== 'All')
          ? chargeableWeight
          : null,
      origin: origin === 'All' ? null : origin,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      destination: destination === 'All' ? null : destination,
      [filterName]:
        ['trackingNumber', 'estDeliveryDate', 'createdAt'].indexOf(
          filterName
        ) === -1
          ? ''
          : null
    };
    onFilter(filters);
    confirm();
  };

  const handleFilter = () => {
    onFilter({
      trackingNumber,
      shipmentName,
      status: status === 'All' ? null : status,
      merchantName: merchantName === 'All' ? null : merchantName,
      shipmentMode: shipmentMode === 'All' ? null : shipmentMode,
      estDeliveryDate,
      billingInvoiceNumber,
      createdAt,
      chargeableWeight:
        chargeableWeight &&
        (chargeableWeight.min ||
          chargeableWeight.max ||
          chargeableWeight.unit !== 'All')
          ? chargeableWeight
          : null,
      origin: origin === 'All' ? null : origin,
      createdBy: createdBy === 'All' ? null : createdBy,
      creatorEmail: creatorEmail === 'All' ? null : creatorEmail,
      destination: destination === 'All' ? null : destination
    });
    dispatch(applyFilters());
    confirm();
  };

  const handleSearchInputChange = (field) => (value, option) => {
    dispatch(setShipmentFilters({ [field]: option?.props?.value }));
  };

  const handleUnitChange = (field, key) => (value, option) => {
    dispatch(
      setShipmentRangeFilters({ field, key, value: option?.props?.value })
    );
  };

  const handleDatePickerChange = (field) => (value) => {
    dispatch(
      setShipmentFilters({
        [field]: [
          value[0]?.startOf('day').toISOString(),
          value[1]?.endOf('day').toISOString()
        ]
      })
    );
  };

  const handleTrackerChange = (field) => (e) => {
    dispatch(setShipmentFilters({ [field]: e.target.value }));
  };

  const handleRangeChange = (field, key) => (e) => {
    dispatch(setShipmentRangeFilters({ field, key, value: e.target.value }));
  };

  return (
    <FilterContainer
      className={
        isMinMaxFilter ? 'filter-container range-filter' : 'filter-container'
      }
    >
      {filterName === 'trackingNumber' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="tracking-number">
              Filter tracking number{' '}
              <Popover
                placement="topLeft"
                arrowPointAtCenter
                overlayClassName="tooltip"
                content={
                  <div className="tooltip-content">
                    The tracking number is a unique ID referring to a quote or
                    shipment
                  </div>
                }
                title="Tracking Number"
              >
                <Icon type="question-circle" />
              </Popover>
            </label>
            <Input
              id="tracking-number"
              placeholder="Type..."
              value={trackingNumber}
              onChange={handleTrackerChange('trackingNumber')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'shipmentName' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="shipmentName">Filter Shipment Name</label>
            <Input
              id="shipmentName"
              placeholder="Type..."
              value={shipmentName}
              onChange={handleTrackerChange('shipmentName')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'billingInvoiceNumber' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="billingInvoiceNumber">Filter invoice no.</label>
            <Input
              id="billingInvoiceNumber"
              placeholder="Type..."
              value={billingInvoiceNumber}
              onChange={handleTrackerChange('billingInvoiceNumber')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'merchantName' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="merchant-name">Filter merchant name</label>
            <SearchInput
              placeholder="Type or select..."
              id="merchant-name"
              items={merchants}
              loading={loading}
              value={merchantName}
              onChange={handleSearchInputChange('merchantName')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'status' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-public-status">Filter status</label>
            <SearchInput
              id="quote-public-status"
              items={statuses}
              loading={loading}
              value={status}
              onChange={handleSearchInputChange('status')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'origin' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="origin-country">Filter origin country</label>
            <SearchInput
              id="origin-country"
              items={COUNTRIES}
              value={origin}
              valueKey="value"
              onChange={handleSearchInputChange('origin')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'destination' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="destination-country">
              Filter destination country
            </label>
            <SearchInput
              id="destination-country"
              items={COUNTRIES}
              value={destination}
              valueKey="value"
              onChange={handleSearchInputChange('destination')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'shipmentMode' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="quote-shipment-mode">Filter shipment mode</label>
            <SearchInput
              id="quote-shipment-mode"
              items={[
                { label: 'All', value: 'All' },
                { label: 'Air', value: 'Air' },
                { label: 'Sea', value: 'Sea' },
                { label: 'Land', value: 'Land' }
              ]}
              loading={loading}
              value={shipmentMode}
              onChange={handleSearchInputChange('shipmentMode')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'chargeableWeight' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="chargeableWeight">Filter chargeable weight</label>
            <AntInput.Group compact className="inputRange">
              <Input
                className="rangeStart"
                placeholder="Min"
                value={chargeableWeight.min}
                onChange={handleRangeChange('chargeableWeight', 'min')}
              />
              <Input
                className="site-input-split rangeInputSplit"
                placeholder="~"
                disabled
              />
              <Input
                className="site-input-right rangeEnd"
                placeholder="Max"
                value={chargeableWeight.max}
                onChange={handleRangeChange('chargeableWeight', 'max')}
              />
              <Select
                style={{
                  width: '26%',
                  display: 'inline-block'
                }}
                value={chargeableWeight.unit}
                onChange={handleUnitChange('chargeableWeight', 'unit')}
              >
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="KG">KG</Select.Option>
                <Select.Option value="CBM">CBM</Select.Option>
              </Select>
            </AntInput.Group>
          </Col>
        </Row>
      )}
      {filterName === 'createdBy' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdBy">Filter created by</label>
            <SearchInput
              id="createdBy"
              items={['All', ...user_names].map((name) => ({
                label: name,
                value: name
              }))}
              value={createdBy}
              valueKey="value"
              onChange={handleSearchInputChange('createdBy')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'creatorEmail' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="creatorEmail">Filter creator email</label>
            <SearchInput
              id="creatorEmail"
              items={['All', ...user_emails].map((email) => ({
                label: email,
                value: email
              }))}
              value={creatorEmail}
              valueKey="value"
              onChange={handleSearchInputChange('creatorEmail')}
            />
          </Col>
        </Row>
      )}
      {filterName === 'createdAt' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="createdAt">Filter shipment creation date</label>
            <RangePicker
              id="createdAt"
              value={createdAt}
              onChange={handleDatePickerChange('createdAt')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      {filterName === 'estDeliveryDate' && (
        <Row className="field-row">
          <Col span={24} className="field-container">
            <label htmlFor="estimated-delivery-date">
              Filter estimated delivery date
            </label>
            <RangePicker
              id="estimated-delivery-date"
              value={estDeliveryDate}
              onChange={handleDatePickerChange('estDeliveryDate')}
              contained
              allowClear={false}
            />
          </Col>
        </Row>
      )}
      <div className="actions">
        <Button
          type="link"
          size="small"
          className="reset-filter"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button type="secondary" onClick={handleFilter}>
          Filter
        </Button>
      </div>
    </FilterContainer>
  );
};

export default ShipmentFilter;
