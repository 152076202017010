import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Row } from 'antd';
import validator from 'validator';
import ImgLogo from '../assets/images/logo.png';
import ImgBackground from '../assets/images/login-background.png';
import LoginForm from '../components/LoginForm';
import { login, isLoggedIn } from '../auth0';
import {
  TEXT_MISS_EMAIL,
  TEXT_WRONG_FORMAT_EMAIL,
  TEXT_MISS_PASSWORD,
  TEXT_NOT_MATCH
} from '../constants/message';
import Button from '../components/Button';
import Input from '../components/Input';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => history.push(isLoggedIn() ? '/shipments' : '/login'), [
    history
  ]);

  const validateForm = () => {
    let isOk = true;
    if (validator.isEmpty(email)) {
      setEmailError(TEXT_MISS_EMAIL);
      isOk = false;
    } else if (!validator.isEmail(email)) {
      setEmailError(TEXT_WRONG_FORMAT_EMAIL);
      isOk = false;
    }
    if (validator.isEmpty(password)) {
      setPasswordError(TEXT_MISS_PASSWORD);
      isOk = false;
    }
    return isOk;
  };

  const handleSubmitLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      login(email, password)
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setEmailError(TEXT_NOT_MATCH);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    setEmailError('');
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  return (
    <LoginForm>
      <img className="login-background" alt="backgound" src={ImgBackground} />
      <div className="login-overlay" />
      <form id="loginForm" onSubmit={handleSubmitLogin}>
        <img alt="logo" src={ImgLogo} />
        <div className="form">
          <Row>
            <h2>JANIO B2B MERCHANT</h2>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="email-address-heading">
            <p>Email Address</p>
          </Row>
          <Row className={emailError.error_description ? 'input--error' : ''}>
            <Input
              type="text"
              size="large"
              onChange={(e) => onChangeEmail(e)}
              timeout={100}
            />
          </Row>
          <p className="msg-error">{emailError}</p>
          <Row className="password-heading">
            <p>Password</p>
          </Row>
          <Row className={passwordError ? 'input--error' : ''}>
            <Input
              size="large"
              type="password"
              onChange={(e) => onChangePassword(e)}
              timeout={100}
            />
          </Row>
          <p className="msg-error">{passwordError}</p>
          <Row>
            <span
              className="under-line forgot-password"
              aria-hidden
              onClick={() => history.push('/forgot-password')}
            >
              Forgot password?
            </span>
          </Row>
          <Button
            size="large"
            loading={loading}
            type="primary"
            form="loginForm"
            key="submit"
            htmlType="submit"
            block
          >
            LOGIN
          </Button>
        </div>
      </form>
    </LoginForm>
  );
};

export default Login;
