/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Skeleton as AntSekeleton } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import {
  Table,
  Card,
  ActionBlock,
  Button,
  ShipmentColumnFilter,
  // Modal,
  GenerateCSVModal
} from '../components';
import {
  getShipments,
  getChoices,
  setShipmentFilters,
  setPageFilter,
  cancelFilters,
  resetFilters
} from '../actions/shipmentList';
// import duplicateIcon from '../assets/images/duplicate-icon.png';
// import deleteIcon from '../assets/images/delete-icon.svg';
// import { deleteDraftShipment } from '../services/shipments';
import FilterSVG from '../assets/icons/filter.svg';
import FilterSelectedSVG from '../assets/icons/filterSelected.svg';
import { getProfile } from '../auth0';

// const { confirm: modalConfirm } = Modal;

const DEFAULT_PAGE_SIZE = 20;

const filterIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSVG}
  />
);
const filterSelectedIcon = (
  <img
    style={{ padding: '6px', right: '10px' }}
    className="filterIcon"
    alt="f"
    src={FilterSelectedSVG}
  />
);

const TrackingNumber = styled.span`
  color: #050593;
`;

const StyledActionBlock = styled.span`
  a {
    text-decoration: none;
  }
`;

// const DuplicateButton = styled(Link)`
//   text-align: center;
//   display: block;
//   img {
//     width: 16px;
//     cursor: pointer;
//   }
// `;

// const DeleteButton = styled(Link)`
//   text-align: center;
//   display: block;
//   img {
//     width: 16px;
//     cursor: pointer;
//   }
// `;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 30px 40px;
`;

const Shipments = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.shipmentList.filters.page);
  const pageSize = 10; // useSelector((state) => state.shipmentList.filters.records);
  const filtered = useSelector((state) => state.shipmentList.filtered);
  const [filters, setFilters] = useState();
  const [isInternal, setIsInternal] = useState(false);
  const [generateCSVModalVisible, setGenerateCSVModalVisible] = useState();
  const handleDropdownVisibleChange = (visible) => {
    if (!visible) dispatch(cancelFilters());
  };
  const handleFilter = (value) => {
    dispatch(setShipmentFilters({ page: 1 }));
    setFilters(value);
  };
  const handleResetFilter = () => {
    dispatch(resetFilters());
    handleFilter({});
    dispatch(cancelFilters());
  };
  // const handleDeleteDraftShipment = (id) => () => {
  //   // confirm delete
  //   // delete
  //   modalConfirm({
  //     title: 'Do you want to delete draft shipment?',
  //     okText: 'Yes',
  //     className: 'b2b-confirm-modal',
  //     cancelText: 'No',
  //     width: 436,
  //     onOk() {
  //       return deleteDraftShipment(id)
  //         .exec()
  //         .then(() => {
  //           const recordFilters = { page: currentPage, pageSize, ...filters };
  //           dispatch(getShipments(recordFilters));
  //           dispatch(getChoices());
  //         });
  //     },
  //     onCancel() { }
  //   });
  // };

  const columns = [
    // {
    //   title: 'Duplicate/Delete',
    //   dataIndex: 'duplicate',
    //   key: 'action',
    //   width: 160,
    //   align: 'center',
    //   ellipsis: true,
    //   render: (_, row) => {
    //     const isDraftShipment = row.quoteId.startsWith('DFT');
    //     return (
    //       <React.Fragment>
    //         {!isDraftShipment && (
    //           <DuplicateButton to={`/shipments/create-shipment/${row.quoteId}`}>
    //             <img alt="duplicate" src={duplicateIcon} />
    //           </DuplicateButton>
    //         )}
    //         {isDraftShipment && (
    //           <DeleteButton onClick={handleDeleteDraftShipment(row.quoteId)}>
    //             <img alt="delete" src={deleteIcon} />
    //           </DeleteButton>
    //         )}
    //       </React.Fragment>
    //     );
    //   },
    //   fixed: 'left'
    // },
    {
      title: 'Tracking Number',
      dataIndex: 'quoteId',
      key: 'quoteId',
      width: 240,
      ellipsis: true,
      render: (text) => (
        <Link
          to={
            text.startsWith('DFT')
              ? `/shipments/create-shipment/${text}`
              : `shipments/${text}`
          }
        >
          <TrackingNumber>{text}</TrackingNumber>
        </Link>
      ),
      fixed: 'left',
      filterIcon:
        filters && filters.trackingNumber ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="trackingNumber"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 230,
      ellipsis: true,
      render: (text, record) => (
        <StyledActionBlock>
          <ActionBlock
            status={record.status}
            trackingNumber={record.quoteId}
            text={text}
          />
        </StyledActionBlock>
      ),
      filterIcon: filters && filters.status ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="status"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Shipment Name',
      dataIndex: 'shipmentName',
      key: 'shipmentName',
      width: 270,
      ellipsis: true,
      filterIcon:
        filters && filters.shipmentName ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="shipmentName"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Shipment Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 230,
      ellipsis: true,
      filterIcon:
        filters && filters.createdAt?.length ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="createdAt"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Origin Country',
      dataIndex: 'origin',
      key: 'origin',
      width: 190,
      ellipsis: true,
      filterIcon: filters && filters.origin ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="origin"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Destination Country',
      dataIndex: 'destination',
      key: 'destination',
      width: 200,
      ellipsis: true,
      filterIcon:
        filters && filters.destination ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="destination"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Chargeable Weight',
      dataIndex: 'chargeableWeight',
      key: 'chargeableWeight',
      render: (text, record) =>
        `${parseFloat(text || 0)} ${
          record.shipmentMode === 'Sea' ? 'CBM' : 'KG'
        }`,
      width: 240,
      ellipsis: true,
      filterIcon:
        filters &&
        filters.chargeableWeight &&
        (filters.chargeableWeight.min ||
          filters.chargeableWeight.max ||
          (filters.chargeableWeight.unit &&
            filters.chargeableWeight.unit !== 'All'))
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="chargeableWeight"
          isMinMaxFilter
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Est. Delivery Date',
      dataIndex: 'estDeliveryDate',
      key: 'estDeliveryDate',
      render: (text) => `${text ? moment(text).format('DD MMM YYYY') : '-'}`,
      width: 235,
      ellipsis: true,
      filterIcon:
        filters && filters.estDeliveryDate?.length
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="estDeliveryDate"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Total No. of Units',
      dataIndex: 'packageTypeUnits',
      key: 'packageTypeUnits',
      render: (text) => text || '-',
      width: 260,
      ellipsis: true
    },
    {
      title: 'Invoice No.',
      dataIndex: 'billingInvoiceNumber',
      key: 'billingInvoiceNumber',
      render: (text) => text || '',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.billingInvoiceNumber
          ? filterSelectedIcon
          : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="billingInvoiceNumber"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Mode of Shipment',
      dataIndex: 'shipmentMode',
      key: 'shipmentMode',
      width: 195,
      ellipsis: true,
      filterIcon:
        filters && filters.shipmentMode ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="shipmentMode"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 180,
      ellipsis: true,
      filterIcon:
        filters && filters.merchantName ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="merchantName"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: 140,
      ellipsis: true,
      filterIcon:
        filters && filters.createdBy ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="createdBy"
          onFilter={handleFilter}
        />
      )
    },
    {
      title: 'Creator Email',
      dataIndex: 'creatorEmail',
      key: 'creatorEmail',
      width: 150,
      ellipsis: true,
      filterIcon:
        filters && filters.creatorEmail ? filterSelectedIcon : filterIcon,
      onFilterDropdownVisibleChange: (bool) =>
        handleDropdownVisibleChange(bool),
      filterDropdown: ({ confirm }) => (
        <ShipmentColumnFilter
          confirm={confirm}
          filterName="creatorEmail"
          onFilter={handleFilter}
        />
      )
    }
  ];

  const { data, total: totalCount, loading } = useSelector(
    (state) => state.shipmentList.shipments
  );

  useEffect(() => {
    const recordFilters = { page: currentPage, pageSize, ...filters };
    dispatch(getShipments(recordFilters));
    dispatch(getChoices());
  }, [currentPage, dispatch, filters]);

  useEffect(() => {
    const checkIsInternal = async () => {
      const profile = await getProfile();
      const roles = profile['https://b2b.janio.asia/roles'] || [];
      const internal = roles.includes('merchant/internal');
      setIsInternal(internal);
    };
    checkIsInternal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <GenerateCSVModal
        isModalVisible={generateCSVModalVisible}
        setIsModalVisible={setGenerateCSVModalVisible}
      />
      <StyledCard>
        <Row type="flex" sx={{ mb: '20px' }} gutter={16}>
          <Col>
            {/* <Link to="/shipments/create-shipment">
              <Button type="primary" size="large">
                Create Shipment
              </Button>
            </Link> */}
          </Col>
          <Col sx={{ ml: 'auto', display: 'flex' }}>
            <Button
              size="large"
              type="secondary"
              style={{ marginLeft: '10px' }}
              onClick={() => setGenerateCSVModalVisible(true)}
            >
              Generate CSV
            </Button>
          </Col>
        </Row>
        <Row>
          <Table
            resizeable
            renderEmptyTable={!loading}
            loading={loading}
            type="secondary"
            columns={columns.filter((column) => {
              if (
                isInternal ||
                (!isInternal &&
                  column.key !== 'merchantName' &&
                  column.key !== 'createdBy' &&
                  column.key !== 'creatorEmail')
              ) {
                return true;
              }
              return false;
            })}
            dataSource={data}
            pageSize={parseInt(pageSize || DEFAULT_PAGE_SIZE, 10)}
            currentPage={currentPage}
            onChange={(page) => dispatch(setPageFilter(page))}
            totalCount={totalCount}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No shipment is found.</span>&nbsp;
                    {filtered === true && (
                      <span
                        className="link"
                        role="button"
                        tabIndex={0}
                        onKeyDown={handleResetFilter}
                        onClick={handleResetFilter}
                      >
                        Reset all filters
                      </span>
                    )}
                  </div>
                )}
                {loading && (
                  <AntSekeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
            // onChange={(page) => dispatch(setPageFilter(page))}
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default Shipments;
