import { axiosRequest } from '../utils/request';

const getMerchants = () => {
  const url = `/shipments/merchants-list?isOps=true`;
  return axiosRequest.get(url);
};

export const merchantService = {
  getMerchants
};
