/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row, Col, Skeleton as AntSekeleton } from 'antd';
import styled from '@emotion/styled';
import moment from 'moment';
import _ from 'lodash';
import { Table, Card, Button } from '../components';
import { getQuotes, setPageFilter } from '../actions/quoteRequestsList';

const DEFAULT_PAGE_SIZE = 10;

const TrackingNumber = styled.span`
  color: #050593;
`;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 30px 40px;
`;

const QuoteRequests = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.quoteRequestList?.page);
  const pageSize = 10;
  const columns = [
    {
      title: 'Quote Number',
      dataIndex: 'quoteId',
      key: 'quoteId',
      width: 150,
      ellipsis: true,
      render: (text) => (
        <Link to={`quotes/${text}`}>
          <TrackingNumber>{text}</TrackingNumber>
        </Link>
      ),
      fixed: 'left'
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => (text ? `${moment(text).format('DD MMM YYYY')}` : ''),
      width: 140,
      ellipsis: true
    },
    {
      title: 'Approved/Rejected',
      dataIndex: 'approveRejectedStatus',
      key: 'approveRejectedStatus',
      render: (text) => (text ? `${_.startCase(_.toLower(text))}` : '-'),
      width: 180,
      ellipsis: true
    },
    {
      title: 'Merchant Name',
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 160,
      ellipsis: true
    },
    {
      title: 'Origin',
      dataIndex: 'origin',
      key: 'origin',
      render: (text) => text || '-',
      width: 130,
      ellipsis: true
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (text) => text || '-',
      width: 130,
      ellipsis: true
    },
    {
      title: 'Mode of Shipment',
      dataIndex: 'shipmentMode',
      key: 'shipmentMode',
      render: (text) => text || '-',
      width: 170,
      ellipsis: true
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (text) => text || '-',
      width: 180,
      ellipsis: true
    },
    {
      title: 'Creator Email',
      dataIndex: 'creatorEmail',
      key: 'creatorEmail',
      width: 180,
      ellipsis: true
    },
    {
      title: 'Quote Status',
      dataIndex: 'quoteStatus',
      key: 'quoteStatus',
      render: (text) => (text ? `${_.startCase(_.toLower(text))}` : '-'),
      width: 120,
      ellipsis: true
    },
    {
      title: 'Reason',
      dataIndex: 'winLossReason',
      key: 'winLossReason',
      render: (text) => text || '-',
      width: 180,
      ellipsis: true
    },
    {
      title: 'Shipment Tracking Number',
      dataIndex: 'shipmentQuoteId',
      key: 'shipmentQuoteId',
      render: (text) => text || '-',
      width: 230,
      ellipsis: true
    },
    {
      title: 'Total Cost from NP',
      dataIndex: 'totalCostFromNP',
      key: 'networkPartnerCost',
      render: (text, record) =>
        record.networkPartnerCost
          ? `${record.npCurrency} ${record.networkPartnerCost}`
          : '-',
      width: 200,
      ellipsis: true
    },
    {
      title: 'Total Quote Price',
      dataIndex: 'totalQuotePrice',
      key: 'totalQuotePrice',
      render: (text, record) =>
        record.totalQuotePrice
          ? `${record.qpCurrency} ${record.totalQuotePrice}`
          : '-',
      width: 200,
      ellipsis: true
    }
  ];

  const { data, total: totalCount, loading } = useSelector(
    (state) => state.quoteRequestList.quotes
  );

  useEffect(() => {
    const recordFilters = { page: currentPage, pageSize };
    dispatch(getQuotes(recordFilters));
  }, [currentPage, dispatch]);

  return (
    <div>
      <StyledCard>
        <Row type="flex" sx={{ mb: '20px' }} gutter={16}>
          <Col>
            <Link to="/quotes/create-quote">
              <Button type="primary" size="large">
                Create Quote
              </Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Table
            resizeable
            renderEmptyTable={!loading}
            loading={loading}
            type="primary"
            columns={columns}
            dataSource={data}
            pageSize={parseInt(pageSize || DEFAULT_PAGE_SIZE, 10)}
            currentPage={currentPage}
            onChange={(page) => dispatch(setPageFilter(page))}
            totalCount={totalCount}
            empty={
              <React.Fragment>
                {!loading && (
                  <div>
                    <span>No quote is found.</span>&nbsp;
                  </div>
                )}
                {loading && (
                  <AntSekeleton
                    active
                    title={{ width: '100%' }}
                    paragraph={{
                      rows: 5,
                      width: ['100%', '100%', '100%', '100%', '100%']
                    }}
                  />
                )}
              </React.Fragment>
            }
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default QuoteRequests;
