import React, {
  forwardRef,
  useState,
  useCallback,
  useRef,
  useEffect
} from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';

const StyledNumberInput = styled(AntInputNumber)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  /* height: 35px; */
  color: #000;
  /* Font alignment adjustment for AvenirLTStdBook */
  height: 35px;
  border: 1px solid #ccc;
  &:hover {
    border-color: #050593;
  }
  &:focus {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
  .ant-input-number-input-wrap input {
    height: 35px;
  }
`;

const NumberInput = (props, ref) => {
  const {
    onChange = () => {},
    value: propValue,
    timeout = 100,
    ...rest
  } = props;
  const [value, setValue] = useState(() => propValue);

  const debouncedOnChangeRef = useRef();
  debouncedOnChangeRef.current = onChange;

  const debouncedOnChange = useCallback(
    debounce(debouncedOnChangeRef.current, timeout),
    [onChange]
  );

  const inputEventRef = useRef();

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  useEffect(() => {
    if (inputEventRef.current !== undefined) {
      debouncedOnChange(inputEventRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (e) => {
    if (/^[0-9]+$/.test(e) || e === '') {
      inputEventRef.current = e;
      setValue(e);
    }
  };

  return (
    <StyledNumberInput
      {...rest}
      onChange={handleOnChange}
      ref={ref}
      value={value}
    />
  );
};

export default forwardRef(NumberInput);
