import React from 'react';
import { Radio as AntRadio } from 'antd';
import styled from '@emotion/styled';

const StyledRadio = styled(AntRadio)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  color: #000000;
  min-width: 105px;
  .ant-radio-inner::after {
    background-color: #050593;
  }
  .ant-radio-checked .ant-radio-inner,
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #050593;
  }
  .ant-radio {
    top: -2px;
  }
`;

const Radio = ({ children, ...rest }) => {
  return <StyledRadio {...rest}>{children}</StyledRadio>;
};

Radio.Group = AntRadio.Group;

export default Radio;
