/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useCallback } from 'react';
import { Icon, Popover } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import VolumeWeightInput from './VolumeWeightInput';
import Button from '../Button';
import {
  calculateTotalChargableWeight,
  calculateTotalVoume,
  calculateTotalWeight,
  calculateTotalVolumetricWeight
} from '../../helpers/calculate';
import { formatShipmentModeValue } from '../../utils/formatString';

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;

const PackageOperations = styled.div`
  display: flex;
  button {
    span {
      color: #050593;
    }
  }
  span {
    margin-left: auto;
    margin-bottom: 0;
    align-self: center;
    font-size: 16px;
    font-family: AvenirLTStdBook;
    color: #000;
  }
`;

const PackageContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;

// Pass calculate logic
const PackageDimension = ({
  modeOfShipment,
  isQuote,
  packages = [],
  packageTypes = [],
  onChange = () => {},
  // eslint-disable-next-line no-unused-vars
  onBlur = () => {},
  loading = false
}) => {
  const packageItemInit = () => {
    return {
      pId: randomstring.generate(),
      amount: 1,
      // https://github.com/ant-design/ant-design/issues/11330#issuecomment-405826778
      type: undefined,
      dimensions: {
        height: null,
        isCm: true,
        length: null,
        width: null,
        amount: null
      },
      weight: {
        amount: null,
        isKg: true
      },
      volumeType: true,
      weightType: false,
      chargeableWeight: null
    };
  };
  // eslint-disable-next-line no-unused-vars
  const [totalChargeableWeight, setTotalChargeableWeight] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalChargeableWeightUnit, setTotalChargeableWeightUnit] = useState(
    'KG'
  );
  // eslint-disable-next-line no-unused-vars
  const [
    totalChargeabeWeightVolumeLabel,
    setTotalChargeabeWeightVolumeLabel
  ] = useState('Total Chargeable Wt.');
  const [errors, setErrors] = useState({});
  const [packageItems, setPackages] = useState(() => {
    if (packages.length > 0) {
      return packages;
    }
    return [packageItemInit()];
  });

  useEffect(() => {
    if (packages.length > 0) {
      return setPackages(packages);
    }
    return setPackages([packageItemInit()]);
  }, [packages]);

  const calculateTotalChargeableWeight = useCallback(() => {
    return modeOfShipment === 'Sea'
      ? calculateTotalVoume(packageItems)
      : calculateTotalChargableWeight(packageItems);
  }, [packageItems, modeOfShipment]);

  const handleChange = (idx, pid) => (e, err) => {
    const packageChange = [...packageItems];
    packageChange[idx] = e;
    setPackages(packageChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [pid]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [pid]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const handleOnBlur = (pid) => (e, err) => {
    if (err !== null) {
      setErrors({
        ...errors,
        [pid]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [pid]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  useDeepCompareEffect(() => {
    const total = calculateTotalChargeableWeight();
    setTotalChargeableWeight(total);
    onChange(packageItems, Object.keys(errors).length > 0);
  }, [packageItems, errors]);

  useEffect(() => {
    if (modeOfShipment === 'Sea') {
      setTotalChargeabeWeightVolumeLabel('Chargeable Vol.');
      setTotalChargeableWeightUnit('CBM');
    } else {
      setTotalChargeabeWeightVolumeLabel('Total Chargeable Wt.');
      setTotalChargeableWeightUnit('KG');
    }
    const total = formatShipmentModeValue(
      modeOfShipment,
      calculateTotalChargeableWeight()
    );
    setTotalChargeableWeight(total);
  }, [calculateTotalChargeableWeight, modeOfShipment]);

  const newPackage = () => {
    setPackages([...packageItems, packageItemInit()]);
  };

  const removePackage = (idx, pid) => () => {
    const newPackages = [...packageItems];
    newPackages.splice(idx, 1);
    setPackages(newPackages);
    const { [pid]: _err, ...errs } = errors;
    setErrors(errs);
  };
  // eslint-disable-next-line no-unused-vars
  const getMetrics = () => {
    if (modeOfShipment === 'Sea') {
      return `Gross Wt., Vol.: ${Math.ceil(
        calculateTotalWeight(packageItems)
      )} KG, ${calculateTotalVoume(packageItems)} CBM; `;
    }
    return `Gross Wt.: ${Math.ceil(
      calculateTotalWeight(packageItems)
    )} KG; Volumetric Wt.: ${Math.ceil(
      calculateTotalVolumetricWeight(packageItems)
    )} KG; `;
  };

  return (
    <>
      {packageItems.map((packageItem, idx) => {
        return (
          <PackageContainer key={packageItem.pId}>
            <VolumeWeightInput
              value={packageItem}
              modeOfShipment={modeOfShipment}
              isQuote={isQuote}
              showDelete={idx > 0}
              onDelete={removePackage(idx, packageItem.pId)}
              packageTypes={packageTypes.map((type) => ({
                label: type.value,
                value: type.key
              }))}
              loading={loading}
              onChange={handleChange(idx, packageItem.pId)}
              onBlur={handleOnBlur(packageItem.pId)}
            />
          </PackageContainer>
        );
      })}

      <PackageOperations>
        <Button type="secondary" onClick={newPackage}>
          <Icon type="plus-circle" />
          Add another set of package
        </Button>
        <span>
          {getMetrics()}
          <strong>{`${totalChargeabeWeightVolumeLabel}: ${totalChargeableWeight} ${totalChargeableWeightUnit}`}</strong>
          <StyledPopover
            title={<h4>Chargeable Weight/Volume</h4>}
            content={
              <>
                <p>
                  Chargeable weight is measured as either the gross weight or
                  volumetric weight, whichever is heavier. It is applied in air
                  and land freights.
                </p>
                <p>
                  Chargeable volume is measured by cubic metres (CBM). It is
                  applied in sea freights.
                </p>
                <p>
                  Disclaimer: Chargeable weight/volume may change based on
                  shipment re-weighing
                </p>
              </>
            }
          >
            <Icon className="question-circle" type="question-circle" />
          </StyledPopover>
        </span>
      </PackageOperations>
    </>
  );
};

export default PackageDimension;
