import React, { useState, useRef, useEffect, useReducer } from 'react';
import styled from '@emotion/styled';
import { message } from 'antd';
// import { geocodeByAddress } from 'react-places-autocomplete';
import validator from 'validator';
// import { get } from 'lodash';
import LocationSearchInput from './LocationSearchInput';
import SearchInput from './SearchInput';
import { addAddress } from '../actions/address';
import {
  TEXT_MISSING_FIELD,
  TEXT_LIMIT_50,
  TEXT_LIMIT_250,
  TEXT_VALID,
  TEXT_ADD_ADDRESS_SUCCESS
  // TEXT_ADDRESS_INVALID
} from '../constants/message';
import {
  invalidCharacter,
  invalidUnicodeCharacter
} from '../utils/formatString';

import Button from './Button';
import Input from './Input';
import Modal from './Modal';

const Container = styled(Modal)`
  .ant-modal-footer button {
    min-width: 120px;
    text-align: center;
  }
`;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 410px auto;
`;
const FormRow = styled.div`
  display: flex;
  margin-bottom: ${(props) => (props.error ? '0' : '15px')};
`;
const FormColumn = styled.div`
  width: ${(props) => `${props.width}px` || 'auto'};
`;
const FormItem = styled.div`
  .ant-select {
    min-width: 200px;
  }
`;

const AddressForm = ({
  list,
  onAdded,
  visible,
  // onError = () => {},
  onCancel = () => {}
}) => {
  const [state, setState] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {}
  );
  const [err, setErr] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {}
  );
  const [loading, setLoading] = useState(false);
  // const retry = useRef(0);

  const {
    locationName = '',
    country = null,
    city = '',
    stateAdr = '',
    streetAddress = '',
    postalCode = '',
    codeCountry = '',
    pocName = '',
    pocPhoneNumber = ''
  } = state;

  let countryEl = useRef(null);
  let stateEl = useRef(null);
  const isChrome =
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

  const validateForm = () => {
    let isValidate = true;
    if (!validator.isLength(locationName, { min: 0, max: 50 })) {
      setErr({
        locationName: TEXT_LIMIT_50
      });
      isValidate = false;
    } else if (locationName && invalidCharacter(locationName)) {
      setErr({
        locationName: TEXT_VALID
      });
      isValidate = false;
    }

    if (!country || validator.isEmpty(country)) {
      setErr({ country: `${TEXT_MISSING_FIELD} Country.` });
      isValidate = false;
    }

    if (validator.isEmpty(city)) {
      setErr({ city: `${TEXT_MISSING_FIELD} City.` });
      isValidate = false;
    }

    if (validator.isEmpty(stateAdr)) {
      setErr({ stateAdr: `${TEXT_MISSING_FIELD} State.` });
      isValidate = false;
    }

    if (validator.isEmpty(postalCode)) {
      setErr({ postalCode: `${TEXT_MISSING_FIELD} Postal Code.` });
      isValidate = false;
    } else if (postalCode && !/^[0-9A-Z\- ]+$/.test(postalCode)) {
      setErr({
        postalCode: TEXT_VALID
      });
      isValidate = false;
    }

    if (validator.isEmpty(streetAddress)) {
      setErr({ streetAddress: `${TEXT_MISSING_FIELD} Address.` });
      isValidate = false;
    } else if (!validator.isLength(streetAddress, { min: 0, max: 250 })) {
      setErr({
        streetAddress: TEXT_LIMIT_250
      });
      isValidate = false;
    } else if (streetAddress && invalidUnicodeCharacter(streetAddress)) {
      setErr({
        streetAddress: TEXT_VALID
      });
      isValidate = false;
    }

    if (validator.isEmpty(pocName)) {
      setErr({ pocName: `${TEXT_MISSING_FIELD} PoC's Name.` });
      isValidate = false;
    } else if (pocName && invalidCharacter(pocName)) {
      setErr({
        streetAddress: TEXT_VALID
      });
      isValidate = false;
    }

    if (validator.isEmpty(pocPhoneNumber)) {
      setErr({
        pocPhoneNumber: `${TEXT_MISSING_FIELD} PoC's Phone Number.`
      });
      isValidate = false;
    } else if (pocPhoneNumber && invalidCharacter(pocPhoneNumber)) {
      setErr({
        pocPhoneNumber: TEXT_VALID
      });
      isValidate = false;
    }

    return isValidate;
  };

  const handleAddAddress = () => {
    return addAddress({ ...state, googleLocationId: null }, (data) => {
      message.success(TEXT_ADD_ADDRESS_SUCCESS);
      onAdded(country, data.data);
      setLoading(false);
    });
  };

  // TODO: temporarily not using this due to B2B-378
  // const validateAndAddAddress = async (withAddress = true) => {
  //   if (retry.current > 2) return;
  //   try {
  //     let googlePlace = [];
  //     if (withAddress) {
  //       googlePlace = await geocodeByAddress(
  //         `${streetAddress} ${stateAdr} ${city} ${country}`
  //       );
  //     } else {
  //       googlePlace = await geocodeByAddress(`${stateAdr} ${city} ${country}`);
  //     }
  //     const googleLocationId = googlePlace[0].place_id;
  //     await addAddress({ ...state, googleLocationId }, (data) => {
  //       message.success(TEXT_ADD_ADDRESS_SUCCESS);
  //       onAdded(country, data.data);
  //       setLoading(false);
  //     });
  //     retry.current = 0;
  //   } catch (error) {
  //     const result = get(error, 'response.data.result');
  //     if (result) {
  //       message.error(result);
  //     } else if (error === 'ZERO_RESULTS') {
  //       retry.current += 1;
  //       validateAndAddAddress(false);
  //     } else {
  //       message.error(TEXT_ADDRESS_INVALID);
  //     }
  //     onError();
  //     setLoading(false);
  //   }
  // };

  const resetForm = () => {
    setState({
      locationName: '',
      country: null,
      city: '',
      stateAdr: '',
      streetAddress: '',
      postalCode: '',
      codeCountry: '',
      pocName: '',
      pocPhoneNumber: ''
    });
    onCancel();
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validateForm()) {
      await handleAddAddress();
      resetForm();
    } else {
      setLoading(false);
    }
  };

  const handleOnChangeInput = (e) => {
    setErr({ [e.target.name]: '' });
    setState({ [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const searchInputs = document.getElementsByClassName(
      'ant-select-search__field'
    );
    for (let i = 0; i < searchInputs.length; i += 1) {
      searchInputs[i].setAttribute(
        'autocomplete',
        isChrome ? 'disabled' : 'off'
      );
    }
  });

  return (
    <Container
      title="Add address"
      visible={visible}
      onCancel={resetForm}
      closable={false}
      width={720}
      footer={[
        <Button key="back" type="secondary" onClick={resetForm}>
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={loading}
          onClick={(e) => handleOnSubmit(e)}
        >
          Add
        </Button>
      ]}
    >
      <FormContainer>
        <FormColumn>
          <h4>Location Information</h4>
          <FormRow error={err.locationName}>
            <FormItem style={{ width: '100%' }}>
              <p>Location name (Optional)</p>
              <Input
                placeholder="e.g. My Warehouse"
                value={locationName}
                name="locationName"
                onChange={handleOnChangeInput}
              />
              <p className="msg-error">{err.locationName}</p>
            </FormItem>
          </FormRow>
          <FormRow error={err.country || err.stateAdr}>
            <FormItem style={{ maxWidth: '205px', paddingRight: '5px' }}>
              <p>Country/Region</p>
              <SearchInput
                setRef={(_ref) => {
                  countryEl = _ref;
                }}
                items={list}
                placeholder="Select"
                value={country}
                onChange={(value, option) => {
                  setState({
                    country: value,
                    city: '',
                    stateAdr: '',
                    streetAddress: '',
                    postalCode: ''
                  });
                  setState({ codeCountry: option.key });
                  setErr({ country: '' });
                }}
                handleOnBlur={() => {}}
              />
              <p className="msg-error">{err.country}</p>
            </FormItem>
            <FormItem style={{ maxWidth: '205px', paddingLeft: '5px' }}>
              <p>State/Province/Region</p>
              <LocationSearchInput
                setRef={(_ref) => {
                  stateEl = _ref;
                }}
                country={codeCountry}
                types={['(regions)']}
                placeholder="Type here"
                value={stateAdr}
                onChange={(value) => {
                  setErr({ stateAdr: '' });
                  setState({ stateAdr: value });
                }}
                onFocusInput={() => {
                  if (!country) {
                    setErr({ country: `${TEXT_MISSING_FIELD} Country.` });
                    countryEl.focus();
                  }
                }}
              />
              <p className="msg-error">{err.stateAdr}</p>
            </FormItem>
          </FormRow>
          <FormRow error={err.city || err.postalCode}>
            <FormItem style={{ maxWidth: '205px', paddingRight: '5px' }}>
              <p>City</p>
              <LocationSearchInput
                country={codeCountry}
                types={['(cities)']}
                placeholder="Type here"
                value={city}
                onChange={(value) => {
                  setState({ city: value });
                  setErr({ city: '' });
                }}
                onFocusInput={() => {
                  if (!stateAdr) {
                    setErr({ stateAdr: `${TEXT_MISSING_FIELD} State.` });
                    stateEl.focus();
                  }
                  if (!country) {
                    setErr({ country: `${TEXT_MISSING_FIELD} Country.` });
                    countryEl.focus();
                  }
                }}
              />
              <p className="msg-error">{err.city}</p>
            </FormItem>
            <FormItem style={{ maxWidth: '205px', paddingLeft: '5px' }}>
              <p>Postal Code</p>
              <Input
                autoComplete={isChrome ? 'disabled' : 'off'}
                placeholder="Type here"
                value={postalCode}
                name="postalCode"
                onChange={handleOnChangeInput}
              />
              <p className="msg-error">{err.postalCode}</p>
            </FormItem>
          </FormRow>
          <FormRow error={err.streetAddress}>
            <FormItem style={{ width: '100%' }}>
              <p>Address</p>
              <LocationSearchInput
                country={codeCountry}
                types={['address']}
                placeholder="Type here"
                value={streetAddress}
                handleFilter={() => true}
                freeText
                onChange={(value) => {
                  setState({ streetAddress: value });
                  setErr({ streetAddress: '' });
                }}
                handleOnClick={(value) => {
                  setState({ streetAddress: value });
                }}
                onFocusInput={() => {
                  if (!country) {
                    setErr({ country: `${TEXT_MISSING_FIELD} Country.` });
                    countryEl.focus();
                  }
                }}
              />
              <p className="msg-error">{err.streetAddress}</p>
            </FormItem>
          </FormRow>
        </FormColumn>
        <FormColumn
          style={{
            marginLeft: '25px',
            paddingLeft: '25px',
            borderLeft: '1px solid #ccc'
          }}
        >
          <h4>Point of contact (PoC)</h4>
          <FormRow>
            <FormItem>
              <p>PoC&#8217;s Name</p>
              <Input
                autoComplete={isChrome ? 'disabled' : 'off'}
                placeholder="Type here"
                value={pocName}
                name="pocName"
                onChange={handleOnChangeInput}
              />
              <p className="msg-error">{err.pocName}</p>
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem>
              <p>PoC&#8217;s Phone Number</p>
              <Input
                autoComplete={isChrome ? 'disabled' : 'off'}
                placeholder="Type here"
                value={pocPhoneNumber}
                name="pocPhoneNumber"
                onChange={handleOnChangeInput}
              />
              <p className="msg-error">{err.pocPhoneNumber}</p>
            </FormItem>
          </FormRow>
        </FormColumn>
      </FormContainer>
    </Container>
  );
};
export default AddressForm;
