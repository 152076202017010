import React from 'react';
import styled from '@emotion/styled';
import { Upload, Icon, Popover } from 'antd';
import Button from './Button';

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;
export const StyledShipmentDocuments = styled.div`
  .shipment-documents-body {
    padding: 0;
    border-bottom: 1px solid #050593;
    margin-bottom: 15px;
  }
  .file-download {
    i {
      margin: 5px;
    }
  }
  .upload-document-container {
    background: #f2f2f9;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 25px;
    position: relative;
    .upload-body {
      display: flex;
    }
  }
  .upload-input {
    width: 100%;
    display: flex;
  }
  .upload-input-box {
    width: 268px;
    font-size: 16px;
    text-align: right;
    p {
      text-align: left;
      margin-top: 5px;
    }
  }
  .upload-input-buttons {
    font-size: 16px;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
    margin-top: 35px;
  }
  .upload-input-add-button {
    margin-left: 100px;
  }
  .upload-input-upload-button {
    font-size: 16px;
    text-align: left;
    button {
      max-width: 200px;
      display: flex;
      justify-content: center;

      i {
        align-self: center;
      }
      span {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
      }
    }
  }
  .upload-document-container button.ant-btn-link {
    position: absolute;
    padding: 0;
    right: 10px;
    top: 20px;
    color: #000;
    font-size: 12px;
  }
  .upload-row-item {
    margin-top: 15px;
    justify-content: space-between;
    display: grid;
    grid-template-columns: 40% 35% 15%;
    @media (min-width: 1600px) {
      grid-template-columns: 35% 35% 15%;
    }
    a {
      margin-top: 6px;
    }
    .ant-upload-list {
      display: none;
    }
  }
`;
const TemplateLink = styled.a`
  font-size: 16px;
  font-style: italic;
  font-family: AvenirLTStdBook;
  line-height: 16px;
  background-color: transparent;
  border-color: transparent;

  color: #050593;
  text-decoration: underline;

  &:hover {
    color: #050593;
    text-decoration: underline;
  }
`;

const ShipmentDocuments = ({ setDataFile, dataFile, dangerousGoods }) => {
  const { packingListFile, commercialInvoiceFile, quotationForm, MSDSFile } =
    dataFile || {};

  return (
    <StyledShipmentDocuments>
      <div className="upload-row-item">
        <h6>
          Packing List - Optional (
          <TemplateLink href="/templates/Packing List template.xlsx">
            Template
          </TemplateLink>
          )
        </h6>
        <span aria-hidden className="file-download">
          {packingListFile && packingListFile.name && <Icon type="file" />}
          {packingListFile && packingListFile.name}
        </span>
        <Upload
          className="wrapper-upload"
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
          beforeUpload={(file) => {
            setDataFile({
              packingListFile: file
            });
            return false;
          }}
          fileList={packingListFile ? [packingListFile] : []}
        >
          <Button type="secondary" size="large" className="btn-change">
            {packingListFile ? 'Replace' : 'Upload'}
          </Button>
        </Upload>
      </div>
      <div className="upload-row-item">
        <h6>
          Commercial Invoice - Optional (
          <TemplateLink href="/templates/Commercial Invoice template.xlsx">
            Template
          </TemplateLink>
          )
        </h6>
        <span aria-hidden className="file-download">
          {commercialInvoiceFile && commercialInvoiceFile.name && (
            <Icon type="file" />
          )}
          {commercialInvoiceFile && commercialInvoiceFile.name}
        </span>
        <Upload
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
          className="wrapper-upload"
          beforeUpload={(file) => {
            setDataFile({ commercialInvoiceFile: file });
            return false;
          }}
          fileList={commercialInvoiceFile ? [commercialInvoiceFile] : []}
        >
          <Button type="secondary" size="large" className="btn-change">
            {commercialInvoiceFile ? 'Replace' : 'Upload'}
          </Button>
        </Upload>
      </div>
      <div className="upload-row-item">
        <h6>
          Quotation Form - Optional (
          <TemplateLink href="/templates/Quotation Template.xlsx">
            Template
          </TemplateLink>
          )
        </h6>
        <span aria-hidden className="file-download">
          {quotationForm && quotationForm.name && <Icon type="file" />}
          {quotationForm && quotationForm.name}
        </span>
        <Upload
          accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
          className="wrapper-upload"
          beforeUpload={(file) => {
            setDataFile({ quotationForm: file });
            return false;
          }}
          fileList={quotationForm ? [quotationForm] : []}
        >
          <Button type="secondary" size="large" className="btn-change">
            {quotationForm ? 'Replace' : 'Upload'}
          </Button>
        </Upload>
      </div>
      {dangerousGoods && (
        <div className="upload-row-item">
          <h6>
            Material Safety Data Sheets (MSDS)
            <span>
              &nbsp;
              <StyledPopover
                title={<h4>MSDS (Material Safety Data Sheet)</h4>}
                content={
                  <ul>
                    <li>
                      We need to update MSDS(Material Safety Data Sheet) as
                      shipment contains hazardous goods.
                    </li>
                    <li>Please check with the shipper for the MSDS.</li>
                  </ul>
                }
              >
                <Icon className="question-circle" type="question-circle" />
              </StyledPopover>
            </span>
          </h6>
          <span className="file-download">
            {MSDSFile && MSDSFile.name && <Icon type="file" />}
            {MSDSFile && MSDSFile.name}
          </span>
          <Upload
            accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
            className="wrapper-upload"
            beforeUpload={(file) => {
              setDataFile({ MSDSFile: file });
              return false;
            }}
            fileList={MSDSFile ? [MSDSFile] : []}
          >
            <Button type="secondary" size="large" className="btn-change">
              {MSDSFile ? 'Replace' : 'Upload'}
            </Button>
          </Upload>
        </div>
      )}
    </StyledShipmentDocuments>
  );
};

export default ShipmentDocuments;
