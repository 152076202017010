/* eslint-disable no-use-before-define */
import auth0 from 'auth0-js';
import randomstring from 'randomstring';
import history from './helpers/history';

const state = randomstring.generate();

const CONNECTION_TYPE = 'Username-Password-Authentication';

const configAuth0 = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  redirectUri: `${window.location.origin}/callback`,
  audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
  scope: 'openid profile email',
  responseType: 'token id_token',
  state
};

const auth0Client = new auth0.WebAuth(configAuth0);

const clearStorage = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('expires_at');
  localStorage.removeItem('profile');
  localStorage.removeItem('state');
};

export const login = (email, password) => {
  clearStorage();
  return new Promise((resolve, reject) => {
    localStorage.setItem('state', state);
    auth0Client.login(
      { realm: CONNECTION_TYPE, email, password },
      (error, result) => {
        if (error) {
          reject(error);
        } else if (result) {
          resolve(result);
        }
      }
    );
  });
};

export const handleAuthentication = () => {
  auth0Client.parseHash(
    { state: localStorage.getItem('state') },
    (err, authResult) => {
      if (authResult && authResult.accessToken) {
        setSession(authResult);
        history.replace('/shipments');
      } else if (err) {
        history.replace('/');
      }
    }
  );
};

const setSession = async (authResult) => {
  localStorage.setItem('access_token', authResult.accessToken);
  localStorage.setItem('id_token', authResult.idToken);
};

export const logout = (e) => {
  e.preventDefault();
  clearStorage();
  auth0Client.logout(
    {
      returnTo: `${window.location.origin}/login`,
      clientID: configAuth0.clientID
    },
    () => {}
  );
};

export const getAccessToken = () => {
  const accessToken = localStorage.getItem('access_token');
  if (!accessToken) {
    throw new Error('No access token found');
  }
  return accessToken;
};

export const getProfile = (opts) => {
  return new Promise((resolve, reject) => {
    const userProfile = opts?.reset ? null : getProfileSync();
    if (userProfile && Object.keys(userProfile).length > 0) {
      resolve(userProfile);
    } else {
      const accessToken = getAccessToken();
      auth0Client.client.userInfo(accessToken, (err, profile) => {
        if (err) {
          reject(err);
        } else if (profile) {
          localStorage.setItem('profile', JSON.stringify(profile));
          resolve(profile);
        }
      });
    }
  });
};

export const getProfileSync = () => {
  const profile = localStorage.getItem('profile');
  return profile ? JSON.parse(profile) : {};
};

export const isLoggedIn = () => {
  // Checks if there is a saved token and it's still valid
  const token = localStorage.getItem('access_token');
  return !!token;
};

export const forgotPassword = (e, email, callback) => {
  e.preventDefault();
  auth0Client.changePassword(
    {
      connection: 'Username-Password-Authentication',
      email
    },
    (err, resp) => {
      callback(err, resp);
    }
  );
};
