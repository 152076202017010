import request, { axiosRequest } from '../utils/request';
import {
  removeEmpty,
  queryString,
  formatToISOString
} from '../utils/formatString';

const getShipments = async ({
  status = '',
  page = 1,
  pageSize = 10,
  merchantName = '',
  trackingNumber = '',
  shipmentMode = '',
  shipmentName = '',
  billingInvoiceNumber = '',
  origin = '',
  destination = '',
  createdBy = '',
  creatorEmail = '',
  estDeliveryDate = [],
  createdAt = [],
  chargeableWeight = ''
}) => {
  const params = removeEmpty({
    status,
    page,
    records: pageSize,
    merchantName,
    trackingNumber,
    shipmentMode,
    shipmentName,
    billingInvoiceNumber,
    origin,
    destination,
    createdBy,
    creatorEmail,
    estDeliveryDate: estDeliveryDate?.map(formatToISOString) || [],
    createdAt: createdAt?.map(formatToISOString) || [],
    chargeableWeight
  });
  params.chargeableWeight = JSON.stringify(params.chargeableWeight);
  const queryParams = queryString(params);
  const url = `/shipments/merchant?${queryParams}`;
  const response = await request().get(url);
  const { quotes: shipments, total } = response.data.data;
  return {
    shipments: shipments.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

const getShipmentFilters = async () => {
  const url = '/shipments/merchant/filters';
  const response = await request().get(url);
  return response.data.data;
};

const getShipmentTrackingIds = async () => {
  const url = '/shipments/tracking-ids';
  const response = await request().get(url);
  const shipmentTrackingIds = response.data?.data?.shipmentTrackingIds;
  return shipmentTrackingIds;
};

const exportShipmentsCSV = async (data) => {
  const url = '/shipments/export';
  const response = await axiosRequest.post(url, data).exec();
  return response.data;
};

const createShipment = (shipment) => {
  const url = `/shipments/create`;
  return axiosRequest.post(url, shipment);
};

export const deleteDraftShipment = (id) => {
  const url = `shipments/delete/${id}`;
  return axiosRequest.delete(url);
};

export const shipmentService = {
  getShipments,
  getShipmentFilters,
  createShipment,
  deleteDraftShipment,
  exportShipmentsCSV,
  getShipmentTrackingIds
};
