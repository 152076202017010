import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { Upload, Icon, Popover } from 'antd';
import Button from './Button';
import MultiSelect from './MultiSelect';
import Message from './Message';
import { invalidCharacter, toTitleCase } from '../utils/formatString';

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;
export const StyledShipmentDocuments = styled.div`
  .shipment-documents-body {
    padding: 0;
    border-bottom: 1px solid #050593;
    margin-bottom: 15px;
  }
  .threeColsGrid {
    grid-template-columns: 45% 35% 20%;
    @media (min-width: 1600px) {
      grid-template-columns: 35% 35% 30%;
    }
  }
  .upload-document-container {
    background: #f2f2f9;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 25px;
    position: relative;
    .upload-body {
      display: flex;
    }
  }
  .upload-input {
    width: 100%;
    display: flex;
  }
  .upload-input-box {
    width: 250px;
    font-size: 16px;
    text-align: right;
    p {
      text-align: left;
      margin-top: 5px;
    }
  }
  .upload-input-buttons {
    font-size: 16px;
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
    margin-top: 35px;
  }
  .upload-input-add-button {
    margin-left: 100px;
  }
  .upload-input-upload-button {
    font-size: 16px;
    text-align: left;
    button {
      max-width: 200px;
      display: flex;
      justify-content: center;

      i {
        align-self: center;
      }
      span {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 150px;
        overflow: hidden;
      }
    }
  }
  .upload-document-container button.ant-btn-link {
    position: absolute;
    padding: 0;
    right: 10px;
    top: 20px;
    color: #000;
    font-size: 12px;
  }
  .upload-row-item-custom-doc {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 155px auto;
    a {
      margin-top: 6px;
    }
  }
`;
const TemplateLink = styled.a`
  font-size: 16px;
  font-style: italic;
  font-family: AvenirLTStdBook;
  line-height: 16px;
  background-color: transparent;
  border-color: transparent;

  color: #050593;
  text-decoration: underline;

  &:hover {
    color: #050593;
    text-decoration: underline;
  }
`;
export const UploadDocumentsDialog = (props) => {
  const [docType, setDocType] = useState('');
  const [fileUploadRequest, setfileUploadRequest] = useState({});
  const [fileUploadError, setFileUploadError] = useState('');
  const [fileUploadDirty, setFileUploadDirty] = useState(false);
  const [fileName, setFileName] = useState('');

  const validationSchema = Yup.object().shape({
    docType: Yup.mixed()
      .nullable()
      .test('required', 'Please enter Document Name', (value) => value)
      .test(
        'max',
        'You have exceeded the maximum 50 characters length limit',
        (value) => {
          return (value?.length || 0) <= 50;
        }
      )
      .test('docType', 'Please enter valid characters', (value) => {
        return !invalidCharacter(value);
      }),
  });

  const validate = () => {
    validationSchema
      .validate({ docType })
      .then(() => {
        setFileUploadError(null);
      })
      .catch((err) => {
        setFileUploadError(err.message);
      });
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docType, fileName]);

  const validateFileUploadRequest = () => {
    if (docType) {
      if (fileUploadRequest[docType]) {
        return fileUploadRequest;
      }
      if (fileUploadRequest['']) {
        const request = {
          [docType
            .trim()
            .toUpperCase()
            .replace(new RegExp(' ', 'g'), '_')]: fileUploadRequest[''],
        };
        setfileUploadRequest(request);
        return request;
      }
    }
    return null;
  };

  return (
    <div className="upload-document-container">
      <div className="upload-header">
        <Button
          onClick={() => {
            props.closeButton(false);
          }}
          type="link"
          icon="close"
          size="large"
        />
        <div className="upload-instructions">
          <h6>Upload new document</h6>
        </div>
      </div>
      <div className="upload-body">
        <div className="upload-input-box">
          <p>Document Name</p>
          <MultiSelect
            mode="combobox"
            placeholder="Type or select entry"
            showSearch="true"
            onBlur={() => {
              setFileUploadDirty(true);
            }}
            onChange={(value) => {
              setDocType(value);
            }}
            items={[
              'Master Airway Bill',
              'House Airway Bill',
              'Proof of Delivery',
            ]}
          />
          <Message type="error">{fileUploadDirty && fileUploadError}</Message>
        </div>
        <div className="upload-input-buttons">
          <div className="upload-input-upload-button">
            <Upload
              className="wrapper-upload"
              accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
              showUploadList={false}
              beforeUpload={(file) => {
                const request = {};
                // TODO: needs to come up with a better stratergy for this one to make it more complex to create new doc types.
                request[''] = file;
                setFileName(file.name);
                setfileUploadRequest(request);
                return false;
              }}
            >
              <Button
                type="secondary"
                icon={fileName ? 'close' : 'upload'}
                ghost
              >
                {fileName || 'Upload'}
              </Button>
            </Upload>
          </div>
          <div className="upload-input-add-button">
            <Button
              onClick={() => {
                const request = validateFileUploadRequest();
                if (request && !fileUploadError) {
                  props.addCustomFile(request);
                }
              }}
              loading={props.uploading}
              type="secondary"
              disabled={fileUploadError || !fileName}
              ghost
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
const ShipmentDocuments = ({
  handleSubmitButton,
  loading,
  detailAPI,
  publicDocuments,
  documentsMap,
  selectedDocType,
  handleReplaceUpload,
  uploadDialog,
  setuploadDialog
}) => {
  let isSubmitButtonDisabled = true;
  const requiredDocs = publicDocuments.filter(
    (doc) =>
      doc.document_type === 'PACKING_LIST' ||
      doc.document_type === 'COMMERCIAL_INVOICE' ||
      doc.document_type === 'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'
  );
  const uploadedDocs = publicDocuments.filter(
    (doc) =>
      (doc.document_type === 'PACKING_LIST' ||
        doc.document_type === 'COMMERCIAL_INVOICE' ||
        doc.document_type === 'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)') &&
      doc.document_id
  );
  if (requiredDocs.length === uploadedDocs.length) {
    isSubmitButtonDisabled = false;
  }

  return (
    <StyledShipmentDocuments>
      {publicDocuments?.map((doc) => {
        return (
          <>
            <div className="threeColsGrid upload-row-item">
              <h6>
                {documentsMap[doc.document_type] ||
                  (doc.document_type &&
                    toTitleCase(
                      doc.document_type?.replace(new RegExp('_', 'g'), ' ')
                    ))}
                {doc.document_type === 'PACKING_LIST' && (
                  <span>
                    &nbsp;(
                    <TemplateLink href="/templates/Packing List template.xlsx">
                      Template
                    </TemplateLink>
                    )
                  </span>
                )}
                {doc.document_type === 'COMMERCIAL_INVOICE' && (
                  <span>
                    &nbsp;(
                    <TemplateLink href="/templates/Commercial Invoice template.xlsx">
                      Template
                    </TemplateLink>
                    )
                  </span>
                )}
                {doc.document_type === 'IMPORTER_OF_RECORD_(IOR)' && (
                  <span>
                    &nbsp;
                    <StyledPopover
                      title={<h4>IOR (Importer Of Record)</h4>}
                      content={
                        <p>
                          Is a person or a company responsible for arranging
                          everything legal related in an import operation on the
                          country of importing.
                        </p>
                      }
                    >
                      <Icon
                        className="question-circle"
                        type="question-circle"
                      />
                    </StyledPopover>
                  </span>
                )}
                {doc.document_type === 'EXPORTER_OF_RECORD_(EOR)' && (
                  <span>
                    &nbsp;
                    <StyledPopover
                      title={<h4>EOR (Exporter Of Record)</h4>}
                      content={
                        <p>
                          An Exporter of Record is the legal exporting entity
                          for goods exiting a country.
                        </p>
                      }
                    >
                      <Icon
                        className="question-circle"
                        type="question-circle"
                      />
                    </StyledPopover>
                  </span>
                )}
                {doc.document_type === 'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)' && (
                  <span>
                    &nbsp;
                    <StyledPopover
                      title={<h4>MSDS (Material Safety Data Sheet)</h4>}
                      content={
                        <ul>
                          <li>
                            We need to update MSDS(Material Safety Data Sheet)
                            as shipment contains hazardous goods.
                          </li>
                          <li>Please check with the shipper for the MSDS.</li>
                        </ul>
                      }
                    >
                      <Icon
                        className="question-circle"
                        type="question-circle"
                      />
                    </StyledPopover>
                  </span>
                )}
              </h6>
              <a href={doc?.document_url ? doc.document_url : '#'}>
                <span
                  aria-hidden
                  // onClick={() => handleDownload(doc)}
                  className="file-download"
                >
                  {doc && doc.document_title && <Icon type="file" />}
                  {doc && doc.document_title}
                </span>
              </a>
              <Upload
                className="wrapper-upload"
                accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
                showUploadList={false}
                disabled={doc.document_type === selectedDocType && loading}
                beforeUpload={(file) => {
                  const request = {};
                  request[doc.document_type] = file;
                  handleReplaceUpload(request, doc.document_type);
                  return false;
                }}
              >
                <Button
                  loading={doc.document_type === selectedDocType && loading}
                  type="secondary"
                  size="large"
                  className="btn-change"
                >
                  {doc && (doc.document_title ? 'Replace' : 'Upload')}
                </Button>
              </Upload>
            </div>
          </>
        );
      })}
      {uploadDialog && (
        <div className="shipment-documents-body">
          <UploadDocumentsDialog
            quoteId={detailAPI?.quote_id}
            closeButton={setuploadDialog}
            uploading={selectedDocType === 'newDoc' && loading}
            replace
            addCustomFile={(request) => {
              handleReplaceUpload(request, 'newDoc', true);
            }}
          />
        </div>
      )}
      <div className="upload-row-item">
        <Button
          style={{
            width: '225px',
            marginTop: '30px',
          }}
          size="large"
          type="secondary"
          onClick={() => {
            setuploadDialog(true);
          }}
        >
          Upload New Documents
        </Button>
        <Button
          style={{
            width: '185px',
            marginTop: '30px',
            justifySelf: 'right',
          }}
          size="large"
          type="primary"
          onClick={handleSubmitButton}
          disabled={isSubmitButtonDisabled}
          // disabled={
          //   !(
          //     packingListFile &&
          //     commercialInvoiceFile &&
          //     (((shipmentDetails.contains_battery ||
          //       shipmentDetails.contains_hazardous_goods ||
          //       shipmentDetails.other) &&
          //       MSDSFile) ||
          //       !(
          //         shipmentDetails.contains_battery ||
          //         shipmentDetails.contains_hazardous_goods ||
          //         shipmentDetails.other
          //       ))
          //   )
          // }
          loading={selectedDocType === 'submitDocs' && loading}
        >
          Submit Documents
        </Button>
      </div>
    </StyledShipmentDocuments>
  );
};

export default ShipmentDocuments;
