import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import styled from '@emotion/styled';
import { useScript } from './helpers/hooks';
import { handleAuthentication, isLoggedIn } from './auth0';
import ShipmentDetail from './pages/shipment-detail';
import Shipments from './pages/shipments';
import QuotesRequests from './pages/quote-requests';
import Addresses from './pages/addresses';
// import CreateShipment from './pages/create-shipment';
import CreateQuote from './pages/create-quote';
import Header from './components/Header';
import Login from './pages/login';
import Error from './pages/error';
import ForgetPassword from './pages/forgot-password';
import Faqs from './pages/faqs';
import RequestQuotesDetails from './pages/request-quote-details';

const mixpanel = require('mixpanel-browser');

const Main = styled.div`
  padding-top: 60px;
  padding-bottom: 1px;
`;

const PrivateRoute = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      isLoggedIn() ? (
        <Main>
          <Header />
          {children}
        </Main>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: location }
          }}
        />
      )
    }
  />
);

const App = () => {
  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API}&libraries=places`
  );
  useScript(
    `${window.location.origin}/js/hotjar.${process.env.REACT_APP_HOTJAR_ENV}.js`
  );
  if (process.env.REACT_APP_HOTJAR_ENV === 'prod') {
    mixpanel.init('a1a0b17505b25a019fea6f13a8cc0936');
    mixpanel.track('Page View');
  }
  const authTrigger = (nextState) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      handleAuthentication();
    }
  };

  return (
    <Switch>
      <PrivateRoute exact path="/">
        <Redirect
          to={{
            pathname: '/shipments'
          }}
        />
      </PrivateRoute>
      <PrivateRoute exact path="/shipments">
        <Shipments />
      </PrivateRoute>
      <PrivateRoute exact path="/addresses">
        <Addresses />
      </PrivateRoute>
      <PrivateRoute exact path="/faqs">
        <Faqs />
      </PrivateRoute>
      {/* <PrivateRoute exact path="/shipments/create-shipment">
        <CreateShipment />
      </PrivateRoute> */}
      {/* <PrivateRoute path="/shipments/create-shipment/:trackingId">
        <CreateShipment />
      </PrivateRoute> */}
      <PrivateRoute path="/shipments/:quoteId">
        <ShipmentDetail />
      </PrivateRoute>
      <PrivateRoute exact path="/quotes/create-quote">
        <CreateQuote />
      </PrivateRoute>
      <PrivateRoute exact path="/quotes">
        <QuotesRequests />
      </PrivateRoute>
      <PrivateRoute path="/quotes/:quoteId">
        <RequestQuotesDetails />
      </PrivateRoute>

      <Route path="/error/:errorCode" component={Error} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot-password" component={ForgetPassword} />
      <Route
        exact
        path="/callback"
        render={(props) => {
          authTrigger(props);
        }}
      />
      <Route component={Error} />
    </Switch>
  );
};

export default App;
