import React from 'react';
import styled from '@emotion/styled';
import { cx } from 'emotion';
import Card from './Card';

const StyledFormSection = styled(Card)`
  width: calc(100% - 30px);
  max-width: 960px;
  margin: 5px auto 25px auto;
  padding: 10px 40px;
  &.locked .title {
    opacity: 0.6;
  }

  .wrapper {
    margin: 30px 0;
    max-width: 850px;
  }

  .wrapper--shipment-dimensions-outside-ui {
    margin-top: 10px;
    width: calc(100% - 10px);
    button {
      padding-left: 0;
    }
    button > i {
      margin-top: 6px;
    }
  }

  .question-circle {
    padding-left: 5px;
  }

  .title {
    position: relative;
    display: flex;
    align-items: center;
    h3 {
      margin-bottom: 0px;
    }
  }
`;

const NumberBadge = styled.span`
  background: ${(props) => `${props.bkColor} 0% 0% no-repeat padding-box`};
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-family: AvenirLTStdHeavy;
  color: #ffffff;
  font-size: 20px;
  margin-right: 25px;
`;

const FormSection = ({ title, number, locked, children, bkColor }) => (
  <StyledFormSection className={cx({ locked })}>
    <div className="title">
      <NumberBadge bkColor={bkColor || '#1fceca'}>{number}</NumberBadge>
      <h3>{title}</h3>
    </div>
    {!locked && children}
  </StyledFormSection>
);

export default FormSection;
