import React, { useEffect, useReducer } from 'react';
import styled from '@emotion/styled';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Row, Col, Tabs, message, Skeleton, Icon, Upload, Popover } from 'antd';
import moment from 'moment';

// import axios from 'axios';
import Modal from '../components/Modal';
import Container from '../components/QuoteDetailsContainer';
// import Chatbox from '../components/Chatbox';
import Button from '../components/Button';
import {
  getFullDataRequestQuote,
  uploadDocument,
  acceptDocument,
  reSetData,
  updateQuoteStatus,
  subscribeToEmail
} from '../actions/quoteDetail';
import {
  TEXT_DOCUMENT_UPLOADED,
  TEXT_DOCUMENT_REPLACED,
  TEXT_DOCUMENT_ALREADY_VERIFIED,
  TEXT_REQUEST_FAILED,
  EMAIL_SUB_FAILED,
  EMAIL_SUB_SUCCESS
  // TEXT_DOWNLOAD_FAILED
} from '../constants/message';
import {
  STATUS_QUOTE_REQUESTED,
  STATUS_QUOTE_DRAFT_CREATED,
  STATUS_QUOTE_CREATED,
  STATUS_QUOTE_ACCEPTED,
  STATUS_QUOTE_REQUEST_UNSUCCESSFUL,
  STATUS_QUOTE_DOCUMENT_UPLOADED,
  STATUS_QUOTE_SHIPMENT_CONFIRMED,
  STATUS_QUOTE_EXPIRED,
  STATUS_QUOTE_REREQUESTED_DRAFT,
  STATUS_SCHEDULED_FOR_PICKUP,
  STATUS_RESCHEDULED_FOR_PICKUP,
  STATUS_SCHEDULED_FOR_DROPOFF,
  STATUS_RESCHEDULED_FOR_DROPOFF,
  STATUS_SHIPMENT_PICKEDUP,
  STATUS_REACHED_ORIGIN_WAREHOUSE,
  STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
  STATUS_RECEIVED_BY_AIRLINES,
  STATUS_IN_TRANSHIPMENT_COUNTRY,
  STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
  STATUS_PENDING_CUSTOM_CLEARANCE,
  STATUS_OUT_FOR_DELIVERY,
  STATUS_DELIVERED_WITHOUT_POD,
  STATUS_DELIVERED,
  STATUS_CANCELLED,
  STATUS_SHIPMENT_CREATED,
  STATUS_BILLED,
  STATUS_FLIGHT_RESCHEDULED,
  STATUS_OFFLOADED_BY_AIRLINES,
  STATUS_CONTAINER_LOADING,
  STATUS_CARGO_ONBOARD_VESSEL,
  STATUS_ON_SAIL,
  STATUS_PARTIAL_UPLIFT_TO_DESTINATION,
  STATUS_FREIGHT_UPLIFT_COMPLETED,
  STATUS_RESCHEDULED_FOR_DELIVERY,
  STATUS_DELIVERY_FAILED,
  STATUS_PARTIALLY_DELIVERED,
  STATUS_VESSEL_DELAYED,
  STATUS_SPACE_BOOKING_IN_PROGRESS
} from '../constants/status';
import { toTitleCase } from '../utils/formatString';

import Card from '../components/Card';
import QuoteDetailsTabs from '../components/QuoteDetailsTabs';
import ShipmentDetails from '../components/ShipmentDetails';
import ShipmentStatus from '../components/ShipmentStatus';
import ShipmentHistory from '../components/ShipmentHistory';
import TrackingUpdatesModal from '../components/TrackingUpdatesModal';
import ShipmentDocuments, {
  UploadDocumentsDialog,
  StyledShipmentDocuments
} from '../components/ShipmentDocuments';
import BillingDetails from '../components/BillingDetails';

const StyledPopover = styled(Popover)`
  cursor: pointer;
  margin-left: 5px;
`;

const StyledCard = styled(Card)``;

const TrackingUpdatesButton = styled(Button)`
  width: 170px;
  white-space: normal;
  height: auto;
  padding: 8px 21px;
  line-height: 15.5px;
`;
const { TabPane } = Tabs;

const QuoteHeader = styled.div`
  h3 {
    margin-bottom: 15px;
  }
  padding: 30px 40px 0px 40px;
`;

const ShipmentDetail = (props) => {
  const { match = {} } = props;
  const dispatch = useDispatch();
  const [selectedDocType, setSelectedDocType] = React.useState('');
  const [uploadDialog, setuploadDialog] = React.useState(false);

  useEffect(() => {
    dispatch(getFullDataRequestQuote(match.params.quoteId));
    window.scrollTo(0, 0);
    return () => {
      dispatch(reSetData());
    };
  }, [dispatch, match.params.quoteId]);

  const [state, setState] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {
      tabActive: null,
      loading: false,
      subEmail: '',
      statusModal: { cancelShipment: false, subscribe: false }
    }
  );

  const { tabActive, loading, statusModal } = state;

  const [dataFile, setDataFile] = useReducer(
    (prestate, newState) => ({ ...prestate, ...newState }),
    {}
  );

  const handleSubscribe = () => {
    subscribeToEmail(
      { email: state.subEmail, id: match.params.quoteId },
      () => {
        message.success(EMAIL_SUB_SUCCESS);
        setState({ email: '' });
      },
      () => {
        message.error(EMAIL_SUB_FAILED);
      }
    );
  };

  const acceptQuote = () => {
    setState({ loading: true });
    dispatch(
      acceptDocument(
        match.params.quoteId,
        () => {
          setState({ tabActive: STATUS_QUOTE_ACCEPTED });
          setState({ loading: false });
        },
        () => {
          message.error(TEXT_REQUEST_FAILED);
          setState({ loading: false });
        }
      )
    );
  };

  const detailAPI = useSelector(
    (stateStore) =>
      (stateStore.quoteDetail && stateStore.quoteDetail.quote) || {
        origin: {},
        destination: {},
        packages: [],
        shipment_details: {
          contains_battery: false,
          contains_hazardous_goods: false,
          other: false,
          contains_no_special_goods: false
        },
        documents: []
      }
  );

  const shipmentDetails = detailAPI.shipment_details;

  const shippingLog = useSelector((stateStore) => {
    if (stateStore.quoteDetail && stateStore.quoteDetail.shipping_log) {
      const groupedLog = {};
      // eslint-disable-next-line no-unused-expressions
      stateStore.quoteDetail.shipping_log.forEach((item) => {
        if (item.type !== 0) {
          if (groupedLog[moment(item.created_at).format('YYYY-MM-DD')]) {
            groupedLog[moment(item.created_at).format('YYYY-MM-DD')].push(item);
          } else {
            groupedLog[moment(item.created_at).format('YYYY-MM-DD')] = [item];
          }
        }
      });
      return groupedLog;
    }
    return [];
  });

  const isDelivered =
    detailAPI.quote_status === STATUS_DELIVERED ||
    detailAPI.quote_status === STATUS_DELIVERED_WITHOUT_POD;
  const isBilled = detailAPI.quote_status === STATUS_BILLED;
  const isShipmentCancelled = detailAPI.quote_status === STATUS_CANCELLED;
  const isQuoteRequestUnsuccessful =
    detailAPI.quote_status === STATUS_QUOTE_REQUEST_UNSUCCESSFUL;
  const isQuoteExpired = detailAPI.quote_status === STATUS_QUOTE_EXPIRED;
  const isQuoteAccepted =
    detailAPI.quote_status === STATUS_QUOTE_ACCEPTED ||
    detailAPI.quote_status === STATUS_SHIPMENT_CREATED;
  const isDocumentUploaded =
    detailAPI.quote_status === STATUS_QUOTE_DOCUMENT_UPLOADED;
  const isShipmentBilled = detailAPI.quote_status === STATUS_BILLED;
  const isShipmentConfirmed = [
    STATUS_QUOTE_SHIPMENT_CONFIRMED,
    STATUS_SCHEDULED_FOR_PICKUP,
    STATUS_RESCHEDULED_FOR_PICKUP,
    STATUS_SCHEDULED_FOR_DROPOFF,
    STATUS_RESCHEDULED_FOR_DROPOFF,
    STATUS_SHIPMENT_PICKEDUP,
    STATUS_REACHED_ORIGIN_WAREHOUSE,
    STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
    STATUS_FLIGHT_RESCHEDULED,
    STATUS_CONTAINER_LOADING,
    STATUS_RECEIVED_BY_AIRLINES,
    STATUS_CARGO_ONBOARD_VESSEL,
    STATUS_ON_SAIL,
    STATUS_PARTIAL_UPLIFT_TO_DESTINATION,
    STATUS_FREIGHT_UPLIFT_COMPLETED,
    STATUS_OFFLOADED_BY_AIRLINES,
    STATUS_IN_TRANSHIPMENT_COUNTRY,
    STATUS_PENDING_CUSTOM_CLEARANCE,
    STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
    STATUS_OUT_FOR_DELIVERY,
    STATUS_DELIVERED,
    STATUS_DELIVERED_WITHOUT_POD,
    STATUS_BILLED,
    STATUS_RESCHEDULED_FOR_DELIVERY,
    STATUS_DELIVERY_FAILED,
    STATUS_PARTIALLY_DELIVERED,
    STATUS_VESSEL_DELAYED,
    STATUS_SPACE_BOOKING_IN_PROGRESS
  ].includes(detailAPI.quote_status);

  const HeaderTitle = () => {
    switch (detailAPI.quote_status) {
      case STATUS_QUOTE_DRAFT_CREATED:
      case STATUS_QUOTE_REQUESTED:
        return (
          <QuoteHeader>
            <h3>We have received your request for quote</h3>
            <p>Let us give you an update via email within 1 working day.</p>
          </QuoteHeader>
        );
      case STATUS_QUOTE_REREQUESTED_DRAFT:
        return (
          <QuoteHeader>
            <h3>Here&apos;s the quote for your shipment</h3>
            <Button size="large" type="primary" disabled>
              Accept Quote
            </Button>
          </QuoteHeader>
        );
      case STATUS_QUOTE_CREATED:
        return (
          <QuoteHeader>
            <h3>Here&apos;s the quote for your shipment</h3>
            <Button
              size="large"
              type="primary"
              loading={loading}
              onClick={acceptQuote}
            >
              Accept Quote
            </Button>
          </QuoteHeader>
        );
      case STATUS_SHIPMENT_CREATED:
      case STATUS_QUOTE_ACCEPTED:
        return (
          <QuoteHeader>
            <h3>Let&apos;s complete your order</h3>
            <p>Please upload the required documents below.</p>
            <p>
              Once you are done, click on
              <strong> Submit Documents</strong> to complete your shipment.
            </p>
          </QuoteHeader>
        );
      case STATUS_QUOTE_DOCUMENT_UPLOADED:
        return (
          <QuoteHeader>
            <h3>You have submitted your shipment documents</h3>
            <p>We&apos;ll process your documents and get back immediately.</p>
            <p>Here are the latest versions of the documents you uploaded.</p>
          </QuoteHeader>
        );
      case STATUS_QUOTE_SHIPMENT_CONFIRMED:
        return (
          <QuoteHeader>
            <h3>Your shipment is confirmed!</h3>
          </QuoteHeader>
        );
      case STATUS_SCHEDULED_FOR_DROPOFF:
      case STATUS_RESCHEDULED_FOR_DROPOFF:
      case STATUS_SCHEDULED_FOR_PICKUP:
      case STATUS_RESCHEDULED_FOR_PICKUP:
      case STATUS_REACHED_ORIGIN_WAREHOUSE:
      case STATUS_RECEIVED_BY_AIRLINES:
      case STATUS_PENDING_CUSTOM_CLEARANCE:
      case STATUS_OUT_FOR_DELIVERY:
      case STATUS_SHIPMENT_PICKEDUP:
      case STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL:
      case STATUS_IN_TRANSHIPMENT_COUNTRY:
      case STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY:
      case STATUS_FLIGHT_RESCHEDULED:
      case STATUS_CONTAINER_LOADING:
      case STATUS_CARGO_ONBOARD_VESSEL:
      case STATUS_ON_SAIL:
      case STATUS_PARTIAL_UPLIFT_TO_DESTINATION:
      case STATUS_PARTIALLY_DELIVERED:
      case STATUS_VESSEL_DELAYED:
      case STATUS_SPACE_BOOKING_IN_PROGRESS:
      case STATUS_OFFLOADED_BY_AIRLINES:
        return (
          <QuoteHeader>
            <h3>Your shipment is in progress</h3>
          </QuoteHeader>
        );
      case STATUS_DELIVERED:
      case STATUS_DELIVERED_WITHOUT_POD:
        return (
          <QuoteHeader>
            <h3>Your shipment is delivered</h3>
          </QuoteHeader>
        );
      case STATUS_BILLED:
        return (
          <QuoteHeader>
            <h3>Your shipment is billed</h3>
          </QuoteHeader>
        );
      case STATUS_FREIGHT_UPLIFT_COMPLETED:
        return (
          <QuoteHeader>
            <h3>Your shipment has beed uplifted</h3>
          </QuoteHeader>
        );
      case STATUS_CANCELLED:
        return (
          <QuoteHeader>
            <h3>Your shipment is cancelled</h3>
            <p>Reason: {detailAPI?.shipment_cancellation_reason}</p>
          </QuoteHeader>
        );
      case STATUS_QUOTE_REQUEST_UNSUCCESSFUL:
        return (
          <QuoteHeader>
            <h3>We are unable to process your quote successfully</h3>
            <p>Reason: {detailAPI?.quote_rejection_reason}</p>
            <p>You may submit a new quote request.</p>
          </QuoteHeader>
        );
      case STATUS_RESCHEDULED_FOR_DELIVERY:
        return (
          <QuoteHeader>
            <h3>Your shipment is in progress</h3>
          </QuoteHeader>
        );
      case STATUS_DELIVERY_FAILED:
        return (
          <QuoteHeader>
            <h3>Shipment delivery has failed</h3>
          </QuoteHeader>
        );
      default:
        return null;
    }
  };

  const getActiveTabByStatus = (status) => {
    if (status === STATUS_QUOTE_DRAFT_CREATED) {
      return STATUS_QUOTE_REQUESTED;
    }
    if (status === STATUS_QUOTE_REREQUESTED_DRAFT) {
      return STATUS_QUOTE_CREATED;
    }
    if (status === STATUS_CANCELLED) {
      return STATUS_QUOTE_DOCUMENT_UPLOADED;
    }
    if (status === STATUS_SHIPMENT_CREATED) {
      return STATUS_QUOTE_ACCEPTED;
    }
    if (
      [
        STATUS_SCHEDULED_FOR_PICKUP,
        STATUS_RESCHEDULED_FOR_PICKUP,
        STATUS_SCHEDULED_FOR_DROPOFF,
        STATUS_RESCHEDULED_FOR_DROPOFF,
        STATUS_SHIPMENT_PICKEDUP,
        STATUS_REACHED_ORIGIN_WAREHOUSE,
        STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
        STATUS_FLIGHT_RESCHEDULED,
        STATUS_CONTAINER_LOADING,
        STATUS_RECEIVED_BY_AIRLINES,
        STATUS_ON_SAIL,
        STATUS_PARTIAL_UPLIFT_TO_DESTINATION,
        STATUS_FREIGHT_UPLIFT_COMPLETED,
        STATUS_OFFLOADED_BY_AIRLINES,
        STATUS_CARGO_ONBOARD_VESSEL,
        STATUS_IN_TRANSHIPMENT_COUNTRY,
        STATUS_PENDING_CUSTOM_CLEARANCE,
        STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
        STATUS_OUT_FOR_DELIVERY,
        STATUS_DELIVERED,
        STATUS_RESCHEDULED_FOR_DELIVERY,
        STATUS_DELIVERY_FAILED,
        STATUS_DELIVERED_WITHOUT_POD,
        STATUS_PARTIALLY_DELIVERED,
        STATUS_VESSEL_DELAYED,
        STATUS_SPACE_BOOKING_IN_PROGRESS
      ].includes(status)
    ) {
      return STATUS_QUOTE_SHIPMENT_CONFIRMED;
    }
    return status;
  };

  const documentsMap = {
    COMMERCIAL_INVOICE: 'Commercial Invoice',
    'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)': 'Material Safety Data Sheets (MSDS)',
    'IMPORTER_OF_RECORD_(IOR)': 'Importer of Record (IOR)',
    'EXPORTER_OF_RECORD_(EOR)': 'Exporter of Record (EOR)',
    QUOTE: 'Customer Quotation'
  };
  const handleSubmitButton = (e) => {
    e.preventDefault();
    setSelectedDocType('submitDocs');
    setState({ loading: true });
    dispatch(
      uploadDocument(
        {
          Id: match.params.quoteId
        },
        () => {
          message.success(TEXT_DOCUMENT_UPLOADED);
          setState({ tabActive: STATUS_QUOTE_DOCUMENT_UPLOADED });
          setState({ loading: false });
        },
        () => {
          message.error(TEXT_REQUEST_FAILED);
          setState({ loading: false });
        }
      )
    );
  };

  const handleReplaceUpload = async (file, type, newDoc = false) => {
    setState({ loading: true });
    setSelectedDocType(type);
    await dispatch(
      uploadDocument(
        {
          data: file,
          Id: match.params.quoteId,
          replace: true
        },
        () => {
          message.success(TEXT_DOCUMENT_REPLACED);
          // setState({ tabActive: STATUS_QUOTE_DOCUMENT_UPLOADED });
          if (newDoc) setuploadDialog(false);
          setState({ loading: false });
        },
        () => {
          message.error(TEXT_REQUEST_FAILED);
          setState({ loading: false });
          if (newDoc) setuploadDialog(false);
          setSelectedDocType('');
        }
      )
    );
  };
  let defaultPublicDocuments = [
    {
      document_type: 'PACKING_LIST'
    },
    {
      document_type: 'COMMERCIAL_INVOICE'
    },
    {
      document_type: 'QUOTE'
    }
  ];
  if (
    detailAPI.shipment_details.contains_battery ||
    detailAPI.shipment_details.contains_hazardous_goods ||
    detailAPI.shipment_details.other
  ) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)' }
    ];
  }
  if (detailAPI.is_eor_present) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'EXPORTER_OF_RECORD_(EOR)' }
    ];
  }
  if (detailAPI.is_ior_present) {
    defaultPublicDocuments = [
      ...defaultPublicDocuments,
      { document_type: 'IMPORTER_OF_RECORD_(IOR)' }
    ];
  }

  const getPublicDocuments = () => {
    // get the default document list, with values coming from API if present
    const defPublicDocuments = defaultPublicDocuments.map((document) => {
      const docs = detailAPI?.documents?.publicDocuments?.find((doc) => {
        return doc.document_type === document.document_type;
      });
      if (docs) {
        return docs;
      }
      return document;
    });

    // get other documents that are not on the default list
    const otherDocuments = detailAPI?.documents?.publicDocuments?.filter(
      (document) => {
        return ![
          'PACKING_LIST',
          'COMMERCIAL_INVOICE',
          'QUOTE',
          'EXPORTER_OF_RECORD_(EOR)',
          'IMPORTER_OF_RECORD_(IOR)',
          'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'
        ].includes(document.document_type);
      }
    );
    // merge default documents and other documents
    return [...defPublicDocuments, ...otherDocuments];
  };
  const publicDocuments =
    detailAPI?.documents?.publicDocuments?.length > 0
      ? getPublicDocuments()
      : defaultPublicDocuments;
  return (
    <Container>
      <Row gutter={10}>
        <Col
        // span={15}
        // xl={{ span: 15 }}
        // xxl={{ span: 16 }}
        // style={{ padding: 0 }}
        >
          <StyledCard className="mb3">
            {detailAPI.quote_status ? (
              <QuoteDetailsTabs>
                <HeaderTitle />
                {!isQuoteRequestUnsuccessful && !isQuoteExpired && (
                  <>
                    <Tabs
                      className="mb3"
                      style={{ padding: '0px 40px 40px 40px' }}
                      activeKey={
                        tabActive ||
                        getActiveTabByStatus(detailAPI.quote_status)
                      }
                      onTabClick={(key) => setState({ tabActive: key })}
                    >
                      <TabPane tab="Details" key={STATUS_QUOTE_REQUESTED}>
                        <ShipmentDetails />
                      </TabPane>
                      {isQuoteAccepted && (
                        <TabPane tab="Documents" key={STATUS_QUOTE_ACCEPTED}>
                          <ShipmentDocuments
                            setDataFile={setDataFile}
                            dataFile={dataFile}
                            shipmentDetails={shipmentDetails}
                            handleSubmitButton={handleSubmitButton}
                            loading={loading}
                            publicDocuments={publicDocuments}
                            documentsMap={documentsMap}
                            selectedDocType={selectedDocType}
                            handleReplaceUpload={handleReplaceUpload}
                            detailAPI={detailAPI}
                            uploadDialog={uploadDialog}
                            setuploadDialog={setuploadDialog}
                          />
                        </TabPane>
                      )}
                      {(isDocumentUploaded ||
                        isShipmentConfirmed ||
                        isShipmentCancelled) && (
                        <TabPane
                          tab="Documents"
                          key={STATUS_QUOTE_DOCUMENT_UPLOADED}
                        >
                          {publicDocuments?.map((doc) => {
                            return (
                              <>
                                <div className="threeColsGrid upload-row-item">
                                  <h6>
                                    {documentsMap[doc.document_type] ||
                                      (doc.document_type &&
                                        toTitleCase(
                                          doc.document_type?.replace(
                                            new RegExp('_', 'g'),
                                            ' '
                                          )
                                        ))}
                                    {doc.document_type ===
                                      'IMPORTER_OF_RECORD_(IOR)' && (
                                      <StyledPopover
                                        title={
                                          <h4>IOR (Importer Of Record)</h4>
                                        }
                                        content={
                                          <p>
                                            Is a person or a company responsible
                                            for arranging everything legal
                                            related in an import operation on
                                            the country of importing.
                                          </p>
                                        }
                                      >
                                        <Icon
                                          className="question-circle"
                                          type="question-circle"
                                        />
                                      </StyledPopover>
                                    )}
                                    {doc.document_type ===
                                      'EXPORTER_OF_RECORD_(EOR)' && (
                                      <StyledPopover
                                        title={
                                          <h4>EOR (Exporter Of Record)</h4>
                                        }
                                        content={
                                          <p>
                                            An Exporter of Record is the legal
                                            exporting entity for goods exiting a
                                            country.
                                          </p>
                                        }
                                      >
                                        <Icon
                                          className="question-circle"
                                          type="question-circle"
                                        />
                                      </StyledPopover>
                                    )}
                                    {doc.document_type ===
                                      'MATERIAL_SAFETY_DATA_SHEETS_(MSDS)' && (
                                      <StyledPopover
                                        title={
                                          <h4>
                                            MSDS (Material Safety Data Sheet)
                                          </h4>
                                        }
                                        content={
                                          <ul>
                                            <li>
                                              We need to update MSDS(Material
                                              Safety Data Sheet) as shipment
                                              contains hazardous goods.
                                            </li>
                                            <li>
                                              Please check with the shipper for
                                              the MSDS.
                                            </li>
                                          </ul>
                                        }
                                      >
                                        <Icon
                                          className="question-circle"
                                          type="question-circle"
                                        />
                                      </StyledPopover>
                                    )}
                                  </h6>
                                  <a
                                    href={
                                      doc?.document_url ? doc.document_url : '#'
                                    }
                                  >
                                    <span
                                      aria-hidden
                                      // onClick={() => handleDownload(doc)}
                                      className="file-download"
                                    >
                                      {doc && doc.document_title && (
                                        <Icon type="file" />
                                      )}
                                      {doc && doc.document_title}
                                    </span>
                                  </a>
                                  {([
                                    STATUS_SHIPMENT_CREATED,
                                    STATUS_QUOTE_DOCUMENT_UPLOADED
                                  ].includes(detailAPI?.quote_status) ||
                                    doc.document_type === 'QUOTE') &&
                                  !doc?.document_is_document_approved ? (
                                    <Upload
                                      className="wrapper-upload"
                                      accept=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .csv"
                                      showUploadList={false}
                                      disabled={
                                        doc.document_type === selectedDocType &&
                                        loading
                                      }
                                      beforeUpload={(file) => {
                                        const request = {};
                                        request[doc.document_type] = file;
                                        handleReplaceUpload(
                                          request,
                                          doc.document_type
                                        );
                                        return false;
                                      }}
                                    >
                                      <Button
                                        loading={
                                          doc.document_type ===
                                            selectedDocType && loading
                                        }
                                        type="secondary"
                                        size="large"
                                        className="btn-change"
                                        disabled={detailAPI.is_cost_locked}
                                      >
                                        {doc &&
                                          (doc.document_title
                                            ? 'Replace'
                                            : 'Upload')}
                                      </Button>
                                    </Upload>
                                  ) : (
                                    <div className="wrapper-upload">
                                      <Button
                                        size="large"
                                        style={{ width: 115 }}
                                        type="secondary"
                                        onClick={() => {
                                          message.error(
                                            TEXT_DOCUMENT_ALREADY_VERIFIED
                                          );
                                        }}
                                        className="btn-change"
                                        disabled={detailAPI.is_cost_locked}
                                      >
                                        {doc &&
                                          (doc.document_title
                                            ? 'Replace'
                                            : 'Upload')}
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          })}
                          {[
                            STATUS_SHIPMENT_CREATED,
                            STATUS_QUOTE_DOCUMENT_UPLOADED
                          ].includes(detailAPI?.quote_status) && (
                            <>
                              {uploadDialog && (
                                <StyledShipmentDocuments>
                                  <div
                                    className="shipment-documents-body"
                                    style={{
                                      padding: 0,
                                      borderBottom: '1px solid #050593',
                                      marginBottom: 15
                                    }}
                                  >
                                    <UploadDocumentsDialog
                                      closeButton={setuploadDialog}
                                      uploading={
                                        selectedDocType === 'newDoc' && loading
                                      }
                                      replace
                                      addCustomFile={(request) => {
                                        handleReplaceUpload(
                                          request,
                                          'newDoc',
                                          true
                                        );
                                      }}
                                    />
                                  </div>
                                </StyledShipmentDocuments>
                              )}
                              <div className="upload-row-item">
                                <Button
                                  style={{
                                    width: '225px',
                                    marginTop: '30px'
                                  }}
                                  size="large"
                                  type="secondary"
                                  onClick={() => {
                                    setuploadDialog(true);
                                  }}
                                >
                                  Upload New Documents
                                </Button>
                              </div>
                            </>
                          )}
                        </TabPane>
                      )}
                      {isShipmentConfirmed && (
                        <TabPane
                          tab="Status"
                          key={STATUS_QUOTE_SHIPMENT_CONFIRMED}
                        >
                          <div className="clearfix">
                            <Modal
                              title="Are you sure you want to cancel your shipment?"
                              visible={statusModal.cancelShipment}
                              closable={false}
                              footer={[
                                <Button
                                  key="back"
                                  type="secondary"
                                  onClick={() => {
                                    setState({
                                      statusModal: { cancelShipment: false }
                                    });
                                  }}
                                >
                                  No
                                </Button>,
                                <Button
                                  key="submit"
                                  onClick={() => {
                                    dispatch(
                                      updateQuoteStatus({
                                        id: match.params.quoteId,
                                        status: STATUS_CANCELLED
                                      })
                                    );
                                    setState({
                                      statusModal: { cancelShipment: false }
                                    });
                                  }}
                                >
                                  Yes
                                </Button>
                              ]}
                            >
                              <p>
                                Your shipment will not be transported to the
                                destination.
                              </p>
                            </Modal>
                            <TrackingUpdatesModal
                              isVisble={statusModal.subscribe}
                              setIsVisible={(val) =>
                                setState({ statusModal: { subscribe: val } })
                              }
                              handleEmailInputChange={(val) =>
                                setState({ subEmail: val })
                              }
                              emailInput={state.subEmail}
                              handleSubscribe={handleSubscribe}
                              shipmentId={match.params.quoteId}
                            />
                            <Row gutter={40} className="wrapper-shipment">
                              <Col
                                span={13}
                                xl={{ span: 13 }}
                                xxl={{ span: 9 }}
                              >
                                <ShipmentStatus
                                  onCancel={() => {
                                    setState({
                                      statusModal: { cancelShipment: true }
                                    });
                                  }}
                                />
                              </Col>
                              <Col span={8} xl={{ span: 8 }} xxl={{ span: 13 }}>
                                <span className="caption">Tracking Number</span>
                                <p
                                  className="heavy"
                                  style={{ marginBottom: '25px' }}
                                >
                                  {match.params && match.params.quoteId}
                                </p>
                                {!isDelivered && !isBilled && (
                                  <>
                                    <span className="caption">
                                      Estimated Delivery Date
                                    </span>

                                    <h6 style={{ marginBottom: '22px' }}>
                                      {moment(
                                        detailAPI.destination.pickup_date
                                      ).format('DD MMM YYYY')}
                                    </h6>
                                  </>
                                )}
                                {!isDelivered && !isBilled && (
                                  <TrackingUpdatesButton
                                    type="secondary"
                                    onClick={() =>
                                      setState({
                                        statusModal: { subscribe: true }
                                      })
                                    }
                                  >
                                    Subscribe to Tracking Updates
                                  </TrackingUpdatesButton>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                      )}
                      {isShipmentBilled && (
                        <TabPane tab="Billing" key={STATUS_BILLED}>
                          <BillingDetails />
                        </TabPane>
                      )}
                    </Tabs>
                  </>
                )}
              </QuoteDetailsTabs>
            ) : (
              <div style={{ padding: 40 }}>
                <Skeleton title active paragraph={{ rows: 6 }} />
              </div>
            )}
          </StyledCard>
          {shippingLog.length === 0 ? (
            <StyledCard
              className="mt3"
              style={{ marginBottom: '40px', padding: 40 }}
            >
              <Skeleton title active paragraph={{ rows: 5 }} />
            </StyledCard>
          ) : (
            <ShipmentHistory heading="Shipment" />
          )}
        </Col>

        {/* <Col span={8} xl={{ span: 8 }} xxl={{ span: 7 }} style={{ padding: 0 }}>
          <Chatbox />
        </Col> */}
      </Row>
    </Container>
  );
};

export default withRouter(ShipmentDetail);
