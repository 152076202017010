import axios from 'axios';
import request, { axiosRequest } from '../utils/request';
import { queryString, removeNonEnglishCharacters } from '../utils/formatString';

const changeQuoteStatus = (id, payload) => {
  return new Promise((resolve) => {
    const url = `/quote/${id}/action`;
    request()
      .post(url, payload)
      .then((resp) => {
        resolve(resp);
      });
  });
};

const changeQuoteRequestStatus = (id, payload) => {
  return new Promise((resolve) => {
    const url = `/quote/${id}/update-status`;
    request()
      .post(url, payload)
      .then((resp) => {
        resolve(resp);
      });
  });
};

const getQuoteMessage = (id) => {
  const url = `/quote/${id}/messages`;
  return axiosRequest.get(url);
};

const getQuoteDetails = (id) => {
  const url = `/shipments/${id}`;
  return axiosRequest.get(url);
};
const getShipmentSubscribedEmails = (id) => {
  const url = `shipments/email-subscriptions/${id}`;
  return axiosRequest.get(url);
};
const unsubscribeShipmentEmail = (id, email) => {
  const url = `shipments/email-unsubscribe/${id}`;
  return axiosRequest.post(url, { email });
};
const updateQuoteDetail = (id, quote) => {
  const url = `/shipments/${id}`;
  return axiosRequest.patch(url, quote);
};
const getRequestQuotesDetail = async (id) => {
  const url = `/quote/quote-requests/${id}`;
  const response = await request().get(url);
  return response.data.data;
};

const getQuoteRequests = async ({ page = 1, pageSize = 10 }) => {
  const params = { page, records: pageSize };
  const queryParams = queryString(params);
  const url = `/quote/quote-requests/merchant?${queryParams}`;
  const response = await request().get(url);
  const { quotes, total } = response.data.data;
  return {
    quotes: quotes.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

export const createQuoteRequest = async (quoteRequest, dataFile) => {
  // save quote details and get tracking id
  const resp = await request().post(`quote/create-quote-request`, quoteRequest);
  const tracking_id = resp?.data?.data.tracking_id;
  // upload documents
  const signedRequest = { files: [] };
  const formData = {};
  const uploadDocRequest = {
    tracking_id,
    files: []
  };
  const {
    packingListFile,
    commercialInvoiceFile,
    quotationForm,
    MSDSFile
  } = dataFile;
  if (packingListFile) {
    formData.PACKING_LIST = packingListFile;
    signedRequest.files.push('PACKING_LIST');
  }

  if (commercialInvoiceFile) {
    formData.COMMERCIAL_INVOICE = commercialInvoiceFile;
    signedRequest.files.push('COMMERCIAL_INVOICE');
  }

  if (quotationForm) {
    formData.QUOTATION_FORM = quotationForm;
    signedRequest.files.push('QUOTATION_FORM');
  }

  if (MSDSFile) {
    formData['MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'] = MSDSFile;
    signedRequest.files.push('MATERIAL_SAFETY_DATA_SHEETS_(MSDS)');
  }

  if (packingListFile || commercialInvoiceFile || MSDSFile) {
    const signedUrls = await request().post(
      `/quote/${tracking_id}/sign-documents`,
      signedRequest
    );

    // eslint-disable-next-line no-restricted-syntax
    for (const file of signedRequest.files) {
      const url = signedUrls.data.data[file];
      const options = {
        headers: {
          'Content-Type': formData[file].type
        }
      };
      // eslint-disable-next-line no-await-in-loop
      await axios.put(url.pre_signed_url, formData[file], options);
      uploadDocRequest.files.push({
        field_name: file,
        key: url.key,
        content_type: formData[file].type,
        file_name: removeNonEnglishCharacters(formData[file].name)
      });
    }
  }
  // save document on server and send email
  await request().post(
    `quote/${tracking_id}/quote-document-upload`,
    uploadDocRequest
  );
  return { tracking_id };
};

export const quoteService = {
  createQuoteRequest,
  changeQuoteStatus,
  changeQuoteRequestStatus,
  getQuoteMessage,
  getQuoteDetails,
  updateQuoteDetail,
  getShipmentSubscribedEmails,
  unsubscribeShipmentEmail,
  getQuoteRequests,
  getRequestQuotesDetail
};
