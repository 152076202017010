import React, { forwardRef } from 'react';
import { Select as AntSelect } from 'antd';
import styled from '@emotion/styled';

const StyledSelect = styled(AntSelect)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  height: 35px;
  color: #000;
  width: 100%;
  display: block;
  .ant-select-selection__rendered {
    font-size: 16px;
    line-height: 29px;
  }
  .ant-select-selection--multiple > ul > li,
  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    margin-left: 5px;
  }
  .ant-select-selection-selected-value {
    font-size: 16px;
    padding-top: 3px;
    padding-bottom: 0;
  }
  .ant-select-search__field {
    padding-top: 7px;
  }
  .ant-select-selection__placeholder,
  .ant-select-search__field__placeholder {
    margin-top: -7px;
  }
  .ant-select-selection:hover {
    border-color: #050593;
  }
  &.ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
  .ant-select-selection {
    height: 35px;
    border: 1px solid #ccc;
  }
  .ant-select-selection:outline {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }

  &.ant-select-lg {
    height: 40px;
    .ant-select-selection {
      height: 40px;
    }
    .ant-select-selection__rendered {
      line-height: 35px;
    }
  }
  .ant-select-selection--multiple.ant-select-search ant-select-search--inline {
    margin-left: 5px;
  }
`;

const StyledDropdownMenu = styled.div`
  .ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected {
    font-family: AvenirLTStdHeavy;
    font-weight: normal;
  }
  .ant-select-dropdown-menu-item {
    font-family: AvenirLTStdBook;
    color: #000;
    font-size: 16px;
    white-space: pre-wrap;
  }
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #f2f2f9;
  }

  .ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected
    .ant-select-selected-icon,
  .ant-select-dropdown.ant-select-dropdown--multiple
    .ant-select-dropdown-menu-item-selected:hover
    .ant-select-selected-icon {
    color: #050593;
  }
`;

const Select = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <StyledSelect
      ref={ref}
      dropdownRender={(menu) => <StyledDropdownMenu>{menu}</StyledDropdownMenu>}
      {...rest}
    >
      {children}
    </StyledSelect>
  );
};

const forwardSelect = forwardRef(Select);

forwardSelect.Option = AntSelect.Option;
forwardSelect.OptGroup = AntSelect.OptGroup;

export default forwardSelect;
