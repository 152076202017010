import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { logout } from '../auth0';
import DropdownMenu from './DropdownMenu';

const StyledDropDownMenu = styled(DropdownMenu)`
  li,
  li:hover {
    cursor: unset;
  }

  .link,
  .link:hover {
    cursor: pointer;
  }
`;

const UserProfile = ({ user }) => {
  return (
    <StyledDropDownMenu>
      <li className="name bold">{user?.name}</li>
      <li className="email spacer-25">{user?.email}</li>
      {/* <li><Link to="/">Account</Link></li> */}
      <li className="link">
        <Link to="/addresses">Manage Address</Link>
      </li>
      <li className="link" aria-hidden onClick={(e) => logout(e)}>
        <span>Logout</span>
      </li>
    </StyledDropDownMenu>
  );
};

export default UserProfile;
