import styled from '@emotion/styled';

const DropDownMenu = styled.ul`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px 0;
  white-space: nowrap;
  list-style: none;
  li {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    line-height: 25px;
    padding: 0 15px;
    transition: 0.5s;
    &:hover {
      cursor: pointer;
    }
    &.bold {
      font-family: AvenirLTStdHeavy;
      color: #000;
    }
    &.link a,
    &.link {
      font-family: AvenirLTStdBook;
      color: #050593;
    }
    &.spacer-25 {
      margin-bottom: 25px;
    }
  }
`;

export default DropDownMenu;
