import React from 'react';
import { Field } from 'formik';
import FullLoad from '../FullLoad/FullLoad';
import FullLoadEdit from '../FullLoadEdit/FullLoad';

const FormFullLoad = ({
  isEditForm = false,
  name,
  validate,
  onChange = () => {},
  onBlur = () => {},
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <>
          {!isEditForm && (
            <FullLoad
              loads={value}
              onChange={(values, isError) => {
                setFieldValue(name, values, false);
                setFieldValue(`internal.${name}`, isError);
                onChange(values);
              }}
              onBlur={(values, isError) => {
                setFieldTouched(name, true, false);
                setFieldValue(`internal.${name}`, isError);
                onBlur(values);
              }}
              {...restProps}
            />
          )}
          {isEditForm && (
            <FullLoadEdit
              loads={value}
              onChange={(values, isError) => {
                setFieldValue(name, values, false);
                setFieldValue(`internal.${name}`, isError);
                onChange(values);
              }}
              onBlur={(values, isError) => {
                setFieldTouched(name, true, false);
                setFieldValue(`internal.${name}`, isError);
                onBlur(values);
              }}
              {...restProps}
            />
          )}
        </>
      )}
    </Field>
  );
};

export default FormFullLoad;
