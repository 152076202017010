import React, { useState, useRef, useEffect } from 'react';
import Select from './Select';
import { TEXT_NO_RESULT, TEXT_LOADING } from '../constants/message';

const { Option } = Select;

const SearchInput = ({
  items = [],
  placeholder,
  value: outValue,
  allowClear = false,
  freeText = false,
  showSearch = true,
  onChange = () => {},
  onFocus = () => {},
  onBlur = () => {},
  loading = false,
  setRef
}) => {
  const [value, setValue] = useState(() => {
    if (items.length > 0) {
      return outValue;
    }
    return undefined;
  });
  const [data, setData] = useState(items);
  const node = useRef();

  const handleSearch = (_value = '') => {
    if (value && _value) {
      setData(
        items.filter((item) =>
          item.label.toLowerCase().includes(_value.toLowerCase())
        )
      );
    } else {
      setData(items);
    }
  };

  useEffect(() => {
    // if (items.length > 0) {
    // }
    setValue(outValue);
    setData(items);
  }, [items, outValue]);
  return (
    <div ref={node}>
      <Select
        ref={setRef}
        freeText={freeText}
        allowClear={allowClear}
        showSearch={showSearch}
        placeholder={placeholder}
        defaultActiveFirstOption={false}
        loading={loading}
        value={value}
        optionFilterProp="children"
        onSearch={handleSearch}
        onChange={(_value, option) => onChange(_value, option)}
        onFocus={onFocus}
        onBlur={onBlur}
        notFoundContent={loading ? TEXT_LOADING : TEXT_NO_RESULT}
        getPopupContainer={() => node.current}
      >
        {data.map((item) => (
          <Option
            key={(item && item.value) || item}
            value={(item && item.value) || item}
          >
            {(item && item.label) || item}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default SearchInput;
