import React from 'react';
import styled from '@emotion/styled';
import { message } from 'antd';
import AddressInput from './AddressInput';
import SearchInput from './SearchInput';
import { addAddress } from '../services/address';
import { TEXT_ADD_ADDRESS_SUCCESS } from '../constants/message';

const StyledAddAddressInput = styled.div``;

const AddLink = styled.span`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
  color: #050593;
  text-decoration: underline;

  position: absolute;
  top: 3px;
  right: 0;

  &:hover {
    color: #050593;
  }
`;

const AddAddressInput = ({
  hideAdd = false,
  loading = false,
  items = [],
  onAdded = () => {},
  ...props
}) => {
  const handleAddAddress = (addr) => {
    const address = {
      streetAddress: addr.address,
      city: addr.city,
      country: addr.country,
      // Figure out what is this for
      countryCode: addr.country,
      locationName: addr.locationName,
      pocEmail: addr.pocEmail,
      pocName: addr.pocName,
      pocPhoneNumber: addr.pocPhoneNumber,
      postalCode: addr.postalCode,
      stateAdr: addr.state
    };
    return addAddress({ ...address, googleLocationId: null })
      .exec()
      .then((response) => {
        const { data } = response;
        message.success(TEXT_ADD_ADDRESS_SUCCESS);
        onAdded(data.data);
      });
  };

  return (
    <StyledAddAddressInput>
      <AddressInput onSubmit={handleAddAddress}>
        {({ showModal }) => (
          <>
            {!hideAdd && <AddLink onClick={showModal}>Add</AddLink>}
            <SearchInput items={items} loading={loading} {...props} />
          </>
        )}
      </AddressInput>
    </StyledAddAddressInput>
  );
};

export default AddAddressInput;
