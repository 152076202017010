import { Field } from 'formik';
import * as React from 'react';
import { Checkbox } from 'antd';
import Message from '../Message';

const FormCheckboxGroup = ({
  name,
  validate,
  children,
  onChange: handleChange = () => {},
  onBlur = () => {},
  validateDirty = true,
  showErrorMessage = false,
  ...restProps
}) => {
  const showError = (touched, error) => {
    if (validateDirty) {
      return <>{error && touched && <Message type="error">{error}</Message>}</>;
    }
    return <>{error && <Message type="error">{error}</Message>}</>;
  };
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta
      }) => (
        <>
          <Checkbox.Group
            value={value}
            onChange={(val) => {
              setFieldValue(name, val);
              handleChange(val);
            }}
            onBlur={(e) => {
              setFieldTouched(name, true);
              onBlur(e);
            }}
            {...restProps}
          >
            {children}
          </Checkbox.Group>
          {showErrorMessage && showError && showError(meta.touched, meta.error)}
        </>
      )}
    </Field>
  );
};

export default FormCheckboxGroup;
