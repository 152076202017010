import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Switch } from 'antd';
import { cx } from 'emotion';

const StyledUnitInput = styled.div`
  position: relative;
  .unit-list {
    font-family: AvenirLTStdBook;
    color: #000000;
    position: absolute;
    top: -28px;
    right: 0;
    padding: 0;
    display: flex;
    align-items: center;
    span {
      color: #bbbbbb;
      transition: all 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
    span.active {
      color: #000000;
    }
  }
  .unit-switch {
    margin: 1px 5px 0 5px;
    width: 25px;
    background-color: #6969be;
  }
`;

const UnitInput = ({
  units = ['XX', 'XY'],
  unit = 'XX',
  value,
  children,
  hide = false,
  onChange: handleChange = () => {}
}) => {
  const [selectedUnit, setSelectedUnit] = useState(unit);
  const [unitValue, setUnitValue] = useState(value);

  useEffect(() => {
    setSelectedUnit(unit);
  }, [unit]);

  useEffect(() => {
    setUnitValue(value);
  }, [value]);

  const changeUnit = (item) => {
    const unitChanged = units[item ? 1 : 0];
    setSelectedUnit(unitChanged);
    handleChange(unitChanged);
  };

  const isChecked = () => Boolean(units.findIndex((u) => u === selectedUnit));

  const onChange = (changeValue) => {
    setUnitValue(changeValue);
  };

  const onBlur = (changeValue) => {
    setUnitValue(changeValue);
  };

  return (
    <StyledUnitInput>
      {!hide && (
        <div className="unit-list">
          <span className={cx({ active: selectedUnit === units[0] })}>
            {units[0]}
          </span>
          <Switch
            size="small"
            onChange={changeUnit}
            checked={isChecked()}
            className="unit-switch"
          />
          <span className={cx({ active: selectedUnit === units[1] })}>
            {units[1]}
          </span>
        </div>
      )}
      {children({
        value: unitValue,
        onChange,
        onBlur,
        setFieldValue: setUnitValue
      })}
    </StyledUnitInput>
  );
};

export default UnitInput;
