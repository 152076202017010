import React, { useState, useEffect, useRef } from 'react';
import Talk from 'talkjs';
import { Skeleton, Collapse } from 'antd';
import styled from '@emotion/styled';
import { withRouter } from 'react-router-dom';
import Card from './Card';
import { getProfile } from '../auth0';
import UnreadIcon from '../assets/icons/unreadIcon.svg';

const { Panel } = Collapse;

const StyledPanel = styled(Panel)`
  .ant-collapse-content-box {
    padding: 0px;
  }
`;

const ChatDiv = styled.div`
  position: fixed;
  margin: 0px 0px 0px 30px;
  display: ${(props) => (props.talkJSReady ? 'block' : 'none')};
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-header {
    font-size: 18px;
    padding: 13px 16px !important;
    border-radius: 8px 8px 0 0 !important;
    font-family: AvenirLTStdHeavy;
    background-color: ${(props) => (!props.isOpsChat ? '#FBFAF6' : '#F2F2FA')};
  }
  .ant-collapse-content {
    border-top: ${(props) =>
      !props.isOpsChat ? '1px solid #D9C8A9' : '1px solid #8181C9'};
  }
  .ant-collapse-item {
    border-radius: 0px !important;
  }
  border: 0px;
  border-radius: 8px 8px 0 0 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
`;

const ChatboxContainer = styled.div`
  height: ${(props) =>
    !props.isExternalMerchant ? 'calc(100vh - 250px)' : 'calc(100vh - 190px)'};
  margin: 0 auto;
  background: #fff;
  display: ${(props) => (props.show ? 'block' : 'none')};

  .ant-spin-spinning {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 30%;
  }

  .chatbox-content {
    height: calc(100%);
    border-radius: 0 0 6px 6px;
    background-color: #ffffff;
  }
`;

const ChatLoader = styled(Card)`
  height: 330px;
  margin: 0 auto;
  padding: 30px 40px;
  position: fixed;
  transform: translateX(15px);
  overflow: hidden;
`;

// TODO: add chat context to trackingId
const Chatbox = (props) => {
  const chatBoxContainer = useRef(null);
  const extMerchantChatBoxContainer = useRef(null);
  const [talkJSReady, setTalkJSReady] = useState(false);
  const [notifierBadge, setNotifierBadge] = useState(0);
  const [extNotifierBadge, setExtNotifierBadge] = useState(0);
  const [user, setUser] = useState();
  const [activePanel, setActivePanel] = useState('2');
  const [isExternalMerchant, setisExternalMerchant] = useState(false);
  useEffect(() => {
    getProfile().then((profile) => {
      setUser(profile);
      const roles = profile['https://b2b.janio.asia/roles'] || [];
      if (roles.includes('merchant/user')) {
        setisExternalMerchant(true);
        setActivePanel('1');
      }
    });
  }, []);
  const { match = {} } = props;
  const { quoteId = {} } = match.params;
  useEffect(() => {
    if (user) {
      Talk.ready.then(() => {
        const me = new Talk.User({
          id: user.sub,
          name: user.name,
          email: user.email,
          role: 'merchant'
        });

        if (window.talkSession1) {
          window.talkSession1.destroy();
        }

        window.talkSession1 = new Talk.Session({
          appId: process.env.REACT_APP_TALKJS_APP_ID,
          me
        });
        const conversationId1 = quoteId;
        const conversation1 = window.talkSession1.getOrCreateConversation(
          conversationId1
        );
        conversation1.setAttributes({ custom: { trackingId: quoteId } });
        conversation1.setParticipant(me);
        const chatBox1 = window.talkSession1.createChatbox(conversation1, {
          showChatHeader: false
        });

        window.talkSession1.unreads.on('change', function(unreadConversations) {
          const unreadMessages = unreadConversations.filter(
            (obj) => obj.lastMessage.conversation.id === quoteId
          );
          const amountOfUnreads = unreadMessages.length;
          setNotifierBadge(amountOfUnreads);
        });

        if (window.talkSession2) {
          window.talkSession2.destroy();
        }

        window.talkSession2 = new Talk.Session({
          appId: process.env.REACT_APP_TALKJS_APP_ID,
          me
        });
        const conversationId2 = `client_${quoteId}`;
        const conversation2 = window.talkSession2.getOrCreateConversation(
          conversationId2
        );
        conversation2.setAttributes({ custom: { trackingId: quoteId } });
        conversation2.setParticipant(me);
        const chatBox2 = window.talkSession2.createChatbox(conversation2, {
          showChatHeader: false
        });

        window.talkSession2.unreads.on('change', function(unreadConversations) {
          const unreadMessages = unreadConversations.filter(
            (obj) => obj.lastMessage.conversation.id === `client_${quoteId}`
          );
          const amountOfUnreads = unreadMessages.length;
          setExtNotifierBadge(amountOfUnreads);
        });

        if (activePanel === '2') {
          chatBox1
            .mount(chatBoxContainer.current)
            .then(() => setTalkJSReady(true));
          window.chatBox = chatBox1;
        } else {
          chatBox2
            .mount(extMerchantChatBoxContainer.current)
            .then(() => setTalkJSReady(true));
          window.chatBox = chatBox2;
        }
      });
    }
  }, [quoteId, user, activePanel]);

  const ExternalHeader = () => {
    return (
      <div>
        <span>
          {isExternalMerchant ? 'Shipment Chat' : 'To External Merchant'}
        </span>
        {extNotifierBadge ? (
          <img
            style={{ marginLeft: 5, height: 22, width: 22 }}
            alt="I"
            src={UnreadIcon}
          />
        ) : null}
      </div>
    );
  };

  const InternalHeader = () => {
    return (
      <div>
        <span>To Operator/Finance/Internal Merchant</span>
        {notifierBadge ? (
          <img
            style={{ marginLeft: 5, height: 22, width: 22 }}
            alt="I"
            src={UnreadIcon}
          />
        ) : null}
      </div>
    );
  };

  return (
    <>
      {!talkJSReady && (
        <ChatLoader
          className="ant-col ant-col-8 ant-col-xl-8 ant-col-xxl-7"
          style={{ margin: '0px 0px 0px 15px' }}
        >
          <Skeleton title active paragraph={{ rows: 7 }} />
        </ChatLoader>
      )}

      <ChatDiv
        className="ant-col ant-col-8 ant-col-xl-8 ant-col-xxl-7"
        talkJSReady={talkJSReady}
      >
        <StyledCollapse
          accordion
          isOpsChat={false}
          defaultActiveKey={[activePanel]}
          activeKey={[activePanel]}
          onChange={(data) => {
            if (!isExternalMerchant) {
              if (data) {
                setActivePanel('1');
              } else {
                setActivePanel('2');
              }
            }
          }}
          expandIconPosition="right"
        >
          <StyledPanel
            header={<ExternalHeader />}
            key="1"
            showArrow={!isExternalMerchant}
          >
            <ChatboxContainer
              isExternalMerchant={isExternalMerchant}
              show={talkJSReady}
            >
              <div
                className="chatbox-content"
                ref={extMerchantChatBoxContainer}
              >
                <i />
              </div>
            </ChatboxContainer>
          </StyledPanel>
        </StyledCollapse>
        <br />

        {!isExternalMerchant && (
          <>
            <StyledCollapse
              accordion
              isOpsChat
              defaultActiveKey={[activePanel]}
              activeKey={[activePanel]}
              onChange={(data) => {
                if (data) {
                  setActivePanel('2');
                } else {
                  setActivePanel('1');
                }
              }}
              expandIconPosition="right"
            >
              <StyledPanel header={<InternalHeader />} key="2">
                <ChatboxContainer
                  isExternalMerchant={isExternalMerchant}
                  show={talkJSReady}
                >
                  <div className="chatbox-content" ref={chatBoxContainer}>
                    <i />
                  </div>
                </ChatboxContainer>
              </StyledPanel>
            </StyledCollapse>
          </>
        )}
      </ChatDiv>
    </>
  );
};

export default withRouter(Chatbox);
