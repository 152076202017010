import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { message } from 'antd';
import moment from 'moment';
import * as Yup from 'yup';
import Button from './Button';
import ShipmentDetailsForm, {
  ShipmentDetailsFormRow
} from './ShipmentDetailsForm';
import { quoteService } from '../services/quotes';
import { packageService } from '../services/packages';
import {
  TEXT_SHIPMENT_STATUS_SUCCESS,
  TEXT_SHIPMENT_STATUS_ERROR
} from '../constants/message';
import { initValidators } from '../helpers/validation';
import { invalidCharacter } from '../utils/formatString';
import { getFullDataRequestQuote } from '../actions/quoteDetail';

const getMetricType = (shipment, item) => {
  if (shipment.quote_shipment_dimension_type) {
    return [
      shipment.quote_shipment_dimension_type,
      shipment.quote_shipment_dimension_type
    ];
  }
  return [item.weight_type, item.volume_type];
};

const StyledShipmentDetails = styled.div`
  .shipment-body {
    .ant-calendar-picker {
      width: 100%;
    }
    line-height: 1;
  }
  .shipment-body .shipment-detail-row:last-child {
    border-bottom: 1px solid #050593;
  }
  .shipment-controls {
    display: flex;
    button {
      margin-top: 15px;
      padding: 0px 41px;
      margin-left: 10px;
    }
    button:first-of-type {
      margin-left: auto;
    }
  }
`;

const ShipmentDetailsEdit = ({ onCancel, onSave }) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [lookups, setLookups] = useState({
    incoterms: [],
    container_types: [],
    package_types: [],
    truck_types: []
  });
  const quote = useSelector((state) => state?.quoteDetail?.quote);
  const shipmentDetails = quote?.shipment_details;

  useEffect(() => {
    const fetchLookupValues = packageService.getPackageTypes();
    fetchLookupValues.exec().then((res) => {
      setLookups(res.data.data);
    });
    return () => fetchLookupValues.cancel();
  }, [setLookups]);

  const dangerousGoods = {
    contains_hazardous_goods: 'Hazardous Materials',
    contains_battery: 'Batteries',
    other: 'Other'
  };

  initValidators();

  const shipmentFormSchema = Yup.object().shape({
    origin: Yup.object().shape({
      pickupDate: Yup.string()
        .nullable()
        .required(
          `Please select ${
            quote?.origin?.is_using_first_mile ? 'Pickup Date' : 'Dropoff Date'
          }`
        ),
      address: Yup.string()
        .nullable()
        .test(
          'address',
          `Please enter ${
            quote?.origin?.is_using_first_mile ? 'Address' : 'Dropoff Address'
          }`,
          (value) => {
            if (quote?.origin?.is_using_first_mile !== 2) {
              return value !== undefined;
            }
            return true;
          }
        )
    }),
    destination: Yup.object().shape({
      pickupDate: Yup.string()
        .nullable()
        .required(`Please select Estimated Delivery Date`),
      address: Yup.string()
        .nullable()
        .required(
          `Please enter ${
            quote?.destination?.is_using_first_mile
              ? 'Address'
              : 'Pickup Address'
          }`
        )
    }),
    shippingPrice: Yup.object().shape({
      agreedPrice: Yup.mixed().test(
        'hasAgreedPrice',
        'Please enter Agreed Price',
        function(value) {
          const { parent } = this;
          if (parent.hasAgreedPrice === 'Yes') {
            return value;
          }
          return true;
        }
      )
    }),
    shipperName: Yup.mixed()
      .nullable()
      .required(`Please enter Shipper's Name`),
    consigneeName: Yup.mixed()
      .nullable()
      .required(`Please enter Consignee's Name`),
    internal: Yup.object().shape({
      shipmentLoad: Yup.mixed().test('internal', 'error', (value) => {
        return !value;
      }),
      shipmentPackages: Yup.mixed().test('internal', 'error', (value) => {
        return !value;
      })
    }),
    yourShipment: Yup.object().shape({
      shipmentName: Yup.string()
        .nullable()
        .test(
          'max',
          'You have exceeded the maximum 200 characters length limit',
          (value) => {
            return (value?.length || 0) <= 200;
          }
        ),
      quoteID: Yup.string()
        .nullable()
        .test(
          'max',
          'You have exceeded the maximum 10 characters length limit',
          (value) => {
            return (value?.length || 0) <= 10;
          }
        )
    }),
    otherDetails: Yup.object().shape({
      description: Yup.string()
        .nullable()
        .test(
          'max',
          'You have exceeded the maximum 2000 characters length limit',
          (value) => {
            return (value?.length || 0) <= 2000;
          }
        )
        .test('description', 'Please enter valid characters', (value) => {
          return !invalidCharacter(value);
        })
    }),
    total: Yup.object().shape({
      reconciledWeight: Yup.mixed()
        .nullable()
        .validNumber()
        .validDecimalPlaces()
        .min('kg', 0.1)
        .max('kg', 1000000000)
    })
  });

  const intialValues = {
    origin: {
      pickupDate: moment(quote?.origin?.pickup_date),
      address: [
        quote?.origin?.address,
        quote?.origin?.city,
        quote?.origin?.state,
        quote?.origin?.postal_code
      ]
        .filter(Boolean)
        .join(', '),
      pocName: quote?.origin?.poc_name,
      port: quote?.origin?.port,
      pocNumber: quote?.origin?.poc_number,
      pocEmail: quote?.origin?.poc_email
    },
    destination: {
      pickupDate: moment(quote?.destination?.pickup_date),
      address: [
        quote?.destination?.address,
        quote?.destination?.city,
        quote?.destination?.state,
        quote?.destination?.postal_code
      ]
        .filter(Boolean)
        .join(', '),
      pocName: quote?.destination?.poc_name,
      port: quote?.destination?.port,
      pocNumber: quote?.destination?.poc_number,
      pocEmail: quote?.destination?.poc_email
    },
    shipperName: quote?.shipper_name,
    consigneeName: quote?.consignee_name,
    shipmentLoad: quote?.shipment_load_type ? 'Full' : 'Less',
    midMile: quote?.is_using_mid_mile ? 'Yes' : 'No',
    shippingInfo: {
      incotermId: quote?.incoterm_id
    },
    shippingPrice: {
      hasAgreedPrice: quote?.agreed_price ? 'Yes' : 'No',
      agreedPrice: quote?.shipment_price
    },
    shipmentPackages: quote?.packages?.map((packageItem) => {
      const [weightType, volumeType] = getMetricType(quote, packageItem);
      return {
        pId: packageItem.id,
        amount: packageItem.units || 1,
        type: packageItem.type_id,
        dimensions: {
          height: packageItem.height,
          isCm: packageItem.length_uom === 'cm',
          length: packageItem.length,
          width: packageItem.width,
          amount: packageItem.total_volume
        },
        weight: {
          amount: weightType ? packageItem.weight : packageItem.total_weight,
          isKg: packageItem.weight_uom === 'kg'
        },
        weightType,
        volumeType,
        chargeableWeight: packageItem.chargeable_weight
      };
    }),
    otherDetails: {
      dangerousGoods: Object.keys(dangerousGoods).filter(
        (goods) => shipmentDetails[goods]
      ),
      description: shipmentDetails?.description
    },
    yourShipment: {
      shipmentName: quote?.shipment_name,
      quoteID: quote?.quote_request_id
    },
    customBrokerageServices: {
      customsClearance: quote?.is_using_custom_clearance ? 'Yes' : 'No',
      exporterOfRecord: quote?.is_eor_present ? 'Yes' : 'No',
      importerOfRecord: quote?.is_ior_present ? 'Yes' : 'No'
    }
  };

  // TODO: create a separate ShipmentDetailsForm
  return (
    <StyledShipmentDetails id="shipment-details">
      <ShipmentDetailsForm
        initialValues={intialValues}
        lookups={lookups}
        validationSchema={shipmentFormSchema}
        onSubmit={(values) => {
          const quoteDetail = {
            edit_by_merchant: true,
            shipping_incoterm_id: values?.shippingInfo?.incotermId,
            origin: {
              estimated_pickup_dt: values?.origin?.pickupDate,
              port: values?.origin?.port ? values?.origin?.port : ''
            },
            destination: {
              estimated_delivery_dt: values?.destination?.pickupDate,
              port: values?.destination?.port ? values?.destination?.port : ''
            },
            shipper_name: values?.shipperName,
            consignee_name: values?.consigneeName,
            shipment_name: values?.yourShipment?.shipmentName,
            quote_request_id: values?.yourShipment?.quoteID,
            shipment_details: {
              description: values?.otherDetails?.description?.trim(),
              quote_cargo_reconciled_weight: Math.ceil(
                parseFloat(values?.total?.reconciledWeight || 0)
              )
            },
            shipment_load: values?.shipmentLoad?.fullLessLoad === 'Full',
            shipment_loads: values?.shipmentLoad?.loadItems
              ? values?.shipmentLoad?.loadItems?.map((loadItem) => {
                  return {
                    pId: loadItem.pId,
                    type_id:
                      values?.shipmentLoad?.fullLessLoad === 'Full'
                        ? loadItem.type
                        : null,
                    units: loadItem.amount,
                    containers_seals: loadItem.seals
                  };
                })
              : [],
            shipments_packages: {
              packages: values?.shipmentPackages.map((packageItem) => {
                return {
                  type_id: packageItem.type,
                  units: packageItem.amount,
                  weight_per_unit: packageItem.weightType
                    ? packageItem?.weight?.amount
                    : null,
                  weight_uom: packageItem?.weight?.isKg ? 'kg' : 'lb',
                  dimensions: {
                    length: packageItem?.dimensions?.length || null,
                    width: packageItem?.dimensions?.width || null,
                    height: packageItem?.dimensions?.height || null,
                    length_uom: packageItem?.dimensions?.isCm ? 'cm' : 'in'
                  },
                  cbm: packageItem.volumeType
                    ? null
                    : packageItem?.dimensions?.amount,
                  total_weight: packageItem.weightType
                    ? null
                    : packageItem?.weight?.amount,
                  weight_type: packageItem.weightType,
                  volume_type: packageItem.volumeType,
                  chargeable_weight: packageItem.chargeableWeight
                };
              })
            },
            is_using_mid_mile: values?.midMile === 'Yes',
            is_using_custom_clearance:
              values?.customBrokerageServices?.customsClearance === 'Yes',
            is_eor_present:
              values?.customBrokerageServices?.exporterOfRecord === 'Yes',
            is_ior_present:
              values?.customBrokerageServices?.importerOfRecord === 'Yes',
            has_agreed_price: values?.shippingPrice?.hasAgreedPrice === 'Yes',
            shipment_price: values?.shippingPrice?.agreedPrice,
            transhipment_mawb: values?.shippingInfo?.transhipmentMawb,
            import_awb: values?.shippingInfo?.importAwb,
            export_awb: values?.shippingInfo?.exportAwb
          };

          if (
            values?.otherDetails?.dangerousGoods &&
            values?.otherDetails?.dangerousGoods?.length > 0
          ) {
            values.otherDetails.dangerousGoods.forEach((key) => {
              quoteDetail.shipment_details = {
                ...quoteDetail.shipment_details,
                [key]: 1
              };
            });
          } else {
            quoteDetail.shipment_details.contains_no_special_goods = 1;
          }
          // console.log(quoteDetail);

          setSaving(true);
          quoteService
            .updateQuoteDetail(quote?.quote_id, quoteDetail)
            .exec()
            .then(async () => {
              dispatch(getFullDataRequestQuote(quote?.quote_id));
              setSaving(false);
              message.success(TEXT_SHIPMENT_STATUS_SUCCESS);
              onSave();
            })
            .catch(() => {
              setSaving(false);
              message.error(TEXT_SHIPMENT_STATUS_ERROR);
            });
        }}
      >
        {({ isValid, handleSubmit, loadType, schema }) => (
          <>
            <div className="shipment-body">
              <ShipmentDetailsFormRow
                schema={schema.merchant}
                width="50%"
                grow="0"
              />
              <ShipmentDetailsFormRow
                schema={schema.yourShipment}
                width="50%"
                grow="0"
              />
              <ShipmentDetailsFormRow
                schema={schema.shipmentMode}
                width="50%"
                grow="0"
              />
              <ShipmentDetailsFormRow grow="0" schema={schema.origin} />
              <ShipmentDetailsFormRow
                width="50%"
                grow="0"
                schema={schema.midMile}
              />
              <ShipmentDetailsFormRow grow="0" schema={schema.destination} />
              <ShipmentDetailsFormRow
                width="50%"
                grow="0"
                schema={schema.information}
              />
              <ShipmentDetailsFormRow
                width="50%"
                grow="0"
                schema={schema.agreedPrice}
              />
              {loadType && quote?.shipment_load_type !== null && (
                <ShipmentDetailsFormRow schema={schema.load} />
              )}
              <ShipmentDetailsFormRow schema={schema.packages} />
              <ShipmentDetailsFormRow
                // style={{ width: '75%' }}
                grow="0"
                schema={schema.otherDetails}
              />
              <ShipmentDetailsFormRow
                style={{ width: '75%' }}
                grow="0"
                schema={schema.customBrokerageServices}
              />
            </div>
            <div className="shipment-controls">
              <Button onClick={onCancel} type="secondary">
                Cancel
              </Button>
              <Button
                disabled={!isValid}
                loading={saving}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </>
        )}
      </ShipmentDetailsForm>
    </StyledShipmentDetails>
  );
};

export default ShipmentDetailsEdit;
