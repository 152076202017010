/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, message } from 'antd';
import styled from '@emotion/styled';
import { Table, Card, Button, Modal } from '../components';
import AddressInput from '../components/AddressInput';
import { getAddresses, setPageFilter } from '../actions/addressList';
import { addAddress, editAddress, deleteAddress } from '../services/address';
import {
  TEXT_ADD_ADDRESS_SUCCESS,
  TEXT_UPDATE_ADDRESS_SUCCESS
} from '../constants/message';
import editIcon from '../assets/images/edit-icon.png';
import deleteIcon from '../assets/images/delete-icon.png';

const { confirm: modalConfirm } = Modal;

const DEFAULT_PAGE_SIZE = 20;

const StyledCard = styled(Card)`
  margin: 0 40px 40px 40px;
  padding: 30px 40px;
`;

const DashedContainer = styled.div`
  border: 0;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23ccc' stroke-width='2' stroke-dasharray='5%2c 5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  text-align: center;
  padding: 70px 0;
`;

const StyledNoContent = styled.p`
  font-size: 24px;
  font-family: AvenirLTStdBook;
  margin-bottom: 20px;
`;

const OperationButton = styled(Button)`
  img {
    width: 16px;
    cursor: pointer;
  }
`;

const Addresses = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.addressList.filters.page);
  const pageSize = 10;

  const { data, total: totalCount, loading } = useSelector(
    (state) => state.addressList.addresses
  );

  useEffect(() => {
    const recordFilters = { page: currentPage, pageSize };
    dispatch(getAddresses(recordFilters));
  }, [currentPage, dispatch]);

  const handleAddAddress = (addr) => {
    const address = {
      streetAddress: addr.address,
      city: addr.city,
      country: addr.country,
      // Figure out what is this for
      countryCode: addr.country,
      locationName: addr.locationName,
      pocEmail: addr.pocEmail,
      pocName: addr.pocName,
      pocPhoneNumber: addr.pocPhoneNumber,
      postalCode: addr.postalCode,
      stateAdr: addr.state
    };
    if (addr.locationId) {
      address.locationId = addr.locationId;
      return editAddress({ ...address, googleLocationId: null })
        .exec()
        .then(() => {
          message.success(TEXT_UPDATE_ADDRESS_SUCCESS);
          const recordFilters = { page: currentPage, pageSize };
          dispatch(getAddresses(recordFilters));
        });
    }
    return addAddress({ ...address, googleLocationId: null })
      .exec()
      .then(() => {
        message.success(TEXT_ADD_ADDRESS_SUCCESS);
        const recordFilters = { page: currentPage, pageSize };
        dispatch(getAddresses(recordFilters));
      });
  };

  const handleDeleteAddress = (id) => () => {
    // confirm delete
    // delete
    modalConfirm({
      content: 'Are you sure you want to delete your address?',
      okText: 'Yes',
      cancelText: 'No',
      width: 530,
      onOk() {
        return deleteAddress(id)
          .exec()
          .then(() => {
            const recordFilters = { page: currentPage, pageSize };
            dispatch(getAddresses(recordFilters));
          });
      },
      onCancel() {}
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'location_id',
      key: 'edit',
      width: 80,
      render: (_, record) => (
        <AddressInput
          data={record}
          onSubmit={handleAddAddress}
          title="Edit Address"
          submitText="Edit"
        >
          {({ showModal }) => (
            <OperationButton onClick={showModal} type="link">
              <img alt="edit" src={editIcon} />
            </OperationButton>
          )}
        </AddressInput>
      ),
      fixed: 'left'
    },
    {
      title: '',
      dataIndex: 'location_id',
      key: 'delete',
      width: 80,
      render: (id) => (
        <OperationButton onClick={handleDeleteAddress(id)} type="link">
          <img alt="delete" src={deleteIcon} />
        </OperationButton>
      ),
      fixed: 'left'
    },
    {
      title: 'Location Name',
      dataIndex: 'location_name',
      key: 'location_name'
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country'
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city'
    },
    {
      title: 'Postal Code',
      dataIndex: 'postal_code',
      key: 'postal_code'
    },
    {
      title: 'Address',
      dataIndex: 'street_address',
      key: 'street_address'
    },
    {
      title: 'POC Name',
      dataIndex: 'poc_name',
      key: 'poc_name'
    },
    {
      title: 'POC Phone Number',
      dataIndex: 'poc_number',
      key: 'poc_number'
    },
    {
      title: 'POC Email',
      dataIndex: 'poc_email',
      key: 'poc_email'
    }
  ];

  return (
    <div>
      <StyledCard>
        <Row type="flex" sx={{ mb: '40px' }} gutter={16}>
          <Col>
            <AddressInput onSubmit={handleAddAddress}>
              {({ showModal }) => (
                <Button type="primary" size="large" onClick={showModal}>
                  Add Address
                </Button>
              )}
            </AddressInput>
          </Col>
        </Row>
        <Row>
          <Table
            loading={loading}
            type="secondary"
            columns={columns}
            dataSource={data}
            pageSize={parseInt(pageSize || DEFAULT_PAGE_SIZE, 10)}
            currentPage={currentPage}
            onChange={(page) => dispatch(setPageFilter(page))}
            totalCount={totalCount}
            empty={
              <DashedContainer>
                <StyledNoContent>
                  You have no addresses currently.
                </StyledNoContent>
              </DashedContainer>
            }
            // onChange={(page) => dispatch(setPageFilter(page))}
          />
        </Row>
      </StyledCard>
    </div>
  );
};
export default Addresses;
