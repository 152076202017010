import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Col, message } from 'antd';

import Modal from './Modal';
import Button from './Button';
import DatePicker from './DatePicker';
import Input from './Input';
import { shipmentService } from '../services/shipments';
import { getProfile } from '../auth0';

const Title = styled.span`
  color: #050593;
`;
const ErrorTitle = styled.span`
  color: #cf4242;
`;
const StyledModalContent = styled(Col)`
  // padding: 0 0 15px 15px;
`;
const { RangePicker } = DatePicker;

const GenerateCSVModal = ({ isModalVisible, setIsModalVisible }) => {
  const [email, setEmail] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);

  const handleDatePickerChange = (value) => {
    setDateRange(value);
  };
  const handleExportCSV = async () => {
    if (!email) {
      setErrorMessage('Please enter a valid email');
    } else {
      try {
        setLoading(true);
        const profile = await getProfile();
        const params = {
          email,
          isMerchant: true,
          userId: profile.sub,
          startDate: dateRange[0]?.startOf('day').toISOString(),
          endDate: dateRange[1]?.endOf('day').toISOString()
        };
        await shipmentService.exportShipmentsCSV(params);
        message.success('Export Request Sent');
        setIsModalVisible(false);
        setLoading(false);
      } catch (error) {
        message.error(error.message);
        setLoading(false);
      }
    }
  };
  const handleEmailChange = (e) => {
    setErrorMessage(undefined);
    const userEmail = e.target.value;
    const isValid = /^\S+@\S+$/.test(userEmail);
    if (isValid) {
      setEmail(userEmail);
    } else {
      setErrorMessage('Please enter a valid email');
    }
  };
  return (
    <Modal
      title={<Title>Generate CSV report for all orders</Title>}
      visible={isModalVisible}
      closable={false}
      footer={[
        <Button
          key="back"
          type="secondary"
          onClick={() => setIsModalVisible(false)}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          loading={loading}
          onClick={handleExportCSV}
          disabled={errorMessage}
        >
          Confirm
        </Button>
      ]}
    >
      <StyledModalContent>
        <p>
          Let us know your email address. The CSV report will take some time to
          be generated. After that, it will be delivered to this email address.
        </p>
        <p style={{ marginTop: 20 }}>
          <label htmlFor="export-creation-date">
            Shipment creation date range
          </label>
          <RangePicker
            id="shipment-date-range"
            style={{ marginTop: 8 }}
            value={dateRange}
            onChange={handleDatePickerChange}
            contained
          />
        </p>
        <p style={{ marginTop: 20 }}>
          <label htmlFor="export-email-address">Email Address</label>
          <Input
            id="export-email-address"
            style={{ marginTop: 8 }}
            placeholder="example@email.com"
            value={email}
            onChange={handleEmailChange}
          />
          {errorMessage && <ErrorTitle>{errorMessage}</ErrorTitle>}
        </p>
      </StyledModalContent>
    </Modal>
  );
};

export default GenerateCSVModal;
