// TODO: stop adding stuff here, slowly port this to `createAction` from `@reduxjs/toolkit`
export const GET_DATA_REQUEST_QUOTE = 'requestQuote/GET_DATA';
export const POST_DATA_REQUEST_QUOTE = 'requestQuote/POST_DATA';

export const GET_DATA_QUOTE_DETAIL = 'quoteDetail/GET_DATA';
export const GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG = 'quoteDetail/GETSHIPMENTLOG';
export const GET_DATA_QUOTE_DETAIL_CHARGES = 'quoteDetail/GETCHARGES';
export const RESET_DATA_QUOTE_DETAIL = 'quoteDetail/RESET_DATA';
export const CHANGE_QUOTE_STATUS = 'quoteDetail/GETACCEPT';
export const UPDATE_DOCUMENT = 'quoteDetail/UPDATE_DOCUMENT';
export const REPLACE_DOCUMENT = 'quoteDetail/REPLACE_DOCUMENT';

export const GET_DATA_QUOTE_LIST = 'quoteList/GET_DATA';
export const FETCH_QUOTE_LOOKUPS = 'quoteDetail/FETCH_LOOKUPS';
export const UPDATE_QUOTE_ADDRESS_VIEW = 'quoteDetail/UPDATE_ADDRESS_VIEW';
