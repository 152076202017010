import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import FullLoadInput from './FullLoadInput';
import Button from '../Button';

const FullLoadOperations = styled.div`
  display: flex;
  h6 {
    margin-left: auto;
    margin-bottom: 0;
    align-self: center;
  }
`;

const FullLoadContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

const FullLoad = ({
  modeOfShipment,
  loads = [],
  loadTypes = [],
  onChange = () => {},
  // eslint-disable-next-line no-unused-vars
  onBlur = () => {},
  loading = false
}) => {
  const loadItemInit = () => {
    return {
      pId: randomstring.generate(),
      amount: null,
      type: undefined
    };
  };
  const [container, setContainer] = useState(true);
  const [errors, setErrors] = useState({});
  const [loadItems, setLoads] = useState(() => {
    if (loads.length > 0) {
      return loads;
    }
    return [loadItemInit()];
  });

  useEffect(() => {
    if (loads.length > 0) {
      return setLoads(loads);
    }
    return setLoads([loadItemInit()]);
  }, [loads]);

  const handleChange = (idx, pid) => (e, err) => {
    const loadChange = [...loadItems];
    loadChange[idx] = e;
    setLoads(loadChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [pid]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [pid]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const handleOnBlur = (idx, pid) => (e, err) => {
    if (err !== null) {
      setErrors({
        ...errors,
        [pid]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [pid]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  useDeepCompareEffect(() => {
    onChange(loadItems, Object.keys(errors).length > 0);
  }, [loadItems, errors]);

  useEffect(() => {
    if (modeOfShipment === 'Sea') {
      setContainer(true);
    }
    if (modeOfShipment === 'Land') {
      setContainer(false);
    }
  }, [modeOfShipment]);

  const newLoad = () => {
    setLoads([...loadItems, loadItemInit()]);
  };

  const removeLoad = (idx, pid) => () => {
    const newLoads = [...loadItems];
    newLoads.splice(idx, 1);
    setLoads(newLoads);
    const newErrors = { ...errors };
    delete newErrors[pid];
    setErrors(newErrors);
  };

  return (
    <>
      <FullLoadContainer>
        {loadItems.map((loadItem, idx) => {
          return (
            <FullLoadInput
              key={loadItem.pId}
              value={loadItem}
              showTooltip={idx === 0}
              showDelete={idx > 0}
              onDelete={removeLoad(idx, loadItem.pId)}
              loadTypes={loadTypes}
              loading={loading}
              onChange={handleChange(idx, loadItem.pId)}
              onBlur={handleOnBlur(idx, loadItem.pId)}
              container={container}
            />
          );
        })}
      </FullLoadContainer>

      <FullLoadOperations>
        <Button type="secondary" onClick={newLoad}>
          <Icon type="plus-circle" />
          Add {container ? 'container' : 'truck'} type
        </Button>
      </FullLoadOperations>
    </>
  );
};
export default FullLoad;
