import { useState, useCallback, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';

export const useDebounce = (propValue, onChange = () => {}, timeout = 3000) => {
  // debounced react controlled component 😎
  const [value, setValue] = useState(() => propValue);

  const debouncedOnChangeRef = useRef();
  debouncedOnChangeRef.current = onChange;

  const debouncedOnChange = useCallback(
    debounce(debouncedOnChangeRef.current, timeout),
    [onChange]
  );

  const inputEventRef = useRef();

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  useEffect(() => {
    if (inputEventRef.current) {
      debouncedOnChange(inputEventRef.current);
    }
    // disabling ESLint rule because I know what I am doing.
    // https://github.com/facebook/react/issues/14920#issuecomment-472018256
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnChange = (e) => {
    e.persist();
    inputEventRef.current = e;
    setValue(e.target.value);
  };
  return [value, handleOnChange];
};
