import countryList from 'country-list';

const COUNTRIES = countryList
  .getData()
  .map((_item) => ({ value: _item.name, label: _item.name }));

const ALL_COUNTRIES = countryList.getData().map((_item) => ({
  label: _item.name,
  value: _item.name,
  countryCode: _item.code
}));

export default COUNTRIES;
export { ALL_COUNTRIES };
