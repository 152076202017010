export const STATUS_QUOTE_REQUESTED = 'QUOTE_REQUESTED';
export const STATUS_QUOTE_DRAFT_CREATED = 'QUOTE_DRAFT_CREATED';
export const STATUS_QUOTE_REQUEST_UNSUCCESSFUL = 'QUOTE_REQUEST_UNSUCCESSFUL';
export const STATUS_QUOTE_CREATED = 'QUOTE_CREATED';
export const STATUS_QUOTE_ACCEPTED = 'QUOTE_ACCEPTED';
export const STATUS_QUOTE_DOCUMENT_UPLOADED = 'DOCUMENT_UPLOADED';
export const STATUS_QUOTE_SHIPMENT_CONFIRMED = 'SHIPMENT_CONFIRMED';
export const STATUS_QUOTE_REREQUESTED_DRAFT = 'QUOTE_REREQUESTED_DRAFT';
export const STATUS_QUOTE_EXPIRED = 'QUOTE_EXPIRED';

export const STATUS_SHIPMENT_CREATED = 'SHIPMENT_CREATED';
export const STATUS_SCHEDULED_FOR_PICKUP = 'SCHEDULED_FOR_PICKUP';
export const STATUS_SCHEDULED_FOR_DROPOFF = 'SCHEDULED_FOR_DROPOFF';
export const STATUS_RESCHEDULED_FOR_PICKUP = 'RESCHEDULED_FOR_PICKUP';
export const STATUS_RESCHEDULED_FOR_DROPOFF = 'RESCHEDULED_FOR_DROPOFF';
export const STATUS_SHIPMENT_PICKEDUP = 'SHIPMENT_PICKEDUP';
export const STATUS_REACHED_ORIGIN_WAREHOUSE = 'REACHED_ORIGIN_WAREHOUSE';
export const STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL =
  'CARGO_DELIVERED_TO_AIRLINES_TERMINAL';
export const STATUS_FLIGHT_RESCHEDULED = 'FLIGHT_RESCHEDULED';
export const STATUS_CONTAINER_LOADING = 'CONTAINER_LOADING';
export const STATUS_RECEIVED_BY_AIRLINES = 'RECEIVED_BY_AIRLINES';
export const STATUS_CARGO_ONBOARD_VESSEL = 'CARGO_ONBOARD_VESSEL';
export const STATUS_ON_SAIL = 'ON_SAIL';
export const STATUS_PARTIAL_UPLIFT_TO_DESTINATION =
  'PARTIAL_UPLIFT_TO_DESTINATION';
export const STATUS_FREIGHT_UPLIFT_COMPLETED = 'FREIGHT_UPLIFT_COMPLETED';
export const STATUS_OFFLOADED_BY_AIRLINES = 'OFFLOADED_BY_AIRLINES';
export const STATUS_IN_TRANSHIPMENT_COUNTRY = 'IN_TRANSHIPMENT_COUNTRY';
export const STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY =
  'CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY';
export const STATUS_PENDING_CUSTOM_CLEARANCE = 'PENDING_CUSTOM_CLEARANCE';
export const STATUS_OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
export const STATUS_DELIVERED_WITHOUT_POD = 'DELIVERED_WITHOUT_POD';
export const STATUS_PARTIALLY_DELIVERED = 'PARTIALLY_DELIVERED';
export const STATUS_VESSEL_DELAYED = 'VESSEL_DELAYED';
export const STATUS_SPACE_BOOKING_IN_PROGRESS = 'SPACE_BOOKING_IN_PROGRESS';
export const STATUS_DELIVERED = 'DELIVERED';
export const STATUS_CANCELLED = 'CANCELLED';
export const STATUS_BILLED = 'BILLED';
export const STATUS_RESCHEDULED_FOR_DELIVERY = 'RESCHEDULED_FOR_DELIVERY';
export const STATUS_DELIVERY_FAILED = 'DELIVERY_FAILED';
export const QUOTE_REQUEST = 'QUOTE_REQUEST';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const WIN = 'WIN';
export const LOSS = 'LOSS';

export const QUOTE_STATUS_MAPPING = {
  QUOTE_REQUEST: 'Quote Requested',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  WIN: 'Win',
  LOSS: 'Loss'
};
