import React, { forwardRef } from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import styled from '@emotion/styled';

const StyledInputNumber = styled(AntInputNumber)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  /* height: 35px; */
  color: #000;
  /* Font alignment adjustment for AvenirLTStdBook */
  height: 35px;
  border: 1px solid #ccc;
  &:hover {
    border-color: #050593;
  }
  &:focus {
    border-color: #050593;
    box-shadow: 0 0 0 2px rgba(5, 5, 147, 0.13);
  }
  .ant-input-number-input-wrap input {
    height: 35px;
  }
`;

const InputNumber = (props, ref) => {
  const { onChange = () => {}, value, ...rest } = props;

  return (
    <StyledInputNumber {...rest} onChange={onChange} ref={ref} value={value} />
  );
};

export default forwardRef(InputNumber);
