import React from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import moment from 'moment';
import shipmentConfirmed from '../assets/images/shipment_confirmed.png';
import delivered from '../assets/images/delivered.png';
import rescheduledDelivery from '../assets/images/rescheduled_delivery.png';
import deliveryFailed from '../assets/images/delivery_failed.png';
import outForDelivery from '../assets/images/out_for_delivery.png';
import pendingCustomsClearance from '../assets/images/pending_customs_clearance.png';
import receivedByAirline from '../assets/images/received_by_airline.png';
import containerLoading from '../assets/images/container_loading.png';
import scheduledForPickup from '../assets/images/scheduled_for_pickup.png';
import reachedOriginWarehouse from '../assets/images/reached_origin_warehouse.png';
import cargoOnboardVessel from '../assets/images/cargo_onboard_vessel.png';
import onSail from '../assets/images/on_sail.png';
import {
  STATUS_QUOTE_SHIPMENT_CONFIRMED,
  STATUS_PARTIALLY_DELIVERED,
  STATUS_DELIVERED,
  STATUS_OUT_FOR_DELIVERY,
  STATUS_PENDING_CUSTOM_CLEARANCE,
  STATUS_RECEIVED_BY_AIRLINES,
  STATUS_REACHED_ORIGIN_WAREHOUSE,
  STATUS_SCHEDULED_FOR_PICKUP,
  STATUS_RESCHEDULED_FOR_PICKUP,
  STATUS_SCHEDULED_FOR_DROPOFF,
  STATUS_RESCHEDULED_FOR_DROPOFF,
  STATUS_SHIPMENT_PICKEDUP,
  STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL,
  STATUS_IN_TRANSHIPMENT_COUNTRY,
  STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY,
  STATUS_DELIVERED_WITHOUT_POD,
  STATUS_BILLED,
  STATUS_FLIGHT_RESCHEDULED,
  STATUS_CONTAINER_LOADING,
  STATUS_OFFLOADED_BY_AIRLINES,
  STATUS_ON_SAIL,
  STATUS_CARGO_ONBOARD_VESSEL,
  STATUS_PARTIAL_UPLIFT_TO_DESTINATION,
  STATUS_FREIGHT_UPLIFT_COMPLETED,
  STATUS_RESCHEDULED_FOR_DELIVERY,
  STATUS_DELIVERY_FAILED,
  STATUS_VESSEL_DELAYED,
  STATUS_SPACE_BOOKING_IN_PROGRESS
} from '../constants/status';

const formatDate = (date) => moment(date).format('DD MMM YYYY');

const StatusData = {
  [STATUS_QUOTE_SHIPMENT_CONFIRMED]: {
    title: 'Shipment Confirmed',
    description: (quote) =>
      `Please place your shipment at the pickup point on ${formatDate(
        quote.origin.pickup_date
      )}`,
    icon: shipmentConfirmed
  },
  [STATUS_PARTIALLY_DELIVERED]: {
    title: 'Partially Delivered',
    description: () =>
      'Some items (not all) in your shipment have been successfully delivered.',
    icon: outForDelivery
  },
  [STATUS_VESSEL_DELAYED]: {
    title: 'Vessel Delayed',
    description: () =>
      'Vessel arrival is delayed because of unforseen circumstances.',
    icon: cargoOnboardVessel
  },
  [STATUS_SPACE_BOOKING_IN_PROGRESS]: {
    title: 'Space Booking in Progress',
    description: () =>
      'Vessel space allocated for your shipment is currently awaiting confirmation.',
    icon: scheduledForPickup
  },
  [STATUS_DELIVERED]: {
    title: 'Delivered',
    description: () => 'Your shipment has reached your destination',
    icon: delivered
  },
  [STATUS_RESCHEDULED_FOR_DELIVERY]: {
    title: 'Rescheduled for Delivery',
    description: () =>
      'Your shipment is rescheduled for last-mile delivery to the destination',
    icon: rescheduledDelivery
  },
  [STATUS_DELIVERY_FAILED]: {
    title: 'Delivery Failed',
    description: () => 'Delivery of your shipment has failed',
    icon: deliveryFailed
  },
  [STATUS_DELIVERED_WITHOUT_POD]: {
    title: 'Delivered without POD',
    description: () =>
      'Your shipment has reached your destination but Proof of Delivery has not been uploaded yet.',
    icon: delivered
  },
  [STATUS_BILLED]: {
    title: 'Billed',
    description: () => 'Your shipment has been billed',
    icon: delivered
  },
  [STATUS_OUT_FOR_DELIVERY]: {
    title: 'Out for Delivery',
    description: () =>
      'Your shipment is out for last-mile delivery to the destination',
    icon: outForDelivery
  },
  [STATUS_PENDING_CUSTOM_CLEARANCE]: {
    title: 'Pending Customs Clearance in Destination',
    description: () =>
      'Your shipment is at the customs of the destination country',
    icon: pendingCustomsClearance
  },
  [STATUS_RECEIVED_BY_AIRLINES]: {
    title: 'Received by Airline',
    description: () =>
      'Your shipment is being transported to the destination country',
    icon: receivedByAirline
  },
  [STATUS_REACHED_ORIGIN_WAREHOUSE]: {
    title: 'Reached Origin Warehouse',
    description: () =>
      'Your shipment is being prepared to be ready for air freight',
    icon: reachedOriginWarehouse
  },
  [STATUS_SCHEDULED_FOR_PICKUP]: {
    title: 'Scheduled for Pickup',
    description: (quote) =>
      `Please place your shipment at the pickup point on ${formatDate(
        quote.origin.pickup_date
      )}`,
    icon: scheduledForPickup
  },
  [STATUS_RESCHEDULED_FOR_PICKUP]: {
    title: 'Rescheduled for Pickup',
    description: (quote) =>
      `Please place your shipment at the pickup point on ${formatDate(
        quote.origin.pickup_date
      )}`,
    icon: scheduledForPickup
  },
  [STATUS_SCHEDULED_FOR_DROPOFF]: {
    title: 'Scheduled for Dropoff',
    description: (quote) =>
      `Please place your shipment at the dropoff point on ${formatDate(
        quote.origin.pickup_date
      )}`,
    icon: scheduledForPickup
  },
  [STATUS_RESCHEDULED_FOR_DROPOFF]: {
    title: 'Rescheduled for Dropoff',
    description: (quote) =>
      `Please place your shipment at the dropoff point on ${formatDate(
        quote.origin.pickup_date
      )}`,
    icon: scheduledForPickup
  },
  [STATUS_SHIPMENT_PICKEDUP]: {
    title: 'Shipment Picked Up',
    description: () => `Your shipment has been picked up`,
    icon: scheduledForPickup
  },
  [STATUS_CARGO_DELIVERED_TO_AIRLINES_TERMINAL]: {
    title: 'Cargo delivered to Airlines Terminal',
    description: () => 'Your shipment is delivered to Airlines Terminal',
    icon: receivedByAirline
  },
  [STATUS_FLIGHT_RESCHEDULED]: {
    title: 'Flight Rescheduled',
    description: () =>
      'Flight has been re-scheduled. We are working on it and will keep you informed once the shipment is scheduled for uplift.',
    icon: receivedByAirline
  },
  [STATUS_OFFLOADED_BY_AIRLINES]: {
    title: 'Offloaded By Airlines',
    description: () =>
      'Airlines has offloaded the shipment due to space constraints. We are working on it and will keep you informed once the shipment is scheduled for uplift.',
    icon: receivedByAirline
  },
  [STATUS_CONTAINER_LOADING]: {
    title: 'Container Loading',
    description: () => 'Your shipment is being loaded for sea freight',
    icon: containerLoading
  },
  [STATUS_CARGO_ONBOARD_VESSEL]: {
    title: 'Cargo Onboard Vessel',
    description: () => 'Your shipment is onboard vessel',
    icon: cargoOnboardVessel
  },
  [STATUS_ON_SAIL]: {
    title: 'On Sail',
    description: () => 'Your shipment is on sail in the vessel',
    icon: onSail
  },
  [STATUS_PARTIAL_UPLIFT_TO_DESTINATION]: {
    title: 'Partial Uplift to Destination',
    description: () =>
      'Shipment has been partially uplifted by airline. We are working on it and will keep you informed on the next uplift.',
    icon: scheduledForPickup
  },
  [STATUS_FREIGHT_UPLIFT_COMPLETED]: {
    title: 'Freight Uplift Completed',
    description: () => 'Your shipment has been uplifted',
    icon: receivedByAirline
  },
  [STATUS_IN_TRANSHIPMENT_COUNTRY]: {
    title: 'In Transhipment Country',
    description: () => 'Your shipment is currently in a transhipment country',
    icon: receivedByAirline
  },
  [STATUS_CUSTOM_CLEARANCE_IN_DESTINATION_COUNTRY]: {
    title: 'Pending Customs Clearance at Destination',
    description: () =>
      'Your shipment is at the customs of the destination country',
    icon: pendingCustomsClearance
  }
};

const StyledShipmentStatus = styled.div``;

const ShipmentStatus = () => {
  const detailAPI = useSelector((state) => state?.quoteDetail?.quote);
  const shippingLog = useSelector((state) => state?.quoteDetail?.shipping_log);

  const status = StatusData[detailAPI.quote_status];

  const getDate = () => {
    if (shippingLog) {
      return `${moment(shippingLog[0]?.created_at).format(
        'DD MMM YYYY'
      )} at ${moment(shippingLog[0]?.created_at).format('hh:mm A')}`;
    }
    return null;
  };

  return (
    <StyledShipmentStatus>
      <span className="caption">Latest Status</span>
      <div className="wrapper-status-shipment">
        <img alt="logo" src={status?.icon} />
        <div>
          <p className="heavy">{status?.title}</p>
          <p style={{ lineHeight: 1.25 }}>{status?.description(detailAPI)}</p>
          <p
            style={{
              fontFamily: 'AvenirLTStdBookOblique',
              fontSize: 14,
              marginTop: '10px'
            }}
          >
            {getDate()}
          </p>
        </div>
      </div>
    </StyledShipmentStatus>
  );
};

export default ShipmentStatus;
