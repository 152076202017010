import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button, Popover, Icon } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import SearchInput from '../SearchInput';
import NumberInput from '../NumberInput';
import Message from '../Message';
import { initValidators } from '../../helpers/validation';

initValidators();

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;

const Container = styled.div`
  width: 70%;
  position: relative;
  background-color: #ededf7;
  padding: 15px 30px 0px 30px;
  .ant-row {
    width: calc(100% - 25px);
  }
  .load-row {
    padding-bottom: 21px;
    border-bottom: solid 1px #cccccc;
  }
  .delete-load {
    position: absolute;
    right: 30px;
    top: 45px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
    transition: none;
  }
  .delete-load:hover,
  .delete-load:focus {
    border-color: #050593;
    color: #050593;
  }
  &:first-of-type {
    padding-top: 30px;
  }
  &:last-of-type {
    .load-row {
      padding-bottom: 30px;
      border-bottom: none;
    }
  }
`;

const FullLoadInput = ({
  value,
  showTooltip = true,
  showDelete = true,
  container = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {},
  loadTypes = {},
  loading = false
}) => {
  const type = container ? 'container' : 'truck';
  const [loadState, setLoadState] = useState(value);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required(`Please enter type of ${type}`),
    amount: Yup.mixed()
      .nullable()
      .test('amount', `Please enter no. of ${type}s`, (val) => {
        return val;
      })
      .validInteger()
      .min('', 1)
      .max('', 50)
  });

  const validate = () => {
    validationSchema
      .validate(loadState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          return {
            ...ers,
            [er.path]: er.message
          };
        }, {});
        setErrors(errs);
      });
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }
    onChange(loadState, errors);
  }, [loadState, errors]);

  useEffect(() => {
    if (dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  const handleChange = (name) => (e) => {
    dirty.current = true;
    switch (name) {
      case 'type':
        setLoadState({ ...loadState, type: e });
        break;
      case 'amount':
        setLoadState({ ...loadState, amount: e });
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur(loadState, errors);
  };

  return (
    <Container>
      <div className="load-row">
        <Row gutter={20}>
          <Col span={12}>
            <p>
              Type of {type}
              {showTooltip && type === 'container' && (
                <StyledPopover
                  title={<h4>Type of {type}</h4>}
                  content={
                    <>
                      <p>
                        <strong>20 ft</strong> (25-28 cbm of cartons, 8 pallets
                        or 16 pallets double stacked pallets of around 110 cm,
                        Max payload 25,000 kg)
                      </p>
                      <p>
                        <strong>40 ft</strong> (54-58 cbm of cartons, 18 pallets
                        or 36 pallets double stacked pallets of around 110 cm,
                        Max payload 28,000 kg)
                      </p>
                      <p>
                        <strong>40 ft HC</strong> (60-68 cbm of cartons, 18
                        pallets or 36 pallets double stacked pallets of around
                        120 cm, Max payload 28,000 kg)
                      </p>
                    </>
                  }
                >
                  <Icon className="question-circle" type="question-circle" />
                </StyledPopover>
              )}
            </p>
            <SearchInput
              placeholder="Select"
              items={(loadTypes[`${type}s`] || []).map((item) => ({
                label: item.value,
                value: item.key
              }))}
              value={loadState?.type}
              loading={loading}
              onChange={handleChange('type')}
              onBlur={handleBlur}
            />
            {errors?.type && dirty.current && (
              <Message type="error">{errors?.type}</Message>
            )}
          </Col>
          <Col span={12}>
            <p>No. of {type}s</p>
            <NumberInput
              placeholder="How much"
              value={loadState?.amount}
              onChange={handleChange('amount')}
              onBlur={handleBlur}
            />
            {errors?.amount && dirty.current && (
              <Message type="error">{errors?.amount}</Message>
            )}
          </Col>
        </Row>
        {showDelete && (
          <Button className="delete-load" icon="delete" onClick={onDelete} />
        )}
      </div>
    </Container>
  );
};
export default FullLoadInput;
