import axios from 'axios';
import history from '../helpers/history';

const request = (opts = {}, optsHeader = {}) => {
  const token = localStorage.getItem('id_token');
  const defaultOptions = {
    ...opts,
    headers: !token
      ? optsHeader
      : {
          ...optsHeader,
          Authorization: `Bearer ${token}`
        }
  };

  const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...defaultOptions
  });

  axiosApi.CancelToken = axios.CancelToken;
  axiosApi.isCancel = axios.isCancel;

  axiosApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
        history.replace('/login');
      }

      return Promise.reject(error);
    }
  );
  return axiosApi;
};

// TODO: replace all API request on services with this get/post
// execute through .exec(), cancel through .cancel()
const post = (url, body = {}, opts = {}) => {
  const { CancelToken, post: axiosPost } = request();
  const source = CancelToken.source();
  return {
    exec: () =>
      axiosPost(url, body, {
        ...opts,
        cancelToken: source.token
      }),
    cancel: () => source.cancel()
  };
};

const patch = (url, body = {}, opts = {}) => {
  const { CancelToken, patch: axiosPatch } = request();
  const source = CancelToken.source();
  return {
    exec: () =>
      axiosPatch(url, body, {
        ...opts,
        cancelToken: source.token
      }),
    cancel: () => source.cancel()
  };
};

const get = (url, opts = {}) => {
  const { CancelToken, get: axiosGet } = request();
  const source = CancelToken.source();
  return {
    exec: () =>
      axiosGet(url, {
        ...opts,
        cancelToken: source.token
      }),
    cancel: () => source.cancel()
  };
};

const remove = (url, opts = {}) => {
  const { CancelToken, delete: axiosDelete } = request();
  const source = CancelToken.source();
  return {
    exec: () =>
      axiosDelete(url, {
        ...opts,
        cancelToken: source.token
      }),
    cancel: () => source.cancel()
  };
};

// TODO: prefer get/post above which provides cancellable requests
export default request;

export const axiosRequest = {
  post,
  get,
  patch,
  delete: remove
};
