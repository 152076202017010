import * as React from 'react';
import { Field } from 'formik';
import MultiSelect from '../MultiSelect';
import Message from '../Message';

const FormMultiSelect = ({
  name,
  validate,
  onChange = () => {},
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta
      }) => (
        <>
          <MultiSelect
            value={value}
            onChange={(selectedValue) => {
              setFieldValue(name, selectedValue);
              setFieldTouched(name, true);
              onChange(selectedValue);
            }}
            onBlur={() => {
              setFieldTouched(name, true);
            }}
            {...restProps}
          />
          {meta.error && meta.touched && (
            <Message type="error">{meta.error}</Message>
          )}
        </>
      )}
    </Field>
  );
};

export default FormMultiSelect;
