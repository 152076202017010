import axios from 'axios';
import { createAction } from '@reduxjs/toolkit';
import {
  GET_DATA_QUOTE_DETAIL,
  GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG,
  GET_DATA_QUOTE_DETAIL_CHARGES,
  REPLACE_DOCUMENT,
  RESET_DATA_QUOTE_DETAIL
} from './types';
import {
  STATUS_QUOTE_ACCEPTED,
  STATUS_QUOTE_DOCUMENT_UPLOADED
} from '../constants/status';
import request from '../utils/request';
import { removeNonEnglishCharacters } from '../utils/formatString';
import { quoteService } from '../services/quotes';

export const changeQuoteStatus = createAction('quote/CHANGE_STATUS');
export const fetchRequestQuotesDetail = createAction(
  'quote/FETCH_DETAIL_QUOTE_REQUEST'
);
export const replaceDocument = createAction('quote/REPLACE_DOCUMENT');
export const updateQuoteStatus = ({ id, status, reason = '' }) => async (
  dispatch
) => {
  const res = await quoteService.changeQuoteStatus(id, {
    action: status,
    notes: reason
  });
  dispatch(changeQuoteStatus(status));
  // TODO: definitely create a getShipmentLog thunk
  request()
    .get(`/quote/${id}/shipment-log`)
    .then((response) => {
      dispatch({
        type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG,
        payload: response.data.data
      });
    })
    .catch(() => {});
  return res;
};

export const updateQuoteWinLossStatus = ({ id, data }) => async (dispatch) => {
  const res = await quoteService.changeQuoteRequestStatus(id, data);
  request()
    .get(`/quote/${id}/shipment-log`)
    .then((response) => {
      dispatch({
        type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG,
        payload: response.data.data
      });
    })
    .catch(() => {});
  return res;
};
export const subscribeToEmail = (
  { email, id },
  response = () => {},
  error = () => {}
) => {
  return request()
    .post(`/shipments/subscribe/${id}`, { email })
    .then(() => {
      response();
    })
    .catch(() => {
      error();
    });
};
export const getRequestQuotesDetail = (id) => async (dispatch) => {
  const res = await quoteService.getRequestQuotesDetail(id);
  dispatch(fetchRequestQuotesDetail(res));
};

export const getRequestQuote = (props) => (dispatch) => {
  const { Id, type, ...params } = props;
  let quoteURL = '';
  switch (type) {
    case GET_DATA_QUOTE_DETAIL:
      quoteURL = `/shipments/${Id}`;
      break;
    case GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG:
      quoteURL = `/quote/${Id}/shipment-log`;
      break;
    case GET_DATA_QUOTE_DETAIL_CHARGES:
      quoteURL = `/quote/${Id}/charges`;
      break;
    default:
      break;
  }
  request()
    .get(quoteURL, { ...params })
    .then((response) => {
      dispatch({
        type,
        payload: response.data.data
      });
    })
    .catch((err) => {
      switch (err.response.status) {
        case 403:
          window.location.href = '/error/403';
          break;
        default:
          return err;
      }
      return err;
    });
};

export const acceptDocument = (Id, response = () => {}, error = () => {}) => (
  dispatch
) => {
  return request()
    .post(`/quote/${Id}/accept`, {})
    .then(() => {
      dispatch(changeQuoteStatus(STATUS_QUOTE_ACCEPTED));
      dispatch(
        getRequestQuote({
          Id,
          type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
        })
      );
      response();
    })
    .catch(() => {
      error();
    });
};

export const uploadDocument = (
  props,
  response = () => {},
  error = () => {}
) => (dispatch) => {
  const { data, Id, replace = false } = props;
  if (!replace) {
    quoteService
      .changeQuoteStatus(Id, {
        action: STATUS_QUOTE_DOCUMENT_UPLOADED,
        notes: null,
        log: null
      })
      .then(() => {
        dispatch(
          getRequestQuote({
            Id,
            type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
          })
        );
      })
      .catch(() => {
        error();
      });
    dispatch(changeQuoteStatus(STATUS_QUOTE_DOCUMENT_UPLOADED));
    response();
    return;
  }
  const signedRequest = { files: [] };
  const formData = {};
  const uploadRequest = { files: [] };
  const {
    packingListFile,
    commercialInvoiceFile,
    exporterOfRecordsFile,
    importerOfRecordFile,
    MSDSFile,
    ...customFiles
  } = data;
  if (packingListFile) {
    formData.PACKING_LIST = packingListFile;
    signedRequest.files.push('PACKING_LIST');
  }
  if (commercialInvoiceFile) {
    formData.COMMERCIAL_INVOICE = commercialInvoiceFile;
    signedRequest.files.push('COMMERCIAL_INVOICE');
  }
  if (MSDSFile) {
    formData['MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'] = MSDSFile;
    signedRequest.files.push('MATERIAL_SAFETY_DATA_SHEETS_(MSDS)');
  }

  if (importerOfRecordFile) {
    formData['IMPORTER_OF_RECORD_(IOR)'] = importerOfRecordFile;
    signedRequest.files.push('IMPORTER_OF_RECORD_(IOR)');
  }

  if (exporterOfRecordsFile) {
    formData['EXPORTER_OF_RECORD_(EOR)'] = exporterOfRecordsFile;
    signedRequest.files.push('EXPORTER_OF_RECORD_(EOR)');
  }
  // eslint-disable-next-line array-callback-return
  Object.keys(customFiles).map((fileName) => {
    formData[fileName] = customFiles[fileName];
    signedRequest.files.push(fileName);
  });

  request()
    .post(`/quote/${Id}/sign-documents`, signedRequest)
    .then(async (signedUrls) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of signedRequest.files) {
        const url = signedUrls.data.data[file];
        const options = {
          headers: {
            'Content-Type': formData[file].type
          }
        };
        // eslint-disable-next-line no-await-in-loop
        await axios.put(url.pre_signed_url, formData[file], options);
        uploadRequest.files.push({
          field_name: file,
          key: url.key,
          file_name: removeNonEnglishCharacters(formData[file].name)
        });
      }
      request()
        .post(`quote/v2/${Id}/document-upload`, uploadRequest)
        .then((resp) => {
          dispatch(
            getRequestQuote({
              Id,
              type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
            })
          );
          dispatch({
            type: REPLACE_DOCUMENT,
            payload: resp.data.data
          });
          response();
        })
        .catch(() => {
          error();
        });
    });
};

export const getFullDataRequestQuote = (Id) => (dispatch) => {
  dispatch(
    getRequestQuote({
      Id,
      type: GET_DATA_QUOTE_DETAIL
    })
  );
  dispatch(
    getRequestQuote({
      Id,
      type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
    })
  );
  dispatch(
    getRequestQuote({
      Id,
      type: GET_DATA_QUOTE_DETAIL_CHARGES
    })
  );
};

export const reSetData = () => ({
  type: RESET_DATA_QUOTE_DETAIL
});

export const updateQuoteAddressView = createAction('quote/UPDATE_ADDRESS_VIEW');

export const unloadRequestQuoteDetails = createAction(
  'quote/UNLOAD_DATA_REQUEST_QUOTE'
);

export const uploadQuoteDocument = (
  props,
  response = () => {},
  error = () => {}
) => (dispatch) => {
  const { data, Id } = props;
  const signedRequest = { files: [] };
  const formData = {};
  const uploadRequest = { files: [] };
  const {
    packingListFile,
    commercialInvoiceFile,
    exporterOfRecordsFile,
    importerOfRecordFile,
    MSDSFile,
    ...customFiles
  } = data;
  if (packingListFile) {
    formData.PACKING_LIST = packingListFile;
    signedRequest.files.push('PACKING_LIST');
  }
  if (commercialInvoiceFile) {
    formData.COMMERCIAL_INVOICE = commercialInvoiceFile;
    signedRequest.files.push('COMMERCIAL_INVOICE');
  }
  if (MSDSFile) {
    formData['MATERIAL_SAFETY_DATA_SHEETS_(MSDS)'] = MSDSFile;
    signedRequest.files.push('MATERIAL_SAFETY_DATA_SHEETS_(MSDS)');
  }

  // eslint-disable-next-line array-callback-return
  Object.keys(customFiles).map((fileName) => {
    formData[fileName] = customFiles[fileName];
    signedRequest.files.push(fileName);
  });

  request()
    .post(`/quote/${Id}/sign-documents`, signedRequest)
    .then(async (signedUrls) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const file of signedRequest.files) {
        const url = signedUrls.data.data[file];
        const options = {
          headers: {
            'Content-Type': formData[file].type
          }
        };
        // eslint-disable-next-line no-await-in-loop
        await axios.put(url.pre_signed_url, formData[file], options);
        uploadRequest.files.push({
          field_name: file,
          key: url.key,
          file_name: removeNonEnglishCharacters(formData[file].name)
        });
      }
      request()
        .post(`quote/v2/${Id}/document-upload`, uploadRequest)
        .then(async (res) => {
          dispatch(
            getRequestQuote({
              Id,
              type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
            })
          );
          await dispatch(replaceDocument(res?.data?.data));
          response();
        })
        .catch((err) => {
          console.log(err);
          error();
        });
    });
};
