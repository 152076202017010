import React, { useState, useRef, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Row, Col, Button, Popover, Icon } from 'antd';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { set } from 'lodash';
import DimensionsInput from '../DimensionsInput';
import SearchInput from '../SearchInput';
import NumberInput from '../NumberInput';
import WeightInput from '../WeightInput';
import Message from '../Message';
import FloatInput from '../FloatInput';

const StyledPopover = styled(Popover)`
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  background-color: #ededf7;
  padding: 30px;
  .ant-row {
    width: calc(100% - 25px);
  }
  .delete-dimension {
    position: absolute;
    right: 30px;
    bottom: 32px;
    background-color: transparent;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: transparent;
    }
  }
  .delete-dimension:hover,
  .delete-dimension:focus {
    border-color: #050593;
    color: #050593;
  }
`;

const VolumeWeightInput = ({
  value,
  showDelete = true,
  onDelete = () => {},
  onChange = () => {},
  onBlur = () => {},
  packageTypes = [],
  loading = false,
  modeOfShipment
}) => {
  const [packageState, setPackageState] = useState(value);
  const [errors, setErrors] = useState(null);
  const dirty = useRef(false);

  const validationSchema = Yup.object().shape({
    type: Yup.string()
      .nullable()
      .required('Please enter Package Type'),
    amount: Yup.string()
      .nullable()
      .required('Please enter unit'),
    chargeableWeight: Yup.string()
      .nullable()
      .required('Please enter Chargeable Weight'),
    weight: Yup.object().shape({
      amount: Yup.string()
        .nullable()
        .required(
          packageState?.weightType
            ? 'Please enter Weight for one unit'
            : 'Please enter Total Weight'
        )
    })
  });

  const validate = () => {
    validationSchema
      .validate(packageState, { abortEarly: false })
      .then(() => {
        setErrors(null);
      })
      .catch((err) => {
        const errs = err.inner.reduce((ers, er) => {
          set(ers, er.path, er.message);
          return ers;
        }, {});

        if (Object.keys(errs).length > 0) {
          setErrors(errs);
        } else {
          setErrors(null);
        }
      });
  };

  useEffect(() => {
    if (!dirty.current) {
      validate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (dirty.current) {
      validate();
    }
    onChange(packageState, errors);
  }, [packageState, errors, modeOfShipment]);

  const buildDimensionsError = (error) => {
    const fields = Object.keys(error);
    return error[fields[0]];
  };

  const handleChange = (name) => (e) => {
    dirty.current = true;
    switch (name) {
      case 'type':
        setPackageState({ ...packageState, type: e });
        break;
      case 'amount':
        setPackageState({ ...packageState, amount: e });
        break;
      case 'chargeableWeight':
        setPackageState({ ...packageState, chargeableWeight: e.target.value });
        break;
      case 'weight':
        setPackageState({
          ...packageState,
          weight: {
            isKg: e.unit.toLowerCase() === 'kg',
            amount: e.weight
          },
          weightType: e.type
        });
        break;
      case 'dimensions':
        setPackageState({
          ...packageState,
          dimensions: {
            length: e.length,
            width: e.width,
            height: e.height,
            amount: e.volume,
            isCm: e.unit.toLowerCase() === 'cm'
          },
          volumeType: e.type
        });
        break;
      default:
    }
  };

  const handleBlur = () => {
    if (!dirty.current) {
      validate();
      dirty.current = true;
    }
    onBlur(packageState, errors);
  };

  return (
    <Container>
      <Row gutter={20}>
        <Col span={8}>
          <p>Type</p>
          <SearchInput
            placeholder="Select"
            items={packageTypes}
            value={packageState?.type}
            loading={loading}
            onChange={handleChange('type')}
            onBlur={handleBlur}
          />
          {errors?.type && dirty.current && (
            <Message type="error">{errors?.type}</Message>
          )}
        </Col>
        <Col span={6}>
          <p>No. of Units</p>
          <NumberInput
            placeholder="How much"
            value={packageState?.amount}
            onChange={handleChange('amount')}
            onBlur={handleBlur}
          />
          {errors?.amount && dirty.current && (
            <Message type="error">{errors?.amount}</Message>
          )}
        </Col>
        <Col span={10}>
          <p>Chargeable Weight (KG)</p>
          <FloatInput
            placeholder="How much..."
            value={packageState?.chargeableWeight}
            onChange={handleChange('chargeableWeight')}
            onBlur={handleBlur}
          />
          {errors?.chargeableWeight && dirty.current && (
            <Message type="error">{errors?.chargeableWeight}</Message>
          )}
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={12}>
          <p>
            Weight
            <StyledPopover
              content={
                <>
                  <p />
                  <p>
                    <strong>Total weight</strong> - This refers to the total
                    weight of all items in this set.
                  </p>
                  <p>
                    <strong>Weight for one unit</strong> - This refers to the
                    weight of only 1 unit of items in this set. The total weight
                    of the set is calculated by multiplying &apos;No. of
                    units&apos; and &apos;Weight for one unit&apos;.
                  </p>
                </>
              }
            >
              <Icon className="question-circle" type="question-circle" />
            </StyledPopover>
          </p>
          <WeightInput
            placeholder="How much..."
            value={packageState?.weight?.amount}
            type={packageState?.weightType}
            unit={packageState?.weight?.isKg ? 'KG' : 'LB'}
            onChange={handleChange('weight')}
            onBlur={handleBlur}
          />
          {errors?.weight?.amount && dirty.current && (
            <Message type="error">{errors?.weight?.amount}</Message>
          )}
        </Col>
        <Col span={12}>
          <p>
            {packageState?.volumeType
              ? 'Volume (L x W x H) (Optional)'
              : 'Volume (CBM) (Optional)'}
            <StyledPopover
              content={
                <>
                  <p />
                  <p>
                    <strong>Total volume</strong> - This refers to the total
                    volume of all items in this set.
                  </p>
                  <p>
                    <strong>Unit volume</strong> - This refers to the volume of
                    only 1 unit of items in this set. You will type the length,
                    width and height of each unit. The total volume of the set
                    is calculated by multiplying &apos;No. of units&apos; and
                    &apos;Unit volume&apos; (L x W x H).
                  </p>
                </>
              }
            >
              <Icon className="question-circle" type="question-circle" />
            </StyledPopover>
          </p>
          <DimensionsInput
            value={{
              length: packageState?.dimensions?.length,
              width: packageState?.dimensions?.width,
              height: packageState?.dimensions?.height,
              volume: packageState?.dimensions?.amount
            }}
            type={packageState?.volumeType}
            unit={packageState?.dimensions?.isCm ? 'CM' : 'IN'}
            onChange={handleChange('dimensions')}
            onBlur={handleBlur}
          />
          {errors?.dimensions && dirty.current && (
            <Message type="error">
              {buildDimensionsError(errors?.dimensions)}
            </Message>
          )}
        </Col>
      </Row>
      {showDelete && (
        <Button className="delete-dimension" icon="delete" onClick={onDelete} />
      )}
    </Container>
  );
};

export default VolumeWeightInput;
