import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ReactHtmlParser from 'react-html-parser';
import { Skeleton } from 'antd';

import { faqService } from '../services/faq';
import Card from '../components/Card';

const StyledCard = styled(Card)`
  margin: 0 140px 36px 140px;
  padding: 30px;
  .faq {
    border-bottom: 1px solid #ccc;
    text-align: justify;
    white-space: pre-wrap;
  }
  .faq__question {
    font-family: AvenirLTStdHeavy;
    color: #000000;
    font-size: 20px;
  }
  .faq__answer {
    margin: 20px 0;
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }
`;

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const faqAxios = faqService.getFaqs();
    faqAxios.exec().then((resp) => {
      setLoading(false);
      setFaqs(resp?.data?.data || []);
    });
    return () => faqAxios.cancel();
  }, []);

  return (
    <StyledCard>
      {loading && <Skeleton title active paragraph={{ rows: 6 }} />}
      {!loading &&
        faqs.map((faq) => (
          <div className="faq">
            <p className="faq__question">{ReactHtmlParser(faq.question)}</p>
            <p className="faq__answer">{ReactHtmlParser(faq.answer)}</p>
          </div>
        ))}
    </StyledCard>
  );
};
export default Faqs;
