import { axiosRequest } from '../utils/request';
import { removeEmpty, queryString } from '../utils/formatString';

export const addAddress = (payload) => {
  const {
    locationName,
    country,
    city,
    stateAdr,
    postalCode,
    streetAddress,
    googleLocationId,
    pocName,
    pocPhoneNumber,
    pocEmail
  } = payload;
  return axiosRequest.post('/locations/add', {
    location_name: (locationName || '').trim(),
    country,
    city,
    state: stateAdr,
    postal_code: postalCode,
    street_address: streetAddress,
    google_location_id: googleLocationId,
    poc_name: pocName,
    poc_number: pocPhoneNumber,
    poc_email: pocEmail
  });
};

export const editAddress = (payload) => {
  const {
    locationId,
    locationName,
    country,
    city,
    stateAdr,
    postalCode,
    streetAddress,
    googleLocationId,
    pocName,
    pocPhoneNumber,
    pocEmail
  } = payload;
  return axiosRequest.post(`/locations/${locationId}`, {
    location_name: (locationName || '').trim(),
    country,
    city,
    state: stateAdr,
    postal_code: postalCode,
    street_address: streetAddress,
    google_location_id: googleLocationId,
    poc_name: pocName,
    poc_number: pocPhoneNumber,
    poc_email: pocEmail
  });
};

export const getAddress = (country, janioFl) => {
  return axiosRequest.get('locations', {
    params: { country, using_janio_fl_services: janioFl }
  });
};

export const deleteAddress = (id) => {
  const url = `/locations/${id}`;
  return axiosRequest.delete(url);
};

const getAddresses = async ({ page = 1, pageSize = 10 }) => {
  const params = removeEmpty({
    page,
    limit: pageSize
  });
  const queryParams = queryString(params);
  const url = `/locations?${queryParams}`;
  const response = await axiosRequest.get(url).exec();
  const { locations, total } = response.data.data;
  return {
    locations: locations.map((record, idx) => {
      return {
        ...record,
        index: idx
      };
    }),
    total
  };
};

export const addressService = {
  addAddress,
  getAddress,
  getAddresses,
  editAddress,
  deleteAddress
};
