import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import UnitInput from './UnitInput';
import FloatInput from './FloatInput';
import SearchInput from './SearchInput';

const StyledWeightInput = styled.div`
  .ant-input-group > div .ant-select,
  .ant-input-group > div .ant-select-selection {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 1;
  }
  .ant-input-group > div .ant-select-dropdown {
    width: 170px !important;
  }
  .ant-input-group > div {
    width: calc(40% + 1px);
    margin-left: -1px;
  }
  .ant-input-group > input {
    width: calc(60% + 1px);
    margin-left: -1px;
  }
`;

const WeightInput = ({
  unit = 'KG',
  type = false,
  value: inputValue,
  placeholder,
  onChange: handleChange = () => {},
  onBlur: handleBlur = () => {}
}) => {
  const [weight, setWeight] = useState(inputValue);
  const [weightUnit, setWeightUnit] = useState(unit);
  const [metricType, setMetricType] = useState(type);

  useEffect(() => {
    setWeightUnit(unit);
  }, [unit]);

  return (
    <StyledWeightInput>
      <UnitInput
        unit={weightUnit.toUpperCase()}
        units={['KG', 'LB']}
        value={inputValue}
        onChange={(selectedUnit) => {
          setWeightUnit(selectedUnit.toLowerCase());
          handleChange({
            unit: selectedUnit.toLowerCase(),
            type: metricType,
            weight
          });
        }}
      >
        {({ value, onChange }) => (
          <span className="ant-input-group ant-input-group-compact">
            <SearchInput
              value={type ? 'unit' : 'total'}
              items={[
                { label: 'Total Weight', value: 'total' },
                { label: 'Weight for one unit', value: 'unit' }
              ]}
              onChange={(selectedType) => {
                setMetricType(selectedType === 'unit');
                handleChange({
                  unit: weightUnit,
                  type: selectedType === 'unit',
                  weight
                });
              }}
            />
            <FloatInput
              value={value}
              placeholder={placeholder}
              onChange={(e) => {
                const weightValue = {
                  unit: weightUnit,
                  type: metricType,
                  weight: e.target.value
                };
                setWeight(e.target.value);
                handleChange(weightValue);
                onChange(e.target.value);
              }}
              onBlur={handleBlur}
            />
          </span>
        )}
      </UnitInput>
    </StyledWeightInput>
  );
};

export default WeightInput;
