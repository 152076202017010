/* eslint-disable eqeqeq */
import { createReducer } from '@reduxjs/toolkit';
import {
  fetchFormAddress,
  resetFormAddress,
  fetchFormAddressLoading,
  setFormAddressLocation,
  setFormAddress,
  setFormAddressDate,
  setFormAddressReadinessDate,
  setFormAddressCountry,
  setFormAddressPort
} from '../actions/shipmentForm';

const initialState = {
  origin: {
    country: undefined,
    loading: false,
    data: [],
    value: undefined,
    date: null
  },
  destination: {
    country: undefined,
    loading: false,
    data: [],
    value: undefined,
    date: null
  }
};

const getOtherSource = (source) => {
  return source === 'origin' ? 'destination' : 'origin';
};

const getLocation = (locations, data, value) => {
  if (value?.location_id || value?.street_address) {
    const inList = locations.find(
      (address) =>
        address.location_id === value?.location_id ||
        (address.street_address == value?.street_address &&
          address.city == value?.city &&
          address.state == value?.state &&
          address.postal_code == value?.postal_code &&
          address.poc_name == value?.poc_name &&
          address.poc_number == value?.poc_number &&
          address.poc_email == value?.poc_email)
    );
    if (!inList) {
      return [
        ...data,
        {
          location_id: value?.location_id,
          location_name: value?.location_name,
          street_address: value?.street_address,
          city: value?.city,
          state: value?.state,
          postal_code: value?.postal_code,
          poc_name: value?.poc_name,
          poc_number: value?.poc_number,
          poc_email: value?.poc_email
        }
      ];
    }
    return locations.map((address) => {
      if (inList.location_id === address.location_id) {
        address.location_id = value.location_id;
      }
      return address;
    });
  }
  return locations;
};

export default createReducer(initialState, {
  [fetchFormAddress](state, action) {
    const { locations } = action.payload.response.data.data;
    const { source, country, trigger } = action.payload;
    const { value, country: currentCountry } = state[source];
    if (currentCountry === country) {
      state[source].data = getLocation(
        [...locations],
        state[source].data,
        value
      );
    } else {
      state[source].data = locations;
    }
    const { value: otherValue, country: otherCountry } = state[
      getOtherSource(source)
    ];
    if (otherCountry === country && trigger) {
      state[getOtherSource(source)].data = getLocation(
        [...locations],
        state[getOtherSource(source)].data,
        otherValue
      );
    }
    state[getOtherSource(source)].loading = false;
    state[source].loading = false;
  },
  [fetchFormAddressLoading](state, action) {
    state[action.payload].loading = true;
  },
  [setFormAddressLocation](state, action) {
    const { source, data } = action.payload;
    state[source].value = { location_id: data };
  },
  [setFormAddressDate](state, action) {
    const { source, data } = action.payload;
    state[source].date = data;
  },
  [setFormAddressReadinessDate](state, action) {
    const { source, data } = action.payload;
    state[source].cargoReadinessDate = data;
  },
  [setFormAddressPort](state, action) {
    const { source, data } = action.payload;
    state[source].port = data;
  },
  [setFormAddressCountry](state, action) {
    const { source, data } = action.payload;
    state[source].country = data;
    state[source].data = [];
    state[source].loading = false;
    state[source].value = undefined;
    state[source].port = '';
  },
  [setFormAddress](state, action) {
    const { source, data } = action.payload;
    if (data.country !== state[source].country) {
      state[source].data = [];
      state[source].loading = false;
      if (data?.address && data.country) {
        state[source].country = data.country;
      }
    }
    if (data?.address && data?.street) {
      state[source].value = {
        location_id: data?.address,
        location_name: data?.locationName,
        street_address: data?.street,
        city: data?.city,
        state: data?.state,
        postal_code: data?.postalCode,
        poc_name: data?.pocName,
        poc_number: data?.pocNumber,
        poc_email: data?.pocEmail
      };
    }
    state[source].date = data.date;
  },
  [resetFormAddress](state, action) {
    state[action.payload] = initialState[action.payload];
  }
});
