import React from 'react';
import styled from '@emotion/styled';
import { get } from 'lodash';
import { formatAddress } from '../utils/formatString';
import Select from './Select';

const { Option } = Select;
const SelectStyle = styled(Select)`
  width: 100%;
`;

const SelectAddressComponent = ({
  options,
  limit,
  placeholder,
  textnotFoundContent,
  onChange,
  value,
  onFocus = () => {}
}) => (
  <SelectStyle
    showSearch
    value={value && value.location_id}
    placeholder={placeholder}
    optionFilterProp="children"
    notFoundContent={textnotFoundContent}
    onChange={onChange}
    onFocus={onFocus}
    filterOption={(input, option) =>
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  >
    {options
      .filter((item) => !limit || item.location_id !== limit)
      .map((item) => (
        <Option key={get(item, 'location_id')} value={get(item, 'location_id')}>
          {formatAddress(item)}
        </Option>
      ))}
  </SelectStyle>
);

export default SelectAddressComponent;
