import { createAction } from '@reduxjs/toolkit';
import { addressService } from '../services/address';

export const fetchFormAddress = createAction('shipmentForm/FETCH_ADDRESS');
export const fetchFormAddressLoading = createAction(
  'shipmentForm/FETCH_ADDRESS_LOADING'
);

export const setFormAddressPort = createAction('shipmentForm/SET_ADDRESS_PORT');
export const setFormAddressLocation = createAction(
  'shipmentForm/SET_ADDRESS_LOCATION'
);
export const setFormAddressDate = createAction('shipmentForm/SET_ADDRESS_DATE');
export const setFormAddressReadinessDate = createAction(
  'shipmentForm/SET_ADDRESS_READINESS_DATE'
);
export const setFormAddressCountry = createAction(
  'shipmentForm/SET_ADDRESS_COUNTRY'
);
export const setFormAddress = createAction('shipmentForm/SET_ADDRESS');
export const resetFormAddress = createAction('shipmentForm/RESET_ADDRESS');

export const getFormAddress = (
  source,
  country,
  service,
  trigger = false
) => async (dispatch) => {
  dispatch(fetchFormAddressLoading(source));
  addressService
    .getAddress(country, service)
    .exec()
    .then((data) => {
      dispatch(fetchFormAddress({ country, source, response: data, trigger }));
    });
};
