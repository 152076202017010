import React from 'react';
import styled from '@emotion/styled';
import ActionButton from './ActionButton';
// import MessageIcon from './MessageIcon';
// import { quoteService } from '../services/quotes';
// import { useAxiosRequest } from '../helpers/hooks';

const StyledActionBlock = styled.div`
  .message-icon {
    margin-left: 10px;
  }
`;

const ActionBlock = ({ status, trackingNumber, text }) => {
  // const [hasMessage, setHasMessage] = useState(false);
  // const [quoteMessage] = useAxiosRequest(
  //   quoteService.getQuoteMessage,
  //   trackingNumber
  // );

  // useEffect(() => {
  //   if (quoteMessage?.data?.readBy?.length < 1) {
  //     setHasMessage(true);
  //   }
  // }, [quoteMessage]);

  return (
    <StyledActionBlock>
      <ActionButton
        to={
          trackingNumber.startsWith('DFT')
            ? `/shipments/create-shipment/${trackingNumber}`
            : `/shipments/${trackingNumber}`
        }
        status={status}
      >
        {text}
      </ActionButton>
      {/* {hasMessage && (
        <MessageIcon type="message" status={status} className="message-icon" />
      )} */}
    </StyledActionBlock>
  );
};

export default ActionBlock;
