import { createReducer } from '@reduxjs/toolkit';
import {
  fetchRequestQuotesDetail,
  replaceDocument
} from '../actions/quoteDetail';

const initialState = {
  quote: null,
  shipping_log: [],
  charges: {},
  lookups: {}
};

export default createReducer(initialState, {
  [fetchRequestQuotesDetail](state, action) {
    state.quote = action.payload.quoteRequest;
  },
  [replaceDocument](state, action) {
    const { payload } = action;
    const docTypes = {};
    const publicDocuments = state.quote?.documents?.publicDocuments?.map(
      (doc) => {
        docTypes[doc.document_type] = 1;
        return doc.document_type === payload[0].document_type
          ? {
              ...doc,
              document_url: payload[0].document_url,
              document_title: payload[0].document_title
            }
          : doc;
      }
    );

    if (!docTypes[payload[0].document_type]) {
      if (!payload[0].document_is_private) {
        publicDocuments.push(payload[0]);
      }
    }
    state.quote.documents = { publicDocuments };
  }
});
