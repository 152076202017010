import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import Button from '../components/Button';
import ImgBackground from '../assets/images/login-background.png';
import ImgLogo from '../assets/images/logo.png';

const ErrorWrapper = styled.div`
  .login-background {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
  .login-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f4fdfc;
    z-index: 1;
  }
`;
const ErrorContent = styled.div`
  z-index: 3;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: 5%;
  img {
    width: 100px;
    padding: 0 0 40px 0;
    margin-top: 40px;
  }
  p {
    font-family: AvenirLTStdBook;
    font-size: 24px;
    color: #000;
  }
  div {
    padding-top: 20px;
  }
`;
const Error = (props) => {
  let errorCode = props?.match?.params?.errorCode;
  let error = '';
  switch (errorCode) {
    case '500':
      error = 'Our server is unavailable right now';
      break;
    case '403':
      error = "Sorry, you don't have the permission to go to this page.";
      break;
    default:
      error = 'Oops, there is no such page!';
      errorCode = '404';
  }
  return (
    <ErrorWrapper>
      <img className="login-background" alt="backgound" src={ImgBackground} />
      <div className="login-overlay" />
      <ErrorContent>
        <img alt="logo" src={ImgLogo} />
        <p>{error}</p>
        <p>{`(Error ${errorCode})`}</p>
        <div>
          <Link to="/">
            <Button type="primary" size="large">
              Return to Janio B2B
            </Button>
          </Link>
        </div>
      </ErrorContent>
    </ErrorWrapper>
  );
};

export default Error;
