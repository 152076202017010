import { AIRPORTS, SEAPORTS } from '../constants/ports';
import { ALL_COUNTRIES as COUNTRIES } from '../constants/countries';

const getPortsList = (shipmentMode, country) => {
  if (country) {
    let seaPorts = [];
    let airPorts = [];
    let ports = [];
    let portsList = [];
    const countryCode = COUNTRIES.find((obj) => obj.value === country)
      ?.countryCode;
    switch (shipmentMode) {
      case 'Sea':
        ports = SEAPORTS.filter(
          (seaport) => seaport.unlocs[0].substr(0, 2) === countryCode
        );
        ports = [...new Set(ports.map((port) => port.name))];
        portsList = ports.map((name) => ({
          label: name,
          value: name
        }));
        portsList = portsList.filter(
          (port) => port.label !== '' || port.value !== ''
        );
        return portsList || [];
      case 'Air':
        ports = AIRPORTS.filter(
          (airport) => airport.countryCode === countryCode
        );
        ports = [...new Set(ports.map((port) => port.airportName))];
        portsList = ports.map((name) => ({
          label: name,
          value: name
        }));
        portsList = portsList.filter(
          (port) => port.label !== '' || port.value !== ''
        );
        return portsList || [];
      case 'All':
        seaPorts = SEAPORTS.filter(
          (seaport) => seaport.unlocs[0].substr(0, 2) === countryCode
        );
        seaPorts = [...new Set(seaPorts.map((port) => port.name))];

        airPorts = AIRPORTS.filter(
          (airport) => airport.countryCode === countryCode
        );
        airPorts = [...new Set(airPorts.map((port) => port.airportName))];
        ports = [...airPorts, ...seaPorts];
        portsList = ports.map((name) => ({
          label: name,
          value: name
        }));
        portsList = portsList.filter(
          (port) => port.label !== '' || port.value !== ''
        );
        return portsList || [];
      default:
        return [];
    }
  } else {
    return [];
  }
};

export default getPortsList;
