import * as React from 'react';
import { Field } from 'formik';
import LocationSearchInput from '../LocationSearchInput';
import Message from '../Message';

const FormLocationSearchInput = ({
  name,
  validate,
  onChange = () => {},
  validateDirty = true,
  validateState = true,
  ...restProps
}) => {
  const showError = (touched, error) => {
    if (validateDirty) {
      return <>{error && touched && <Message type="error">{error}</Message>}</>;
    }
    return <>{error && <Message type="error">{error}</Message>}</>;
  };
  return (
    <Field name={name} validate={validate}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched },
        meta
      }) => (
        <>
          <LocationSearchInput
            value={value}
            onChange={(selectedValue) => {
              if (validateState) {
                setFieldValue(name, selectedValue);
                setFieldTouched(name, true);
                onChange(selectedValue);
              }
            }}
            onBlur={() => {
              if (validateState) {
                setFieldTouched(name, true);
              }
            }}
            {...restProps}
          />
          {showError(meta.touched, meta.error)}
        </>
      )}
    </Field>
  );
};

export default FormLocationSearchInput;
