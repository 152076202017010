import React from 'react';
import styled from '@emotion/styled';
import LocationInput from './LocationInput';
import Input from './Input';

const StyledEditableLocationInput = styled.div``;

const EditableLocationInput = ({ location, onSubmit = () => {}, ...props }) => {
  return (
    <StyledEditableLocationInput>
      <LocationInput location={location} onSubmit={onSubmit}>
        {({ showModal }) => <Input {...props} onClick={showModal} readOnly />}
      </LocationInput>
    </StyledEditableLocationInput>
  );
};

export default EditableLocationInput;
