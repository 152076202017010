import { createReducer } from '@reduxjs/toolkit';
import {
  fetchAddresses,
  fetchAddressesLoading,
  setPageFilter,
  setPageSizeFilter,
  resetFilters
} from '../actions/addressList';

const initialState = {
  addresses: {
    data: [],
    total: null,
    loading: false,
    filtered: false
  },
  filters: {
    page: 1,
    records: '20'
  }
};

export default createReducer(initialState, {
  [fetchAddresses](state, action) {
    const { locations, total } = action.payload;
    state.addresses = {
      data: locations,
      total,
      loading: false
    };
  },
  [fetchAddressesLoading](state) {
    state.addresses.loading = true;
  },
  [setPageFilter](state, action) {
    state.filters.page = action.payload;
  },
  [setPageSizeFilter](state, action) {
    state.filters.page = 1;
    state.filters.records = action.payload;
  },
  [resetFilters](state) {
    state.filters = initialState.filters;
  }
});
