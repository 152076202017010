import {
  GET_DATA_QUOTE_DETAIL,
  GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG,
  GET_DATA_QUOTE_DETAIL_CHARGES,
  // UPDATE_DOCUMENT,
  REPLACE_DOCUMENT,
  RESET_DATA_QUOTE_DETAIL
} from '../actions/types';

import {
  changeQuoteStatus,
  updateQuoteAddressView
} from '../actions/quoteDetail';

const initialState = {};

export default (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG:
    case GET_DATA_QUOTE_DETAIL_CHARGES:
      return {
        ...state,
        ...payload
      };
    case GET_DATA_QUOTE_DETAIL: {
      const { quote } = payload;
      return {
        ...state,
        ...payload,
        quote: {
          ...quote,
          documents: {
            publicDocuments: quote.documents.publicDocuments.map((document) => {
              return {
                ...document,
                document_url:
                  document &&
                  document.document_url &&
                  document.document_url.replace('http://', 'https://')
              };
            })
          }
        }
      };
    }
    // TODO: change this to createReducer form so that we don't need to do .toString
    case changeQuoteStatus.toString():
      return {
        ...state,
        quote: {
          ...state.quote,
          quote_status: payload
        }
      };
    // case UPDATE_DOCUMENT:
    //   return {
    //     ...state,
    //     quote: {
    //       ...state.quote,
    //       documents: { publicDocuments: payload }
    //     }
    //   };
    case REPLACE_DOCUMENT: {
      const docTypes = {};
      const publicDocuments = state.quote.documents.publicDocuments?.map(
        (doc) => {
          docTypes[doc.document_type] = 1;
          return doc.document_type === payload[0].document_type
            ? {
                ...doc,
                document_url: payload[0].document_url,
                document_title: payload[0].document_title
              }
            : doc;
        }
      );

      if (!docTypes[payload[0].document_type]) {
        if (!payload[0].document_is_private) {
          publicDocuments.push(payload[0]);
        }
      }

      return {
        ...state,
        quote: {
          ...state.quote,
          documents: { publicDocuments }
        }
      };
    }
    case updateQuoteAddressView.toString():
      return {
        ...state,
        quote: {
          ...state.quote,
          [payload.address]: {
            ...state.quote[payload.address],
            ...payload.details
          }
        }
      };
    case RESET_DATA_QUOTE_DETAIL:
      return initialState;
    default:
      return state;
  }
};
