import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import quoteDetail from './quoteDetail';
import shipmentList from './shipmentList';
import addressList from './addressList';
import shipmentForm from './shipmentForm';
import quoteRequestList from './quoteRequestList';
import quoteRequestDetails from './quoteRequestDetails';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    quoteDetail,
    quoteRequestList,
    shipmentList,
    addressList,
    shipmentForm,
    quoteRequestDetails
  });
export default createRootReducer;
