import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button } from 'antd';
import validator from 'validator';
import {
  TEXT_VALID,
  TEXT_MISSING_FIELD,
  TEXT_ONLY_NUMBER,
  TEXT_LIMIT_50,
  TEXT_MAX_2_DECIMAL,
  TEXT_MIN_VOLUME,
  TEXT_MAX_VOLUME,
  TEXT_MIN_WEIGHT_KG,
  TEXT_MAX_WEIGHT_KG,
  TEXT_MIN_WEIGHT_LB,
  TEXT_MAX_WEIGHT_LB
} from '../constants/message';
import { invalidCharacter } from '../utils/formatString';
import { isMeasureUnitValid } from '../helpers/validate';
import Input from './Input';

const ContainerInner = styled.div`
  background: #05059312 0% 0% no-repeat padding-box;
  padding: 30px 45px;
  width: fit-content;
  position: relative;
  margin-bottom: 20px;
  .group-form {
    width: 500px;
    float: left;
  }
  .buton-icon {
    position: absolute;
    right: 20px;
    bottom: 37px;
    :hover {
      color: #050593;
    }
  }
`;

const checkValid = (_newState) =>
  !!(
    _newState.name &&
    !invalidCharacter(_newState.name) &&
    _newState.totalVolume &&
    _newState.totalWeight &&
    validator.isFloat(_newState.totalVolume, { min: 0.1, max: 43 }) &&
    validator.isDecimal(_newState.totalVolume, { decimal_digits: '0,2' }) &&
    isMeasureUnitValid(_newState.totalWeight, _newState.isKg, 34000, 75000)
  );

const TotalWeight = ({
  triggerData = () => {},
  _index,
  data,
  handleDelete
}) => {
  const updateState = (newState) => {
    const _newState = {
      ...data,
      ...newState
    };
    _newState.status = checkValid(_newState);
    triggerData(_newState);
    return _newState;
  };

  const { name = '', totalVolume = '', totalWeight = '', isKg = true } = data;

  const [err, setErr] = useState({
    name: '',
    totalVolume: '',
    weitotalWeightght: ''
  });

  const updateStateInside = (newState) => {
    setErr((prevState) => ({
      ...prevState,
      ...newState
    }));
  };

  const validatorNumber = (target, prop, text) => {
    if (validator.isEmpty(target)) {
      updateStateInside({
        [prop]: `${TEXT_MISSING_FIELD} ${text}.`
      });
      updateState({ status: false });
    } else if (!validator.isNumeric(target) || target < 0) {
      updateStateInside({
        [prop]: TEXT_ONLY_NUMBER
      });
      updateState({ status: false });
    } else if (!validator.isDecimal(target, { decimal_digits: '0,2' })) {
      updateStateInside({
        [prop]: TEXT_MAX_2_DECIMAL
      });
      updateState({ status: false });
    } else if (
      prop === 'totalVolume' &&
      !validator.isFloat(target, { min: 0.1 })
    ) {
      updateStateInside({
        [prop]: TEXT_MIN_VOLUME
      });
      updateState({ status: false });
    } else if (
      prop === 'totalVolume' &&
      !validator.isFloat(target, { max: 43 })
    ) {
      updateStateInside({
        [prop]: TEXT_MAX_VOLUME
      });
      updateState({ status: false });
    } else if (
      prop === 'totalWeight' &&
      isKg &&
      !validator.isFloat(target, { min: 0.1 })
    ) {
      updateStateInside({
        [prop]: TEXT_MIN_WEIGHT_KG
      });
      updateState({ status: false });
    } else if (
      prop === 'totalWeight' &&
      isKg &&
      !validator.isFloat(target, { max: 34000 })
    ) {
      updateStateInside({
        [prop]: TEXT_MAX_WEIGHT_KG
      });
      updateState({ status: false });
    } else if (
      prop === 'totalWeight' &&
      !isKg &&
      !validator.isFloat(target, { min: 0.1 })
    ) {
      updateStateInside({
        [prop]: TEXT_MIN_WEIGHT_LB
      });
      updateState({ status: false });
    } else if (
      prop === 'totalWeight' &&
      !isKg &&
      !validator.isFloat(target, { max: 75000 })
    ) {
      updateStateInside({
        [prop]: TEXT_MAX_WEIGHT_LB
      });
      updateState({ status: false });
    } else {
      updateStateInside({
        [prop]: ''
      });
    }
  };

  const validatorName = () => {
    if (name && invalidCharacter(name)) {
      updateStateInside({
        name: TEXT_VALID
      });
      updateState({ status: false });
    } else if (!validator.isLength(name, { min: 0, max: 50 })) {
      updateStateInside({
        name: TEXT_LIMIT_50
      });
      updateState({ status: false });
    } else if (!name) {
      updateStateInside({
        name: `${TEXT_MISSING_FIELD} Package Name`
      });
      updateState({ status: false });
    } else {
      updateStateInside({
        name: ``
      });
    }
  };

  return (
    <ContainerInner>
      <Row className="">
        <Col className="input-wrapper">
          <p>Package Name</p>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => updateState({ name: e.target.value })}
            onBlur={() => validatorName()}
          />
          {err.name ? <p className="msg-error">{err.name}</p> : ''}
        </Col>
        <Col className="input-wrapper">
          <p>Total Volume (CBM)</p>
          <Input
            placeholder="How much..."
            value={totalVolume}
            onChange={(e) => updateState({ totalVolume: e.target.value })}
            onBlur={() =>
              validatorNumber(totalVolume, 'totalVolume', 'Total Volume')
            }
          />
          <p className="msg-error">{err.totalVolume}</p>
        </Col>
        <Col className="input-wrapper">
          <p>
            Total Weight
            <span className="unit-wrapper">
              <span
                aria-hidden
                role="button"
                className={`unit-text ${!isKg && 'unChecked'}`}
                onClick={() => updateState({ isKg: true })}
              >
                KG
              </span>
              <span className="devide">|</span>
              <span
                aria-hidden
                role="button"
                className={`unit-text ${isKg && 'unChecked'}`}
                onClick={() => updateState({ isKg: false })}
              >
                LB
              </span>
            </span>
          </p>
          <Input
            placeholder="How much..."
            value={totalWeight}
            onChange={(e) => updateState({ totalWeight: e.target.value })}
            onBlur={() =>
              validatorNumber(totalWeight, 'totalWeight', 'Total Weight')
            }
          />
          <p className="msg-error">{err.totalWeight}</p>
        </Col>
      </Row>
      {_index !== 0 && (
        <Button
          className="buton-icon"
          icon="delete"
          onClick={() => handleDelete()}
        />
      )}
    </ContainerInner>
  );
};

export default TotalWeight;
