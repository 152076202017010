import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const StyledActionButton = styled(Link)`
  font-family: AvenirLTStdBook;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  background-color: transparent;
  border-color: transparent;

  color: #050593;
  text-decoration: none;

  &:hover {
    color: #050593;
  }
`;

const actionClassName = {
  'Awaiting Janio': 'none',
  None: 'none',
  'Shipment Confirmed': 'none',
  'Scheduled for Pickup': 'none',
  'Scheduled for Dropoff': 'none',
  'Rescheduled for Pickup': 'none',
  'Rescheduled for Dropoff': 'none',
  'Reached Origin Warehouse': 'none',
  'Received By Airlines': 'none',
  'Pending Custom Clearance': 'none',
  'Out for Delivery': 'none',
  'Delivered without POD': 'none',
  Delivered: 'none',
  Cancelled: 'none'
};

const ActionButton = ({ children, status, ...props }) => {
  return (
    <StyledActionButton className={actionClassName[children]} {...props}>
      {children}
    </StyledActionButton>
  );
};

export default ActionButton;
