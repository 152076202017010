import React, { useRef, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
  Modal as AntModal,
  Row,
  Col,
  Divider,
  Button as AntButton,
  message
} from 'antd';
import Input from './Input';
import Button from './Button';
import { quoteService } from '../services/quotes';
import { validateCommaSeparatedEmail } from '../utils/formatString';

const StyledModal = styled.div`
  .ant-modal-body {
    padding: 35px 40px 0 35px;
  }
  .ant-modal-footer {
    border-top: none;
    padding: 20px 40px 35px 40px;
    button {
      width: 107px;
      height: 35px;
    }
  }
  .ant-divider-horizontal {
    margin-top: 0px;
    margin-bottom: 15px;
  }
`;
const NavButtons = styled(Row)`
  display: flex;
  padding: 15px;
  justify-content: flex-end;
`;
const Title = styled.h5`
  color: #000000;
`;
const ErrorTitle = styled.p`
  color: #cf4242;
`;
const EmailRow = styled(Row)`
  color: #000000;
  margin: 10px 0;
`;
const UnsubscribeButton = styled(AntButton)`
  border-radius: 0;
  color: #050593;
  font-family: AvenirLTStdBook;
  border: 1px solid #050593;
  &:hover,
  &:focus,
  &:active {
    color: #050593;
    border: 1px solid #050593;
  }
`;
const TrackingUpdateModal = ({ getContainer, ...props }) => {
  const node = useRef();
  return (
    <StyledModal ref={node}>
      <AntModal getContainer={() => node.current} {...props} />
    </StyledModal>
  );
};

const TrackingUpdatesModal = ({
  isVisble,
  setIsVisible,
  handleEmailInputChange,
  emailInput,
  handleSubscribe,
  shipmentId
}) => {
  const [emailList, setEmailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchEmails = async () => {
    return quoteService
      .getShipmentSubscribedEmails(shipmentId)
      .exec()
      .then((resp) => {
        const data = resp?.data?.data;
        const emails = data.map((emailData) => emailData.email);
        setEmailList(emails);
      });
  };
  useEffect(() => {
    quoteService
      .getShipmentSubscribedEmails(shipmentId)
      .exec()
      .then((resp) => {
        const data = resp?.data?.data;
        const emails = data.map((emailData) => emailData.email);
        setEmailList(emails);
      });
  }, [isVisble, shipmentId]);
  const handleUnsubscribe = (email) => {
    setIsLoading(true);
    quoteService
      .unsubscribeShipmentEmail(shipmentId, email)
      .exec()
      .then(() => {
        fetchEmails();
      })
      .then(() => {
        setIsLoading(false);
        message.success('Email Unsubscribed Sucessfully');
      });
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    const isValid = validateCommaSeparatedEmail(value);
    setIsError(!isValid);
    handleEmailInputChange(value);
  };
  const handleSubscribeSubmit = () => {
    if (emailInput) {
      handleSubscribe();
      setIsVisible(false);
    } else {
      setIsError(true);
    }
  };
  return (
    <TrackingUpdateModal
      className="trackingUpdateModal"
      visible={isVisble}
      okText="Subscribe"
      footer={[]}
      closable={false}
    >
      <h3>Subscribe to Delivery Updates</h3>
      <p>Receive delivery updates by email</p>
      <Input
        placeholder="name1@email.com, name2@email.com, ..."
        block
        onChange={handleEmailChange}
      />
      {isError && <ErrorTitle>Please enter a valid email address </ErrorTitle>}
      <NavButtons>
        <Col span={5}>
          <Button
            key="cancel"
            type="secondary"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </Button>
        </Col>
        <Col span={5}>
          <Button
            key="subscribe"
            disabled={isError}
            onClick={handleSubscribeSubmit}
          >
            Subscribe
          </Button>
        </Col>
      </NavButtons>
      <Divider />
      <Title>Subscribed Emails</Title>
      {emailList.map((email) => (
        <EmailRow key={email}>
          <Col span={18}>{email}</Col>
          <Col span={6}>
            <UnsubscribeButton
              size="small"
              loading={isLoading}
              onClick={() => handleUnsubscribe(email)}
            >
              Unsubscribe
            </UnsubscribeButton>
          </Col>
        </EmailRow>
      ))}
    </TrackingUpdateModal>
  );
};

export default TrackingUpdatesModal;
