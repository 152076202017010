import React from 'react';
import { Field } from 'formik';
import PickupDeliveryDetails from '../PickupDeliveryDetails';

const FormPickupDeliveryDetails = ({
  name,
  validate,
  onChange = () => {},
  onBlur = () => {},
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <>
          <PickupDeliveryDetails
            value={value}
            onChange={(values, isError) => {
              setFieldValue(name, values, false);
              setFieldValue(`internal.${name}`, isError);
              onChange(values);
            }}
            onBlur={(values, isError) => {
              setFieldTouched(name, true, false);
              setFieldValue(`internal.${name}`, isError);
              onBlur(values);
            }}
            {...restProps}
          />
        </>
      )}
    </Field>
  );
};

export default FormPickupDeliveryDetails;
