/* eslint-disable no-restricted-properties */
import { get } from 'lodash';
import XRegExp from 'xregexp';
import moment from 'moment';

export const formatAddress = (address) =>
  get(address, 'location_name')
    ? `${address.location_name}, ${address.street_address}, ${address.city}, ${address.state}, ${address.postal_code}`
    : `${address.street_address}, ${address.city}, ${address.state}, ${address.postal_code}`;

export const invalidCharacter = (text) => {
  const pattern = XRegExp(
    '^[a-zA-Z0-9~!@#$%^&*()_+`1234567890\\-=[\\]\\{};\':",./\\\\<>|?\\s]+$',
    'i'
  );
  return !pattern.test(text);
};

export const invalidUnicodeCharacter = (text) => {
  const pattern = XRegExp(
    '^[\\pLa-zA-Z0-9~!@#$%^&*()_+`1234567890\\-=[\\]\\{};\':",./\\\\<>|?\\s]+$',
    'i'
  );
  return !pattern.test(text);
};

export const removeEmpty = (obj) => {
  const cleanObj = { ...obj };
  Object.keys(cleanObj).forEach(
    (key) => !cleanObj[key] && delete cleanObj[key]
  );
  return cleanObj;
};

export const formatToISOString = (dateMoment) => {
  return dateMoment === null ? null : moment(dateMoment).toISOString();
};

export const queryString = (obj) => {
  const params = removeEmpty(obj);
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const toTitleCase = (text) => {
  let wordsArray = text.toLowerCase().split(' ');
  wordsArray = wordsArray.filter(function() {
    return true;
  });
  console.log(wordsArray);
  const capsArray = wordsArray.map((word) => {
    if (word.length > 0) return word[0].toUpperCase() + word.slice(1);
    return word;
  });
  return capsArray.join(' ');
};
export const handleAbbrevCase = (text) => {
  const titleCaseAbbrv = text.replace(/\(.*\)/g, (str) => {
    return str.toUpperCase();
  });
  return titleCaseAbbrv;
};

export const removeNonEnglishCharacters = (text) => {
  const newText = text.replace(/[^\x20-\x7E]+/g, '0');
  return newText;
};
export const formatShipmentModeValue = (mode, value) =>
  mode === 'Sea' ? parseFloat(value || 0) : Math.ceil(parseFloat(value || 0));

const preciseRound = (num, dec) => {
  if (typeof num !== 'number' || typeof dec !== 'number') return false;

  const num_sign = num >= 0 ? 1 : -1;

  return (
    Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)
  );
};

export const toFixedDown = (num, digits) => {
  const value = preciseRound(num, digits);
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`);
  const m = value.toString().match(re);
  return m ? parseFloat(m[1]) : value.valueOf();
};
export const validateCommaSeparatedEmail = (text) => {
  const pattern = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,10})(,\s?)*([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,10})*$/g;
  return pattern.test(text);
};
