import React from 'react';
import styled from '@emotion/styled';

const StyledQuoteDetailsTabs = styled.div`
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    padding: 10px 16px;
    @media (min-width: 1600px) {
      padding: 10px 32px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
    margin-top: 15px;
    margin-bottom: 25px;
  }
`;

// TODO: maintain state transition here like ops
const QuoteDetailsTabs = ({ children }) => {
  return <StyledQuoteDetailsTabs>{children}</StyledQuoteDetailsTabs>;
};

export default QuoteDetailsTabs;
