import React, { forwardRef } from 'react';
import { Button as AntButton } from 'antd';
import styled from '@emotion/styled';

const StyledButton = styled(AntButton)`
  span {
    padding-top: 3px;
  }
  &.ant-btn-lg {
    /* Font alignment adjustment for AvenirLTStdBook */
    padding: 8px 25px 6px 25px;
    span {
      padding-top: 0;
    }
  }

  &.ant-btn-sm {
    font-size: 14px;
  }

  &.ant-btn-secondary {
    border: 1px solid #050593;
    color: #050593;
    font-family: AvenirLTStdBook;
    font-size: 16px;
  }

  &.ant-btn-link {
    color: #050593;
    span {
      text-decoration: underline;
    }
  }

  &.ant-btn-primary {
    background: #050593 0% 0% no-repeat padding-box;
    border-radius: 4px;
    border: 1px solid #050593;
    font-family: AvenirLTStdBook;
    font-size: 16px;
    color: #ffffff;
    &:hover,
    &:focus,
    &:active {
      background-color: #050593;
      color: #fff;
      border: 1px solid #050593;
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background-color: #9b9bd4;
      color: #fff;
      border: 1px solid #9b9bd4;
    }
  }
`;

const Button = (props, ref) => {
  const { children, ...rest } = props;
  return (
    <StyledButton ref={ref} {...{ type: 'primary', ...rest }}>
      {props.children}
    </StyledButton>
  );
};

export default forwardRef(Button);
