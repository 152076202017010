import { createAction } from '@reduxjs/toolkit';
import { addressService } from '../services/address';

export const fetchAddresses = createAction('addresses/FETCH_DATA');
export const fetchAddressesLoading = createAction(
  'shipments/FETCH_DATA_LOADING'
);

export const setPageFilter = createAction('addressFilter/SET_PAGE');
export const setPageSizeFilter = createAction('addressFilter/SET_PAGE_SIZE');

export const resetFilters = createAction('addressFilter/RESET_FILTERS');

export const getAddresses = (params = {}) => async (dispatch) => {
  dispatch(fetchAddressesLoading());
  addressService.getAddresses(params).then((data) => {
    dispatch(fetchAddresses(data));
  });
};
