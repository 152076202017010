import { toFixedDown } from '../utils/formatString';

const convertDimensionsToCM = (data) => {
  if (!data || data.isCm) {
    return {
      height: parseFloat(data.height || 0),
      length: parseFloat(data.length || 0),
      width: parseFloat(data.width || 0),
      isCm: true
    };
  }
  return {
    height: parseFloat(data.height || 0) * 2.54,
    length: parseFloat(data.length || 0) * 2.54,
    width: parseFloat(data.width || 0) * 2.54,
    isCm: false
  };
};

const convertDimensionsToKG = (data) => {
  if (!data || data.isKg) {
    return {
      amount: parseFloat(data.amount || 0),
      isKg: true
    };
  }
  return {
    amount: parseFloat(data.amount || 0) * 0.453592,
    isKg: false
  };
};

const getMetricType = (item) => {
  return [item.weightType, item.volumeType];
};

// make sure second parameter isIndividual is taken into consideration
export const calculateTotalChargableWeight = (data) => {
  let totalChargableWeight = 0;

  data.forEach((item) => {
    totalChargableWeight += Number(item.chargeableWeight);
  });

  return totalChargableWeight;
};

export const calculateTotalVoume = (data) => {
  let totalVolume = 0;
  data.forEach((item) => {
    // eslint-disable-next-line no-unused-vars
    const [_, volumeType] = getMetricType(item);
    if (volumeType) {
      const dimensions = convertDimensionsToCM(item.dimensions);
      const volume =
        (item.amount *
          dimensions.length *
          dimensions.width *
          dimensions.height) /
        1000000;
      totalVolume += volume;
    } else {
      totalVolume += parseFloat(item.dimensions.amount || 0);
    }
  });
  return toFixedDown(parseFloat(totalVolume), 3);
};

export const calculateTotalVolumetricWeight = (data) => {
  let totalVolumetricWeight = 0;
  data.forEach((item) => {
    // eslint-disable-next-line no-unused-vars
    const [_, volumeType] = getMetricType(item);
    if (volumeType) {
      const dimensions = convertDimensionsToCM(item.dimensions);
      const itemVW =
        (item.amount *
          dimensions.length *
          dimensions.width *
          dimensions.height) /
        5000;
      totalVolumetricWeight += itemVW;
    } else {
      totalVolumetricWeight += parseFloat(item.dimensions.amount || 0) / 0.005;
    }
  });
  return totalVolumetricWeight;
};

export const calculateTotalWeight = (data) => {
  let totalWeight = 0;
  data.forEach((item) => {
    const weight = convertDimensionsToKG(item.weight);
    const [weightType] = getMetricType(item);
    if (weightType) {
      totalWeight += item.amount * weight.amount;
    } else {
      totalWeight += weight.amount;
    }
  });
  return totalWeight;
};
