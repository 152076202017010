import React from 'react';
import moment from 'moment';
import styled from '@emotion/styled';
import { Row, Col } from 'antd';

const TimeText = styled.span`
  font-family: AvenirLTStdBookOblique;
  color: #000000;
  font-size: 12px;
  float: right;
  margin-top: 3px;
`;
const BoldLog = styled.span`
  font-family: AvenirLTStdHeavy;
`;
const PublicLog = styled.p`
  margin: 0;
  color: #000000;
`;
const ShipmentLog = ({ log }) => {
  const created_at = <TimeText>{moment(log.created_at).format('LT')}</TimeText>;
  let { publicLog } = log;
  if (log.type) {
    const publicLogItems = publicLog.split(' BY ');
    if (publicLogItems.length > 1) {
      publicLog = (
        <>
          <BoldLog>{publicLogItems[0]}</BoldLog>
          {` by ${publicLogItems[1]}`}
        </>
      );
    } else {
      publicLog = <BoldLog>{publicLog}</BoldLog>;
    }
  }
  return (
    <>
      {log.publicLog && (
        <Row>
          <PublicLog>
            <Col xs={4} sm={8} md={11} lg={16} xl={20}>
              {publicLog}
            </Col>
            <Col span={3}>{created_at}</Col>
          </PublicLog>
        </Row>
      )}
    </>
  );
};
export default ShipmentLog;
