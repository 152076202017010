import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Skeleton, Tabs } from 'antd';

import { QuoteDetails } from './QuoteDetails';
import RequestQuoteDocuments from './RequestQuoteDocuments';

const { TabPane } = Tabs;

const StyledQuoteDetailTabs = styled.div`
  .ant-tabs {
    overflow: visible !important;
  }
  .ant-tabs-tab {
    font-family: AvenirLTStdBook;
    font-size: 16px;
    @media (min-width: 1600px) {
      padding: 10px 32px;
    }
  }
  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #707070;
  }
  .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-nav .ant-tabs-tab-active:hover {
    font-family: AvenirLTStdHeavy;
    color: #050593;
  }
  .ant-tabs-ink-bar {
    background-color: #050593;
  }
  .ant-tabs-bar {
    border-bottom-color: #1fceca;
  }
`;

let StateTabs = {
  QUOTE_REQUEST: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  APPROVED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  REJECTED: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  WIN: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DETAILS'
  },
  LOSS: {
    tabs: ['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'],
    activeTab: 'SHIPMENT_DETAILS'
  }
};

StateTabs = {
  ...StateTabs
};

const TabComponent = ({ component: Component, ...props }) => {
  return <Component {...props} />;
};

const RequestQuoteDetailTabs = ({
  quoteStatus,
  quoteId,
  onLoading = () => {}
}) => {
  const [loading, setLoading] = useState(true);
  const [tabKey, setTabKey] = useState(Date.now());
  const [currentTab, setCurrentTab] = useState(() => {
    return StateTabs[quoteStatus]?.activeTab || 'SHIPMENT_DETAILS';
  });

  useEffect(() => {
    if (quoteStatus) {
      setCurrentTab(StateTabs[quoteStatus]?.activeTab || 'SHIPMENT_DETAILS');
    }
  }, [quoteStatus]);

  const tabList = {
    SHIPMENT_DETAILS: {
      title: 'Details',
      component: QuoteDetails
    },
    SHIPMENT_DOCUMENT: {
      title: 'Documents',
      component: RequestQuoteDocuments
    }
  };

  // TODO: make this more generic so that any tab can inform parent that it is still loading

  useEffect(() => {
    if (quoteStatus) {
      setLoading(false);
      onLoading(false);
    } else {
      setLoading(true);
      onLoading(true);
    }
  }, [quoteStatus, onLoading]);

  useEffect(() => {
    if (['SHIPMENT_DETAILS', 'SHIPMENT_DOCUMENT'].includes(currentTab)) {
      // hack
      setTimeout(() => {
        setTabKey(`${Date.now()}`);
      }, 500);
    }
  }, [currentTab]);

  return (
    <StyledQuoteDetailTabs>
      {quoteStatus && (
        <Tabs
          key={tabKey}
          activeKey={currentTab}
          onTabClick={(key) => setCurrentTab(key)}
          style={{ display: loading ? 'none' : 'block' }}
          animated={false}
        >
          {StateTabs[quoteStatus]?.tabs.map((tab) => (
            <TabPane tab={tabList[tab].title} key={tab} forceRender>
              <TabComponent
                component={tabList[tab].component}
                quoteId={quoteId}
              />
            </TabPane>
          ))}
        </Tabs>
      )}
      {loading && <Skeleton title active paragraph={{ rows: 5 }} />}
    </StyledQuoteDetailTabs>
  );
};

export default RequestQuoteDetailTabs;
