import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import {
  calculateTotalChargableWeight,
  calculateTotalVoume,
  calculateTotalWeight,
  calculateTotalVolumetricWeight
} from '../../helpers/calculate';

const StyledTotalsDisplay = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  h5 {
    font-family: AvenirLTStdHeavy;
    font-size: 16px;
    color: #000000;
    margin-bottom: 10px;
  }
  & > ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    margin-left: -10px;
    margin-right: -10px;
    & > li {
      width: ${(props) => props.width || '33.33%'};
      // flex-grow: ${(props) => props.grow || '1'};
      padding: 0 10px;
      @media (min-width: 1600px) {
        width: 25%;
      }
    }
    & > li em {
      font-family: AvenirLTStdHeavy;
      font-size: 14px;
      color: #707070;
      display: block;
      font-style: normal;
      margin-bottom: 5px;
    }
    & > li .editable-control {
      margin-bottom: 20px;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #000000;
    }
  }
`;

const TotalsDisplay = ({
  packages = [],
  packageTypes = [],
  modeOfShipment
}) => {
  const [packageTypeUnits, setPackageTypeUnits] = useState({});
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    const typeUnits = {};
    if (packageTypes.length > 0) {
      packages.forEach((packageItem) => {
        const packageType = packageTypes.find(
          (x) => x.key === packageItem.type
        );
        if (packageType) {
          typeUnits[packageType.value.toLowerCase()] =
            parseInt(typeUnits[packageType.value.toLowerCase()] || 0, 10) +
            parseInt(packageItem.amount || 0, 10);
        }
      });

      const metricObj = {
        totalWeight: `${Math.ceil(
          parseFloat(calculateTotalWeight(packages))
        )} KG`,
        volume:
          modeOfShipment === 'Sea'
            ? `${calculateTotalVoume(packages)} CBM`
            : 'None',
        volumetricWeight:
          modeOfShipment === 'Sea'
            ? 'None'
            : ` ${Math.ceil(calculateTotalVolumetricWeight(packages))} KG`,
        chargeableWeightVolume:
          modeOfShipment === 'Sea'
            ? calculateTotalVoume(packages)
            : calculateTotalChargableWeight(packages)
      };
      setMetrics(metricObj);
      setPackageTypeUnits(typeUnits);
    }
  }, [packages, packageTypes, modeOfShipment]);

  const getPackageTypeUnits = () => {
    if (Object.keys(packageTypeUnits).length < 1) {
      return null;
    }
    const getPluralType = (type) => {
      const pluralTypeMap = {
        box: 'boxes'
      };
      if (pluralTypeMap[type]) {
        return pluralTypeMap[type];
      }
      return `${type}s`;
    };

    const typeUnits = Object.keys(packageTypeUnits).map((key) => {
      return `${packageTypeUnits[key]} ${
        packageTypeUnits[key] > 1 ? getPluralType(key) : key
      }`;
    });
    return typeUnits.join(', ');
  };

  return (
    <StyledTotalsDisplay>
      <h5>Total</h5>
      <ul>
        <li>
          <em className="label">Units By Package Type</em>
          <div className="editable-control">{getPackageTypeUnits()}</div>
        </li>
        <li>
          <em className="label">Volume</em>
          <div className="editable-control">{metrics.volume}</div>
        </li>
        <li>
          <em className="label">Gross Weight</em>
          <div className="editable-control">{metrics.totalWeight}</div>
        </li>
        <li>
          <em className="label">Volumetric Weight</em>
          <div className="editable-control">{metrics.volumetricWeight}</div>
        </li>
        <li>
          <em className="label">Total Chargeable Weight</em>
          <div className="editable-control">
            {metrics.chargeableWeightVolume}
            {modeOfShipment === 'Sea' ? ' CBM' : ' KG'}
          </div>
        </li>
      </ul>
    </StyledTotalsDisplay>
  );
};

export default TotalsDisplay;
