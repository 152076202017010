/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import { Button, Icon } from 'antd';
import useDeepCompareEffect from 'use-deep-compare-effect';
import styled from '@emotion/styled';
import randomstring from 'randomstring';
import VolumeWeightInput from './VolumeWeightInput';
import TotalsDisplay from './TotalsDisplay';

const PackageOperations = styled.div`
  display: flex;
  button {
    font-size: 16px;
    font-family: AvenirLTStdBook;
    padding-left: 0;
    span {
      color: #050593;
    }
    &:not([disabled]):hover {
      text-decoration: underline;
      opacity: 1;
    }
    i {
      color: #050593;
    }
  }
`;

// Pass calculate logic
const PackageDimension = ({
  modeOfShipment,
  packages = [],
  packageTypes = [],
  finalChargeableWeight,
  finalChargeableWeightUnit,
  collapsed,
  onChange = () => {},
  // eslint-disable-next-line no-unused-vars
  onBlur = () => {},
  loading = false
}) => {
  const packageItemInit = () => {
    return {
      id: randomstring.generate(),
      amount: 1,
      // https://github.com/ant-design/ant-design/issues/11330#issuecomment-405826778
      type: undefined,
      dimensions: {
        height: null,
        isCm: true,
        length: null,
        width: null,
        amount: null
      },
      weight: {
        amount: null,
        isKg: true
      },
      volumeType: true,
      weightType: false,
      chargeableWeight: null
    };
  };

  const [errors, setErrors] = useState({});
  const [packageItems, setPackages] = useState(() => {
    if (packages.length > 0) {
      return packages.map((packageItem) => {
        return {
          ...packageItem,
          id: packageItem.pId
        };
      });
    }
    return [packageItemInit()];
  });

  const handleChange = (idx, id) => (e, err) => {
    const packageChange = [...packageItems];
    packageChange[idx] = e;
    setPackages(packageChange);
    if (err !== null) {
      setErrors({
        ...errors,
        [id]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [id]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  const handleOnBlur = (id) => (e, err) => {
    if (err !== null) {
      setErrors({
        ...errors,
        [id]: err
      });
    } else {
      // eslint-disable-next-line no-unused-vars
      const { [id]: _err, ...errs } = errors;
      setErrors(errs);
    }
  };

  useDeepCompareEffect(() => {
    onChange(packageItems, Object.keys(errors).length > 0);
  }, [packageItems, errors]);

  const newPackage = async () => {
    await setPackages([...packageItems, packageItemInit()]);
    const el = document.getElementById(`set${packageItems.length}`);
    el.scrollIntoView();
  };

  const removePackage = (idx, id) => () => {
    const newPackages = [...packageItems];
    newPackages.splice(idx, 1);
    const { [id]: _err, ...errs } = errors;
    setErrors(errs);
    setPackages(newPackages);
  };

  return (
    <>
      {!collapsed && (
        <>
          <PackageOperations>
            <Button type="link" onClick={newPackage}>
              <Icon type="plus-circle" />
              Add new set of product
            </Button>
          </PackageOperations>
          <div>
            {packageItems.map((packageItem, idx) => {
              return (
                <VolumeWeightInput
                  id={`set${idx + 1}`}
                  key={packageItem.id}
                  value={packageItem}
                  label={`Set ${idx + 1}`}
                  showDelete={packageItems.length > 1}
                  onDelete={removePackage(idx, packageItem.id)}
                  packageTypes={packageTypes.map((type) => ({
                    label: type.value,
                    value: type.key
                  }))}
                  loading={loading}
                  onChange={handleChange(idx, packageItem.id)}
                  onBlur={handleOnBlur(packageItem.id)}
                />
              );
            })}
          </div>
        </>
      )}
      <TotalsDisplay
        packages={packageItems}
        packageTypes={packageTypes}
        modeOfShipment={modeOfShipment}
        finalChargeableWeight={finalChargeableWeight}
        finalChargeableWeightUnit={finalChargeableWeightUnit}
      />
    </>
  );
};

export default PackageDimension;
