import { axiosRequest } from '../utils/request';

const getPackageTypes = () => {
  const url = `/quote/request-form-values`;
  return axiosRequest.get(url);
};

export const packageService = {
  getPackageTypes
};
