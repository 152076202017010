import React, { useState } from 'react';
import ShipmentDetailsView from './ShipmentDetailsView';
import ShipmentDetailsEdit from './ShipmentDetailsEdit';

const ShipmentDetails = () => {
  const [isEditing, setEditing] = useState(false);

  const onEdit = () => {
    setEditing(true);
  };

  const onCancel = () => {
    setEditing(false);
  };

  const onSave = () => {
    setEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <ShipmentDetailsEdit onCancel={onCancel} onSave={onSave} />
      ) : (
        <ShipmentDetailsView onEdit={onEdit} />
      )}
    </>
  );
};

export default ShipmentDetails;
