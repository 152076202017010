import { createAction } from '@reduxjs/toolkit';
import { quoteService } from '../services/quotes';

export const fetchQuotes = createAction('quoteRequests/FETCH_DATA');
export const fetchQuotesLoading = createAction(
  'quoteRequests/FETCH_DATA_LOADING'
);

export const setPageFilter = createAction('quoteRequestFilter/SET_PAGE');
export const setPageSizeFilter = createAction(
  'quoteRequestFilter/SET_PAGE_SIZE'
);

export const getQuotes = (params = {}) => async (dispatch) => {
  dispatch(fetchQuotesLoading());
  quoteService.getQuoteRequests(params).then((data) => {
    dispatch(fetchQuotes(data));
  });
};
