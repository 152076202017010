import { Field } from 'formik';
import * as React from 'react';
import Input from '../Input';
import TextArea from '../TextArea';
import Message from '../Message';

const FormInput = ({
  name,
  validate,
  // eslint-disable-next-line no-unused-vars
  onChange: handleChange = () => {},
  onBlur: handleBlur = () => {},
  ...restProps
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ field: { value, onChange, onBlur }, meta }) => (
        <>
          {console.log(name)}
          <Input
            name={name}
            value={value}
            onChange={(event) => {
              onChange(event);
              if (name !== 'origin.port' && name !== 'destination.port')
                handleChange(event);
            }}
            onBlur={(event) => {
              onBlur(event);
              // TODO: find a way to not run onBlur if value has not changed
              handleBlur(event);
            }}
            {...restProps}
          />
          {meta.touched && meta.error && (
            <Message type="error">{meta.error}</Message>
          )}
        </>
      )}
    </Field>
  );
};

FormInput.FormTextArea = ({
  name,
  validate,
  onChange: handleChange = () => {},
  onBlur: handleBlur = () => {},
  ...restProps
}) => (
  <Field name={name} validate={validate}>
    {({ field: { value, onChange, onBlur }, meta }) => (
      <>
        <TextArea
          name={name}
          value={value}
          onChange={(event) => {
            onChange(event);
            handleChange(event);
          }}
          onBlur={(event) => {
            onBlur(event);
            handleBlur(event);
          }}
          {...restProps}
        />
        {meta.touched && meta.error && (
          <Message type="error">{meta.error}</Message>
        )}
      </>
    )}
  </Field>
);

export default FormInput;
