import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Breadcrumb, Dropdown } from 'antd';
import styled from '@emotion/styled';
// import Notifications from './notifications';
import UserProfile from './User';
import ImgLogoReverse from '../assets/images/logo-reverse.png';
// import Bell from '../assets/images/bell.png';
import Account from '../assets/images/account.png';
import { getProfile } from '../auth0';

const itemRender = (route, params, routes, paths) => {
  const path = paths.reduce((a, b) => {
    return a.length > b.length ? a : b;
  }, '');
  const last = routes.indexOf(route) === routes.length - 1;
  if (route.breadcrumbName === 'Create Shipment') {
    return (
      <span style={{ fontFamily: 'AvenirLTStdBook', color: '#000000' }}>
        {route.breadcrumbName}
      </span>
    );
  }
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={`/${path}`}>{route.breadcrumbName}</Link>
  );
};
const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background: transparent linear-gradient(270deg, #1fceca 0%, #050593 100%) 0%
    0% no-repeat padding-box;
  display: flex;
  align-items: center;
  padding: 5px 40px;
  box-shadow: 0px 3px 6px #00000029;

  img {
    width: 100px;
  }

  ul {
    position: relative;
    white-space: nowrap;
    list-style: none;
    padding: 0;
    margin-left: 80px;
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 8px 20px;
      transition: 0.5s;
      font-family: AvenirLTStdBook;
      font-size: 16px;
      color: #fff;
      margin-left: 5px;
      &:hover,
      &.menu-enable {
        background: #ffffff40 0% 0% no-repeat padding-box;
        cursor: pointer;
      }
      &:first-of-type:before {
        content: '';
        position: absolute;
        border: 0.5px solid #fff;
        background-color: #ffffff;
        top: 7px;
        bottom: 7px;
        left: -30px;
      }
      .bell {
        width: 21px;
      }
      .account {
        width: 25px;
      }
    }
    &.right-part {
      position: absolute;
      right: 40px;
      li {
        padding: 0;
        border-radius: 100%;
        position: relative;
        .notice-num {
          top: -10px;
          right: -5px;
          position: absolute;
          span {
            background-color: #fa3e3e;
            border-radius: 100%;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: AvenirLTStdHeavy;
            font-size: 10px;
            color: #fff;
          }
        }
        &:first-of-type:before {
          top: -3px;
          bottom: -3px;
        }
        &:last-child {
          margin-left: 30px;
        }
      }
    }
  }
`;

const BreadcrumbStyle = styled(Breadcrumb)`
  &.ant-breadcrumb {
    background: transparent;
    padding: 12px 40px;
    line-height: 14.3px;
    max-height: 40px;
    height: 40px;
    & span .ant-breadcrumb-link {
      font-family: AvenirLTStdBook;
      font-size: 14px;
      a {
        color: #050593;
        text-decoration: underline;
      }
    }

    .ant-breadcrumb-separator {
      color: #000000;
    }

    & > span:last-child .ant-breadcrumb-link {
      text-decoration: none;
      span {
        font-family: AvenirLTStdBook;
        color: #000000;
      }
    }
  }
`;

const routesConfig = {
  '': {
    name: 'Overview',
    path: '/'
  },
  shipments: {
    name: 'Shipments',
    path: '/shipments'
  },
  faqs: {
    name: 'Glossary/FAQ',
    path: '/faqs'
  },
  quotes: {
    name: 'Quotes',
    path: '/quotes'
  },
  addresses: {
    name: 'Manage Address',
    path: '/addresses'
  },
  'create-shipment': {
    name: 'Create Shipment',
    path: '/shipments/create-shipment'
  },
  'create-quote': {
    name: 'Request Quote',
    path: '/quotes/create-quote'
  }
};

const Header = () => {
  const history = useHistory();
  const { pathname } = history.location;
  const [isInternal, setIsInternal] = useState(false);
  const [headerNav, setHeaderNav] = useState([
    {
      link: '/shipments',
      name: 'Shipments'
    },
    {
      link: '/quotes',
      name: 'Quotes'
    },
    {
      link: '/faqs',
      name: 'Glossary'
    }
  ]);
  const [routes, setRoutes] = useState([
    {
      path: '/',
      breadcrumbName: 'Overview'
    }
  ]);
  const [user, setUser] = useState();
  useEffect(() => {
    getProfile().then((profile) => {
      setUser(profile);
      const roles = profile['https://b2b.janio.asia/roles'] || [];
      setIsInternal(roles.includes('merchant/internal'));
    });
  }, []);

  useEffect(() => {
    const Current = [];
    let arrRoute = pathname.split('/');
    arrRoute.shift();
    if (arrRoute.includes('create-shipment')) {
      arrRoute = arrRoute.filter((path) => !path.includes('B2B'));
    }
    arrRoute.forEach((item) => {
      if (Object.keys(routesConfig).includes(item)) {
        Current.push({
          path: routesConfig[item].path,
          breadcrumbName: routesConfig[item].name
        });
      } else {
        Current.push({
          breadcrumbName: item
        });
      }
    });
    setRoutes(Current);
  }, [pathname]);

  useEffect(() => {
    if (isInternal) {
      setHeaderNav([
        {
          link: '/shipments',
          name: 'Shipments'
        },
        {
          link: '/quotes',
          name: 'Quotes'
        },
        {
          link: '/faqs',
          name: 'Glossary'
        }
      ]);
    }
  }, [isInternal]);

  return (
    <div>
      <HeaderContainer>
        <img alt="logo" src={ImgLogoReverse} />
        <ul id="menu">
          {headerNav.map((_nav) => (
            <Link key={_nav.link} to={_nav.link}>
              <li
                className={
                  pathname === _nav.link ||
                  (_nav.link !== '/' && pathname.includes(_nav.link))
                    ? 'menu-enable'
                    : ''
                }
              >
                {_nav.name}
              </li>
            </Link>
          ))}
        </ul>
        <ul className="right-part">
          {/* <Dropdown overlay={Notifications} trigger={['click']} placement="bottomRight">
            <li>
              <i><img alt="logo" className="bell" src={Bell} /></i>
              <span className="notice-num"><span>2</span></span>
            </li>
          </Dropdown> */}
          <Dropdown
            overlay={() => <UserProfile user={user} />}
            trigger={['click']}
            placement="bottomRight"
          >
            <li>
              <i>
                <img alt="logo" className="account" src={Account} />
              </i>
            </li>
          </Dropdown>
        </ul>
      </HeaderContainer>
      <BreadcrumbStyle itemRender={itemRender} separator=">" routes={routes} />
    </div>
  );
};

export default Header;
