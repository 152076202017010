import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Skeleton } from 'antd';
import { withRouter } from 'react-router-dom';
import ShipmentHistory from '../components/ShipmentHistory';
import { GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG } from '../actions/types';
import { Card, RequestQuoteDetailTabs } from '../components';
import {
  unloadRequestQuoteDetails,
  getRequestQuotesDetail,
  getRequestQuote
} from '../actions/quoteDetail';

const StyledQuotesDetail = styled.div`
  // max-width: 1200px;
  margin: 0 40px;
`;

const StyledCard = styled(Card)`
  padding: 30px 40px;
`;
const QuoteHeaderTitle = styled.div`
  h3 {
    margin-bottom: 15px;
  }
`;

const QuotesDetails = (props) => {
  const { match = {} } = props;
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const quoteId = match?.params?.quoteId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getRequestQuotesDetail(quoteId));
    dispatch(
      getRequestQuote({
        Id: quoteId,
        type: GET_DATA_QUOTE_DETAIL_SHIPMENT_LOG
      })
    );
    return () => {
      dispatch(unloadRequestQuoteDetails());
    };
  }, [dispatch, quoteId]);

  const quoteStatus = useSelector(
    (state) => state?.quoteRequestDetails?.quote?.quote_status
  );

  return (
    <StyledQuotesDetail>
      <Row gutter={10}>
        <Col style={{ padding: 0 }}>
          <StyledCard>
            {!loading && (
              <QuoteHeaderTitle>
                <h3>You just requested a quote</h3>
              </QuoteHeaderTitle>
            )}
            <RequestQuoteDetailTabs
              quoteId={quoteId}
              quoteStatus={quoteStatus}
              onLoading={(isLoading) => {
                setLoading(isLoading);
              }}
            />
          </StyledCard>
          {loading ? (
            <StyledCard
              className="mt3"
              style={{ marginBottom: '40px', padding: 40 }}
            >
              <Skeleton title active paragraph={{ rows: 5 }} />
            </StyledCard>
          ) : (
            <ShipmentHistory heading="Quote" />
          )}
        </Col>
      </Row>
    </StyledQuotesDetail>
  );
};
export default withRouter(QuotesDetails);
